export const getHynesEnergyReportFooter = () => `
    <style>
      html { -webkit-print-color-adjust: exact; }
    </style>
    <div style="font-size: 8pt;width: 100%; text-align: left;">
      <div style="display: flex; justify-content: space-between">
        <span style="padding-left: 2cm;">erstellt mit: <span style="color: #36A9E1; fontSize: 8pt; font-weight: bold; margin: 12px 0px;">hynemo</span> | hynemo.hynes.eu</span>
        <span class="pageNumber" style="padding-right: 2cm;"></span>
      </div>
    </div>`;
