import * as Yup from 'yup';
export const validate = (schema: any) => (value: any) => {
  try {
    schema.validateSync(value);
  } catch (error: unknown) {
    if (error instanceof Yup.ValidationError) {
      return error.message;
    }
    error;
  }
};
