export const formatNumberToUnit = (
  number: number | undefined,
  unit = '',
  decicamlPlace = 2
) => {
  if (number === undefined) {
    return number;
  }

  return `${number
    .toFixed(decicamlPlace)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} ${unit}`;
};

export const validateDecimalPlacesOfNumber =
  (decimalPlaces = 0) =>
  (value: number | undefined) => {
    const regex =
      decimalPlaces > 0
        ? `^[-]?\\d{1,8}([.,]\\d{1,${decimalPlaces}})?$`
        : '^[-]?\\d{1,8}$';

    return !value ? true : !!value.toString().match(regex);
  };
