import { Tab, Tabs } from '@material-ui/core';
import SupervisorAccounts from '@material-ui/icons/SupervisorAccount';
import * as React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Berechtigung, useCurrentUser } from 'src/modules/User/hooks';
import { hasPermission } from 'src/utils/permissions';

import styled from '@emotion/styled';
import {
  useFeatureFlag,
  useGlobalFeatureConfig,
} from 'src/modules/Config/hooks';
import { useVerwaltung } from 'src/modules/Einstellungen/hooks';
import {
  PATH_DASHBOARD,
  PATH_DATA_EXPORT,
  PATH_DATA_INPUT,
  PATH_ENERGY_REPORT,
  PATH_HILFEBEREICH,
  PATH_LIEGENSCHAFTEN,
  PATH_NEUER_KUNDE,
  PATH_SETTINGS,
  PATH_ZAEHLPUNKTE,
} from 'src/routes';
import { useSmallDevice } from 'src/utils/hooks/use-small-device';
import {
  IconDateneingabe,
  IconDownload,
  IconEinstellungen,
  IconEnergiebericht,
  IconHilfebereich,
  IconLiegenschaft,
  IconMeineKommune,
  IconZaehlpunkte,
} from '../../atoms/icons';

interface NavTab {
  path: string;
  label: string;
  icon: React.ReactElement;
  feature: string;
  mobile: boolean;
  globalUser: boolean;
  normalUser: boolean;
  permissions?: Berechtigung[];
}

export const NavTabs: NavTab[] = [
  {
    path: PATH_DASHBOARD,
    label: 'Mein Verband',
    icon: <IconMeineKommune />,
    feature: 'meineKommune',
    mobile: false,
    globalUser: false,
    normalUser: true,
  },
  {
    path: PATH_LIEGENSCHAFTEN,
    label: 'Liegenschaften',
    icon: <IconLiegenschaft />,
    feature: 'liegenschaften',
    mobile: false,
    globalUser: false,
    normalUser: true,
  },
  {
    path: PATH_ZAEHLPUNKTE,
    label: 'Zählpunkte',
    icon: <IconZaehlpunkte />,
    feature: 'zaehlpunkte',
    mobile: false,
    globalUser: false,
    normalUser: true,
  },
  {
    path: PATH_DATA_INPUT,
    label: 'Dateneingabe',
    icon: <IconDateneingabe />,
    feature: 'dateneingabe',
    mobile: true,
    globalUser: false,
    normalUser: true,
    permissions: [
      Berechtigung.EditMetaData,
      Berechtigung.EditMeterReadings,
      Berechtigung.EditInvoices,
    ],
  },
  {
    path: PATH_DATA_EXPORT,
    label: 'Datenexport',
    icon: <IconDownload />,
    feature: 'datenexport',
    mobile: false,
    globalUser: false,
    normalUser: true,
    permissions: [Berechtigung.ViewMetaData],
  },
  {
    path: PATH_NEUER_KUNDE,
    label: 'Neuer Kunde',
    icon: <SupervisorAccounts />,
    feature: 'kunde',
    mobile: false,
    globalUser: true,
    normalUser: false,
  },
  {
    path: PATH_ENERGY_REPORT,
    label: 'Energieberichte',
    icon: <IconEnergiebericht />,
    feature: 'energieberichte',
    mobile: false,
    globalUser: true,
    normalUser: true,
  },
  {
    path: PATH_SETTINGS,
    label: 'Einstellungen',
    icon: <IconEinstellungen />,
    feature: 'einstellungen',
    mobile: false,
    globalUser: false,
    normalUser: true,
  },
  {
    path: PATH_HILFEBEREICH,
    label: 'Hilfebereich',
    icon: <IconHilfebereich />,
    feature: 'hilfebereich',
    mobile: false,
    globalUser: true,
    normalUser: true,
  },
];

interface A11yProps {
  path: string;
  index: number;
}
function a11yProps({ path, index }: A11yProps) {
  return {
    key: `${path}`,
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const getActiveTabFromPathname = (activeTabs, pathname) =>
  activeTabs.findIndex(({ path }) => matchPath(pathname, { path }));

const useActiveTab = (
  activeTabs
): [number, React.Dispatch<React.SetStateAction<number>>] => {
  const { pathname } = useLocation();
  const [value, setValue] = React.useState(
    getActiveTabFromPathname(activeTabs, pathname)
  );

  React.useEffect(() => {
    const activeTabFromPath = getActiveTabFromPathname(activeTabs, pathname);
    if (activeTabFromPath !== value) {
      setValue(activeTabFromPath);
    }
  }, [activeTabs, pathname, value]);

  return [value, setValue];
};

const useActiveTabs = (verwaltung, user, small) => {
  const activeTabs = React.useMemo(
    () =>
      user
        ? NavTabs.filter((tab) => (small ? tab.mobile : true))
            .filter((tab) =>
              user?.berechtigungen.globalSuperUser
                ? tab.globalUser
                : tab.normalUser
            )
            .filter(
              (tab) =>
                !tab.permissions ||
                tab.permissions.find(
                  (permission) =>
                    hasPermission(permission, user?.berechtigungen) ||
                    verwaltung?.isLiteVersion
                )
            )
        : [],
    [small, user, verwaltung]
  );

  return { activeTabs };
};

const StyledTabs = styled(Tabs)`
  .MuiTabs-scroller > span {
    left: 0;
    width: 4px;
  }
  .MuiTab-root {
    font-size: 0.775rem;
    height: 40px;
  }
  .MuiTab-wrapper {
    display: block;
    text-align: left;
    font-weight: 700;
  }
  .MuiTab-labelIcon {
    min-height: inherit;
  }
  .MuiTab-labelIcon .MuiTab-wrapper > *:first-of-type {
    margin-bottom: -7px;
    margin-right: 12px;
    margin-left: 12px;
  }
  .MuiSvgIcon-root {
    width: 24px;
    height: inherit;
  }
  .MuiTab-textColorInherit {
    opacity: 1;
  }
  .MuiTab-textColorInherit.Mui-disabled {
    opacity: 0.5;
  }
`;

interface ConditionalTabProps {
  onTouchEnd: any;
  feature: string;
  path: string;
  icon: any;
  label: string;
  index: number;
  isGlobalUserFeature: boolean;
}

const ConditionalTab = ({
  onTouchEnd,
  feature,
  path,
  icon,
  label,
  index,
  isGlobalUserFeature,
}: ConditionalTabProps) => {
  const { user } = useCurrentUser();
  const { isActivated } = useFeatureFlag({
    feature: feature || '',
  });
  const { isActivated: isGlobaleActivated } = useGlobalFeatureConfig({
    feature: feature || '',
  });

  const isDisabled = user?.berechtigungen.globalSuperUser
    ? !isGlobalUserFeature
    : !isGlobaleActivated || !isActivated;

  return (
    <Tab
      onTouchEnd={onTouchEnd}
      component={Link}
      to={isDisabled ? '' : path}
      icon={icon}
      label={label}
      disabled={isDisabled}
      {...a11yProps({ path, index })}
    />
  );
};

export const VerbandNavItems = ({ onNavItemClick = null }: any) => {
  const { user } = useCurrentUser();
  const { small } = useSmallDevice();
  const { verwaltung } = useVerwaltung();
  const { activeTabs } = useActiveTabs(verwaltung, user, small);
  const [value, setValue] = useActiveTab(activeTabs);

  const handleClick = React.useCallback(
    (_, newValue: number) => {
      setValue(newValue);
      onNavItemClick && onNavItemClick();
    },
    [onNavItemClick, setValue]
  );

  return (
    <StyledTabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      // @ts-ignore
      onChange={handleClick}
      aria-label="Vertical tabs example"
    >
      {activeTabs.map(({ feature, path, icon, label, globalUser }, index) => (
        <ConditionalTab
          key={`${index}-${path}`}
          onTouchEnd={handleClick}
          feature={feature}
          path={path}
          icon={icon}
          label={label}
          index={index}
          isGlobalUserFeature={globalUser}
        />
      ))}
    </StyledTabs>
  );
};
