import * as React from 'react';
import useSWR, { mutate } from 'swr';
import { ENDPOINTS } from 'src/config';
import httpService, { fetcher } from 'src/utils/httpService';

import * as EinstellungenSchema from './entity/einstellungen.schema.json';
import { useSnackbar } from 'notistack';
import { alphabeticalSort } from '../../utils/sorting';

export const useVerwaltungen = () => {
  const { data, error } = useSWR(
    ENDPOINTS.DEV_API_MEINE_KOMMUNEN_DATA_GET,
    fetcher,
    { shouldRetryOnError: false, revalidateOnFocus: false }
  );

  const refetch = React.useCallback(() => {
    mutate(ENDPOINTS.DEV_API_MEINE_KOMMUNEN_DATA_GET);
  }, []);

  const sortierteVerwaltungen = React.useMemo(() => {
    if (data?.verwaltungen) {
      return data.verwaltungen.sort(alphabeticalSort());
    } else {
      return null;
    }
  }, [data]);

  return {
    verwaltungen: sortierteVerwaltungen,
    isGlobalUser: data ? data.isGlobalSuperUser : null,
    isLoading: !error && !data,
    refetch,
    error,
  };
};

export const useVerwaltung = () => {
  const { data, error } = useSWR(
    ENDPOINTS.DEV_API_MEINE_KOMMUNE_DATA_GET,
    fetcher,
    { shouldRetryOnError: false, revalidateOnFocus: false }
  );

  const { enqueueSnackbar } = useSnackbar();

  const refetch = React.useCallback(async () => {
    await mutate(ENDPOINTS.DEV_API_MEINE_KOMMUNE_DATA_GET);
  }, []);

  const updateVerwaltung = React.useCallback(
    async (inputData) => {
      const data = Object.keys(EinstellungenSchema.properties).reduce(
        (acc, property) => ({ ...acc, [property]: inputData[property] }),
        {}
      );
      await httpService
        .put(ENDPOINTS.DEV_API_MEINE_KOMMUNE_DATA_GET, data)
        .catch(() => {
          enqueueSnackbar('Verwaltung konnte nicht aktualisiert werden', {
            variant: 'error',
          });
        });
      refetch();
    },
    [enqueueSnackbar, refetch]
  );

  const getUploadUrl = React.useCallback(
    (inputData) => {
      return httpService
        .get(ENDPOINTS.DEV_API_EINSTELLUNGEN_UPLOAD, {
          params: inputData,
        })
        .catch(() => {
          enqueueSnackbar('Uploadlink konnte nicht ermittelt werden', {
            variant: 'error',
          });
        });
    },
    [enqueueSnackbar]
  );

  return {
    updateVerwaltung,
    getUploadUrl,
    verwaltung: data || {},
    isLoading: !error && !data,
    error,
  };
};
