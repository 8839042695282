/* eslint-disable react/jsx-props-no-spreading */
import { Box, TextField, Typography } from '@material-ui/core';
import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { withFormikField } from '../../../utils/formik';
import LimboToolTip from '../../molecules/limbo-tool-tip';

export interface LimboNumberFieldProps extends NumericFormatProps {
  label: string;
  errorText: string;
  tooltip: string;
  decimalScale?: number;
  allowNegative?: boolean;
}

export const LimboNumberField: React.FC<LimboNumberFieldProps> = ({
  label,
  errorText = '',
  tooltip = '',
  decimalScale = 2,
  allowNegative = false,
  ...props
}) => {
  const onChange = React.useCallback(
    ({ target: { value } }) => {
      const numberValue = Number(value.replace(',', '.'));
      props.onChange({ target: { value: numberValue } });
    },
    [props]
  );

  return (
    <Box style={{ position: 'relative' }}>
      {tooltip && <LimboToolTip tooltip={<Typography>{tooltip}</Typography>} />}
      <NumericFormat
        fullWidth
        label={label}
        variant="outlined"
        allowNegative={allowNegative}
        customInput={TextField}
        decimalScale={decimalScale}
        decimalSeparator=","
        error={!!errorText}
        helperText={errorText}
        {...props}
        onChange={onChange}
      />
    </Box>
  );
};

const LimboFormikNumberField = withFormikField(LimboNumberField);

export default LimboFormikNumberField;
