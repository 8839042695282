/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core';
import LimboToolTip from 'src/components/molecules/limbo-tool-tip';
import { withFormikField } from 'src/utils/formik';
import { Autocomplete } from '@material-ui/lab';

interface LimboAutocompleteProps {
  label: string;
  data: Array<any>;
  errorText?: string;
  tooltip?: string;
  required?: boolean;
  disabled?: boolean;
  style: any;
  optionLabel: string;
  onChange?: any;
  autoFocus?: boolean;
  onEnter?: () => void;
}

export const LimboAutocomplete: React.FC<LimboAutocompleteProps> = ({
  label,
  data,
  errorText = '',
  tooltip = '',
  required = false,
  disabled = false,
  style,
  optionLabel = 'label',
  onChange = null,
  autoFocus = false,
  onEnter = null,
  ...props
}) => (
  <FormControl
    required={required}
    variant="outlined"
    fullWidth
    error={!!errorText}
    disabled={disabled}
  >
    {tooltip && <LimboToolTip tooltip={<Typography>{tooltip}</Typography>} />}
    <Autocomplete
      options={data}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter' && onEnter) {
          onEnter();
        }
      }}
      onChange={onChange != null ? onChange : () => {}}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          label={label}
          variant="outlined"
        />
      )}
      getOptionLabel={(option) => option[optionLabel]}
      {...props}
    />

    {errorText && (
      <FormHelperText style={{ color: 'red' }}>{errorText}</FormHelperText>
    )}
  </FormControl>
);

const LimboFormikAutocomplete = withFormikField(LimboAutocomplete);

export default LimboFormikAutocomplete;
