import * as React from 'react';
import { Box, BoxProps } from '@material-ui/core';
import styled from '@emotion/styled';

export interface StackProps extends BoxProps {
  spacing?: string;
}

const StyledBox = styled(Box)<StackProps>`
  > :not(:first-child) {
    margin-${({ flexDirection }) =>
      flexDirection === 'column' ? 'top' : 'left'}: ${({ spacing = 0 }) =>
  spacing};
  }
`;

export const Stack: React.FC<StackProps> = ({
  children,
  style,
  ...boxProps
}) => (
  <StyledBox display="flex" {...boxProps}>
    {children}
  </StyledBox>
);
