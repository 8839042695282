import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

interface TruncateTextWithTooltipProps {
  tooltip: string;
  children: any;
}

const TruncateTextWithTooltip: React.FC<TruncateTextWithTooltipProps> = ({
  tooltip,
  children,
}) => {
  const [hoverStatus, setHover] = React.useState(false);
  const textElementRef = React.useRef<HTMLDivElement>(null);

  const compareSize = () => {
    if (textElementRef.current) {
      const compare =
        textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  React.useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => window.removeEventListener('resize', compareSize);
  }, []);

  return (
    <Tooltip
      title={<span style={{ fontSize: '0.875rem' }}>{tooltip}</span>}
      disableHoverListener={!hoverStatus}
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default TruncateTextWithTooltip;
