import { matchPath, useLocation } from 'react-router-dom';
import { KUNDEN_TYPEN } from 'src/config';
import { useVerwaltung } from 'src/modules/Einstellungen/hooks';
import {
  PATH_DASHBOARD,
  PATH_DATA_INPUT,
  PATH_ENERGY_REPORT,
  PATH_HILFEBEREICH,
  PATH_LIEGENSCHAFTEN,
  PATH_LIEGENSCHAFT_DETAIL,
  PATH_NEUER_ZAEHLERSTAND,
  PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT,
  PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT_ZAEHLPUNKT,
  PATH_NEUER_ZAEHLPUNKT,
  PATH_NEUE_LIEGENSCHAFT,
  PATH_NEUE_MASSNAHME,
  PATH_NEUE_RECHNUNG,
  PATH_RECHNUNGEN,
  PATH_SETTINGS,
  PATH_ZAEHLPUNKTE,
} from 'src/routes';

const KommuneTitlesByPathMap = {
  [PATH_DATA_INPUT]: 'Dateneingabe',
  [PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT_ZAEHLPUNKT]: 'Daten erfassen',
  [PATH_DASHBOARD]: 'Meine Kommune',
  [PATH_HILFEBEREICH]: 'Hilfebereich',
  [PATH_SETTINGS]: 'Einstellungen',
  [PATH_ENERGY_REPORT]: 'Energieberichte',
  [PATH_LIEGENSCHAFTEN]: 'Liegenschaften',
  [PATH_LIEGENSCHAFT_DETAIL]: 'Liegenschaft Details',
  [PATH_ZAEHLPUNKTE]: 'Zählpunkte',
  [PATH_RECHNUNGEN]: 'Rechnungen',
  [PATH_NEUER_ZAEHLPUNKT]: 'Neuer Zählpunkt',
  [PATH_NEUER_ZAEHLERSTAND]: 'Neue Produktion oder Verbrauch',
  [PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT]: 'Zählerstand Liegenschaft',
  [PATH_NEUE_RECHNUNG]: 'Neue Rechnung',
  [PATH_NEUE_MASSNAHME]: 'Neue Massnahme',
  [PATH_NEUE_LIEGENSCHAFT]: 'Neue Liegenschaft',
};

const VerbandTitlesByPathMap = {
  [PATH_DATA_INPUT]: 'Dateneingabe',
  [PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT_ZAEHLPUNKT]: 'Daten erfassen',
  [PATH_DASHBOARD]: 'Mein Verband',
  [PATH_HILFEBEREICH]: 'Hilfebereich',
  [PATH_SETTINGS]: 'Einstellungen',
  [PATH_ENERGY_REPORT]: 'Energieberichte',
  [PATH_LIEGENSCHAFTEN]: 'Liegenschaften',
  [PATH_LIEGENSCHAFT_DETAIL]: 'Liegenschaft Details',
  [PATH_ZAEHLPUNKTE]: 'Zählpunkte',
  [PATH_NEUER_ZAEHLPUNKT]: 'Neuer Zählpunkt',
  [PATH_NEUER_ZAEHLERSTAND]: 'Neue Produktion oder Verbrauch',
  [PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT]: 'Zählerstand Liegenschaft',
  [PATH_NEUE_MASSNAHME]: 'Neue Massnahme',
  [PATH_NEUE_LIEGENSCHAFT]: 'Neue Liegenschaft',
};

export const useTitleByPath = () => {
  const { pathname } = useLocation();
  const { verwaltung } = useVerwaltung();

  const titlesByPath =
    verwaltung.kundenTyp === KUNDEN_TYPEN.kommune
      ? KommuneTitlesByPathMap
      : VerbandTitlesByPathMap;

  const matchkey = Object.keys(titlesByPath).find((path) =>
    matchPath(pathname, { path, exact: true })
  );

  if (!matchkey) return 'Unbekannter Pfad';

  document.title = titlesByPath[matchkey] ? titlesByPath[matchkey] : 'LiMBO';

  return titlesByPath[matchkey];
};
