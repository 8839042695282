import * as React from 'react';
import styled from '@emotion/styled';
import { Grid, Typography, Link } from '@material-ui/core';
import { FOOTER_LINKS } from 'src/config';
import { MOBILE_BREAKPOINT } from 'src/utils/hooks/use-small-device';
import { PRINT_QUERY } from 'src/utils/hooks/use-printing';

const Root = styled.div(({ theme }) => ({
  marginTop: '1rem',
  ['.links']: {
    margin: '12px',
    marginBottom: '32px',
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '0.8rem',
  },
  [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
    marginLeft: 0,
  },
  [PRINT_QUERY]: {
    display: 'none',
  },
}));

const Footer: React.FC = () => {
  return (
    <Root>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <Typography className="links">
            <Link href={FOOTER_LINKS.IMPRESSUM} target="_blank">
              Impressum
            </Link>
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="links">
            <Link href={FOOTER_LINKS.DATENSCHUTZ} target="_blank">
              Datenschutz
            </Link>
          </Typography>
        </Grid>
        <Grid item>
          <Typography className="links">
            <Link href={FOOTER_LINKS.KONTAKT} target="_blank">
              Kontakt
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Root>
  );
};

export default Footer;
