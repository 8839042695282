import Amplify, { I18n } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth';

const authScreenLabels = {
  de: {
    'Back to Sign In': 'Zurück zum login',
    'Confirmation code cannot be empty':
      'Verifizierungs Code darf nicht leer sein',
    'Email Address *': 'Email Adresse',
    'Enter code': 'Code eingeben',
    'Enter your email address': 'Bitte Email eingeben',
    'Enter your new password': 'Neues Passwort eingeben',
    'Enter your password': 'Bitte Passwort eingeben',
    'Forgot your password?': 'Passwort vergessen?',
    'Incorrect username or password.': 'Falsche Email oder Passwort',
    'Invalid verification code provided, please try again.':
      'Ungültiger Verifizierungs Code eingegeben. Bitte erneut versuchen',
    'New password': 'Neues Passwort',
    'Password *': 'Passwort',
    'Password cannot be empty': 'Das Passwort Feld darf nicht leer sein',
    'Reset password': 'Passwort zurücksetzen',
    'Reset your password': 'Setzen Sie Ihr Passwort zurück',
    'Send Code': 'Code zuschicken',
    'Change Password' : 'Passwort ändern',
    'Password does not conform to policy: Password not long enough': 'Das Passwort entspricht nicht den Richtlinien: Passwort nicht lang genug',
    'Password does not conform to policy: Password must have uppercase characters': 'Das Passwort entspricht nicht den Richtlinien: Passwort muss Großbuchstaben enthalten',
    'Password does not conform to policy: Password must have numeric characters': 'Das Passwort entspricht nicht den Richtlinien: Passwort muss mindestens eine Ziffer enthalten',
    'Change' : 'Ändern',
    'Sign in to your account': 'Melden Sie sich an',
    'Sign In': 'Anmelden',
    'User does not exist.': 'Benutzer existiert nicht.',
    'Username cannot be empty': 'Das Email Feld darf nicht leer sein',
    'Verification code': 'Verifizierungs Code',
    'Your verification code': 'Ihr Verifizierungs Code',
    'Username/client id combination not found.': 'Benutzer existiert nicht.',
    Submit: 'Abschicken',
  },
};

I18n?.setLanguage('de');
I18n?.putVocabularies(authScreenLabels);

export const awsConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_WEB_CLIENT_ID,
  },
};

Amplify?.configure(awsConfig);

export async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}
