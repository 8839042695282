import styled from '@emotion/styled';
import { Box, Button, Grid, Paper } from '@material-ui/core';
import PubSub from 'pubsub-js';
import { useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import Logo from 'src/assets/limbo_logo.svg';
import { SignOutButton } from 'src/components/atoms/buttons/sign-out';
import { LimboAutocomplete } from 'src/components/atoms/form-fields/limboAutocomplete';
import { ErrorFallback } from 'src/components/organisms/error-fallback';
import { useVerwaltungen } from 'src/modules/Einstellungen/hooks';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';

const AuthenticationBackground = styled(Paper)`
  height: 100vh;
  -webkit-flex-flow: column wrap;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
  background-color: #243047;
  border-radius: 0;
`;

const SelectionBox = styled(Box)`
  margin: 50px;
  margin-top: 0;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 35px 40px;
  justify-content: center;
  text-align: left;
  border-radius: 6px;
  -webkit-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 660px;
`;

const StyledButton = styled(Button)`
  .MuiButton-label {
    font-weight: 700;
    font-size: 1rem;
  }
`;

const AdministrationSelectionPage = ({ userTracking }) => {
  // const { user } = useCurrentUser();

  const { setValue } = useLocalStorage();

  const { verwaltungen } = useVerwaltungen();

  const [administration, setAdministration] = useState(null);

  // @ts-ignore
  const selectAdministration = (e, newValue) => {
    setAdministration(newValue ? newValue.id : null);
  };

  const continueLogin = () => {
    if (administration) {
      setValue('verwaltungs_id', administration);
      userTracking();
      PubSub.publish('administrationSelected');
    }
  };

  return (
    <AuthenticationBackground>
      <img
        src={Logo}
        alt={'LiMBO Logo'}
        style={{
          width: '100px',
          height: '100px',
        }}
      />

      {verwaltungen && (
        <SelectionBox>
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} md={12}>
              Bitte auswählen
            </Grid>
            <Grid item xs={12} md={12}>
              {verwaltungen && (
                <LimboAutocomplete
                  autoFocus
                  onEnter={continueLogin}
                  optionLabel="name"
                  onChange={selectAdministration}
                  style={{ width: '100%' }}
                  label="bitte auswählen"
                  data={verwaltungen}
                />
              )}
            </Grid>
            <Grid item xs={6} md={6}>
              <SignOutButton
                style={{
                  color: '#666e7f',
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <StyledButton
                variant="text"
                onClick={continueLogin}
                disabled={!administration}
                style={{
                  margin: 'auto',
                  display: 'block',
                  width: '100%',
                  textTransform: 'none',
                  color: 'white',
                  backgroundColor: administration ? '#f1504f' : 'lightgrey',
                }}
              >
                Auswählen
              </StyledButton>
            </Grid>
          </Grid>
        </SelectionBox>
      )}
    </AuthenticationBackground>
  );
};

export default withErrorBoundary(AdministrationSelectionPage, {
  FallbackComponent: ErrorFallback,
});
