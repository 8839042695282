/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import LimboToolTip from '../../molecules/limbo-tool-tip';
import { withFormikField } from 'src/utils/formik';
import { format } from 'date-fns';
import { Box, Typography } from '@material-ui/core';

interface LimboDatePickerProps {
  label: string;
  tooltip: string;
  errorText: string;
  dateFormat?: string;
  onChange: ({ target: { value: date } }) => void;
  value: string;
}

const getTimeValue = (date, dateFormat) => {
  try {
    return dateFormat && date ? format(new Date(date), dateFormat) : date;
  } catch (e) {
    return date || '';
  }
};

export const LimboDatePicker: React.FC<LimboDatePickerProps> = ({
  label,
  tooltip,
  errorText,
  onChange,
  value,
  dateFormat,
  ...props
}) => {
  return (
    <Box style={{ position: 'relative' }}>
      {tooltip && <LimboToolTip tooltip={<Typography>{tooltip}</Typography>} />}
      <KeyboardDatePicker
        okLabel="Speichern"
        cancelLabel="Abbrechen"
        label={label}
        format="dd.MM.yyyy"
        invalidDateMessage="Falsches Datenformat"
        value={value || null}
        helperText={errorText}
        error={!!errorText}
        onChange={(date) =>
          onChange({
            target: {
              value: getTimeValue(date, dateFormat),
            },
          })
        }
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...props}
      />
    </Box>
  );
};

const LimboFormikDatePicker = withFormikField(LimboDatePicker);

export default LimboFormikDatePicker;
