import Popper, { PopperProps } from '@material-ui/core/Popper';
import { Box, Fade, Paper } from '@material-ui/core';

export const TooltipPopper =
  (children: React.ReactElement) => (popperProps: PopperProps) => {
    return (
      <Popper placement="bottom" {...popperProps}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{ width: 300 }}>
              <Box style={{ padding: '0.5rem' }}>{children}</Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    );
  };
