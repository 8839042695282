import { Box, Container as MuiContainer } from '@material-ui/core';
import styled from '@emotion/styled';
import { PRINT_QUERY } from 'src/utils/hooks/use-printing';
import Footer from 'src/components/organisms/footer';
import { MOBILE_BREAKPOINT } from 'src/utils/hooks/use-small-device';
import { BackToTopButton } from 'src/components/atoms/buttons/back-to-top';
import { useVerwaltung } from 'src/modules/Einstellungen/hooks';
import { LiteVersionHinweis } from 'src/components/atoms/lite-version-info';

const Root = styled.div({
  overflow: 'hidden',
  height: '100%',
  marginTop: '75px',
  padding: '1rem',
  ['.detailPageLink']: {
    textDecoration: 'none',
    ['&:hover']: {
      textDecoration: 'underline',
      textDecorationColor: 'secondary',
    },
  },
  ['.editIconContainer']: {
    cursor: 'pointer',
    fontSize: '12px',
    color: '#7c8391',
  },
  [PRINT_QUERY]: {
    marginTop: 0,
    padding: 0,
  },
});

const ResponsivePageWrapper = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.up('sm')]: {
    marginLeft: 240,
    [PRINT_QUERY]: {
      margin: 0,
    },
  },
}));

const Container = styled(MuiContainer)(({ theme }) => ({
  minHeight: 'calc(100vh - 1rem - 63px)',
  paddingBottom: '1rem',
  overflow: 'hidden',
  [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
    marginLeft: 0,
  },
}));

export const PageWrapper = ({ children }) => {
  const { verwaltung } = useVerwaltung();

  return (
    <ResponsivePageWrapper>
      <Container maxWidth="lg">
        {verwaltung?.isLiteVersion && (
          <Box
            style={{
              padding: '0 1rem',
            }}
          >
            <LiteVersionHinweis />
          </Box>
        )}
        <Root>{children}</Root>
      </Container>
      <BackToTopButton />
      <Footer />
    </ResponsivePageWrapper>
  );
};
