import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { addDays } from 'date-fns';
import { FieldArray, getIn, useField, useFormikContext } from 'formik';
import * as React from 'react';
import { EnergieSparte, ErfassungsTyp } from 'src/modules/Config/hooks';
import {
  increasingDate,
  noValuesAfterDate,
  notInFuture,
  uniqueDate,
  uniqueZaehlernummer,
} from 'src/modules/Zaehlpunkt/components/forms/validation';
import {
  ERROR_FUTURE_ZAEHLER_ERSETZT_AM,
  ERROR_INCREASING_NUMBER,
  ERROR_INCREASING_ZAEHLER_ERSETZT_AM,
  ERROR_UNIQUE_ZAEHLER_ERSETZT_AM,
  ERROR_UNIQUE_ZAEHLER_NUMMER,
  ERROR_VALUES_AFTER_ZAEHLER_ERSETZT_AM,
} from 'src/modules/Zaehlpunkt/constants';
import { validateDecimalPlacesOfNumber } from 'src/utils/formatNumbers';
import { validate } from 'src/utils/yup';
import * as Yup from 'yup';
import { AddOutlinedButton } from '../../../../components/atoms/buttons/add-button-outline';
import LimboFormikDatePicker from '../../../../components/atoms/form-fields/limboDatePicker';
import LimboFormikNumberField from '../../../../components/atoms/form-fields/limboNumberField';
import LimboFormikTextField from '../../../../components/atoms/form-fields/limboTextField';
import { DeleteIcon } from '../../../../components/atoms/icons';
import { TooltipPopper } from '../../../../components/atoms/tooltip';
import { useZaehlerstaende } from '../../hooks';

import zaehlerBeispiel1 from 'src/assets/beispiel_zaehler_1.png';
import zaehlerBeispiel2 from 'src/assets/beispiel_zaehler_2.png';
import { increasingOrEqualNumber } from '../zaehler-werte-erfassen/validation';

interface LimboFormikZaehlernummernProps {
  name: string;
  validationSchema: (value: any) => string | undefined;
  defaultValue: Array<any>;
  erfassungsTyp: number;
  zaehlerTyp: number;
  disabled: boolean;
  messwertAttributeKey?: string;
  nulldurchgangAttributeKey?: string;
}

export const LimboFormikZaehlernummern: React.FC<LimboFormikZaehlernummernProps> =
  ({
    name,
    validationSchema,
    defaultValue,
    erfassungsTyp,
    zaehlerTyp,
    messwertAttributeKey = 'wert',
    nulldurchgangAttributeKey = 'nulldurchgang',
    disabled,
  }) => {
    const [rowsToEdit, setRowsToEdit] = React.useState<Array<number>>([]);
    const [newAddedRows, setNewAddedRows] = React.useState<Array<number>>([]);
    const [isCreatedMode, setIsCreateMode] = React.useState(false);
    const [field, , { setValue }] = useField<
      Array<{ [key: string]: string | null }>
    >({
      name: name,
      validate: validationSchema,
    });

    const handleZaehlerwechsel = React.useCallback(() => {
      setValue([...field.value, defaultValue[0]]);
      const newRows = [...newAddedRows, field.value.length];
      setNewAddedRows(newRows);
    }, [defaultValue, field.value, newAddedRows, setValue]);

    React.useEffect(() => {
      if (!field.value) {
        setValue(defaultValue);
        setIsCreateMode(true);
      }
    }, [defaultValue, field.value, setValue]);

    const handleDelete = React.useCallback(
      (indexToDelete) => {
        const lastItem = field.value.length - 1;
        const newFieldArray = [
          ...field.value.slice(0, indexToDelete),
          ...field.value.slice(indexToDelete + 1),
        ];
        if (indexToDelete === lastItem) {
          const [last, ...items] = [...newFieldArray].reverse();

          return setValue([
            ...items.reverse(),
            {
              [ZAEHLERNUMMER.fieldName]: last[ZAEHLERNUMMER.fieldName],
              [ZAEHLER_WANDLERFAKTOR.fieldName]:
                last[ZAEHLER_WANDLERFAKTOR.fieldName],
            },
          ]);
        }
        setValue(newFieldArray);
        const newRows = newAddedRows
          .filter((rowIndex) => rowIndex !== indexToDelete)
          .map((index) => (index > indexToDelete ? index - 1 : index));
        setNewAddedRows(newRows);
      },
      [field.value, newAddedRows, setValue]
    );

    const isMengenZaehler = erfassungsTyp === ErfassungsTyp.Menge;
    const isZaehlerstandZaehler = erfassungsTyp === ErfassungsTyp.Zaehlerstand;
    const isZaehlerWithWandlerfaktor =
      zaehlerTyp === EnergieSparte.Strom ||
      zaehlerTyp === EnergieSparte.StromProduktion ||
      zaehlerTyp === EnergieSparte.StromEinspeisung;
    const showWandlerfaktor =
      isZaehlerstandZaehler && isZaehlerWithWandlerfaktor;

    const handleEditRow = React.useCallback(
      (index: number) => {
        const newRowsToEdit = [...rowsToEdit, index];
        setRowsToEdit(newRowsToEdit);
      },
      [rowsToEdit]
    );

    return (
      <Grid container direction="row" spacing={4}>
        <Grid item xs={12}>
          <FieldArray
            name={name}
            render={() => (
              <>
                {field.value?.map((record, index) => {
                  const isLastEntry = index === field.value.length - 1;
                  const showEditButton =
                    !isCreatedMode &&
                    !rowsToEdit.includes(index) &&
                    !newAddedRows.includes(index);
                  const showDeleteButton =
                    (isCreatedMode && field?.value?.length > 1) ||
                    (!isCreatedMode && newAddedRows.includes(index));

                  return (
                    <Grid key={index} container direction="row" spacing={4}>
                      <Grid
                        item
                        xs={
                          !isLastEntry && !isMengenZaehler && showWandlerfaktor
                            ? 3
                            : 6
                        }
                      >
                        <Zaehlernummer
                          index={index}
                          fieldArrayName={name}
                          value={record[ZAEHLERNUMMER.fieldName]}
                          disabled={disabled || showEditButton}
                        />
                      </Grid>
                      {showWandlerfaktor && (
                        <Grid
                          item
                          xs={
                            !isLastEntry
                              ? 3
                              : showDeleteButton || showEditButton
                              ? 5
                              : 6
                          }
                        >
                          <ZaehlerWandlderfaktor
                            index={index}
                            fieldArrayName={name}
                            value={record[ZAEHLER_WANDLERFAKTOR.fieldName]}
                            disabled={disabled || showEditButton}
                          />
                        </Grid>
                      )}
                      {!isLastEntry && !isMengenZaehler && (
                        <Grid item xs={3}>
                          <ZaehlerFinalerMesswert
                            index={index}
                            fieldArrayName={name}
                            attributeWertKey={messwertAttributeKey}
                            attributeNulldurchgangKey={
                              nulldurchgangAttributeKey
                            }
                            disabled={
                              disabled ||
                              (showEditButton &&
                                !newAddedRows.includes(index + 1))
                            }
                          />
                        </Grid>
                      )}
                      {!isLastEntry && (
                        <>
                          <Grid item xs={!isMengenZaehler ? 2 : 5}>
                            <ZaehlerErsetztAm
                              index={index}
                              fieldArrayName={name}
                              value={record[ZAEHLER_ERSETZT_AM.fieldName]}
                              disabled={
                                disabled ||
                                (showEditButton &&
                                  !newAddedRows.includes(index + 1))
                              }
                              KeyboardButtonProps={
                                !isMengenZaehler
                                  ? {
                                      disabled: true,
                                      style: { display: 'none' },
                                    }
                                  : {}
                              }
                            />
                          </Grid>
                        </>
                      )}
                      {showDeleteButton && (
                        <>
                          {isLastEntry && !showWandlerfaktor && (
                            <Grid item xs={5}></Grid>
                          )}
                          <Grid item xs={1}>
                            <DeleteZaehlerLine
                              index={index}
                              fieldArrayName={name}
                              disabled={disabled}
                              onClick={() => handleDelete(index)}
                            />
                          </Grid>
                        </>
                      )}
                      {showEditButton && (
                        <Grid item xs={1}>
                          <Tooltip
                            interactive
                            title="Bitte nutzen Sie die Bearbeitenfunktion nur um
                          historische Daten zu korrigieren."
                            PopperComponent={TooltipPopper(
                              <Typography>
                                Bitte nutzen Sie die Bearbeitenfunktion nur um
                                fehlerhafte historische Daten zu korrigieren.
                                Für Änderungen an der Zählernummer legen Sie
                                bitte über die Schaltfläche "Zählerwechsel
                                erfassen" einen neuen Eintrag an.
                              </Typography>
                            )}
                          >
                            <IconButton
                              onClick={() => handleEditRow(index)}
                              disabled={disabled}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
              </>
            )}
          />
          <Grid item xs={6}>
            <AddOutlinedButton
              onClick={handleZaehlerwechsel}
              disabled={disabled}
              style={{ marginTop: '0.5rem' }}
            >
              Zählerwechsel erfassen
            </AddOutlinedButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

export const ZAEHLERNUMMER = {
  fieldName: 'zaehlernummer',
  name: (fieldArrayName: string, index: number) =>
    `${fieldArrayName}.${index}.${ZAEHLERNUMMER.fieldName}`,
  label: 'Zählernummer',
  requiredErrorMsg: '',
  tooltip: '',
  validationSchema: (
    zaehler: Array<{ zaehlernummer: string }>,
    index: number
  ) =>
    validate(
      Yup.lazy(() => {
        return Yup.string()
          .required('Bitte Zählernummer eintragen')
          .test({
            message: ERROR_UNIQUE_ZAEHLER_NUMMER,
            test: uniqueZaehlernummer(zaehler, index),
          });
      })
    ),
};

const Zaehlernummer = ({
  index,
  fieldArrayName,
  validate = ZAEHLERNUMMER.validationSchema,
  ...props
}: any) => {
  const [{ value: zaehler }] = useField(fieldArrayName);

  return (
    <LimboFormikTextField
      name={ZAEHLERNUMMER.name(fieldArrayName, index)}
      label={ZAEHLERNUMMER.label}
      tooltip={ZAEHLERNUMMER.tooltip}
      validate={validate(zaehler, index)}
      disabled={useDisabledIfZaehlerstaendeByZaehlernummer({
        fieldArrayName,
        index,
      })}
      required
      {...props}
    />
  );
};

export const ZAEHLER_ERSETZT_AM = {
  fieldName: 'ersetztAm',
  name: (fieldArrayName: string, index: number) =>
    `${fieldArrayName}.${index}.${ZAEHLER_ERSETZT_AM.fieldName}`,
  label: 'Ersetzt am',
  requiredErrorMsg: '',
  tooltip: 'Dieses Datum ist erforderlich wenn der Zähler gewechselt wurde',
  validationSchema: (
    zaehlerstaende,
    zaehler: Array<{ ersetztAm?: string }>,
    zaehlerNummer: string
  ) =>
    validate(
      Yup.lazy(() =>
        Yup.date()
          .typeError('Bitte korrektes Datum eingeben')
          .required('Bitte Datum eingeben')
          .max(new Date(), 'Datum darf nicht in der Zukunft liegen')
          .test({
            message: ERROR_FUTURE_ZAEHLER_ERSETZT_AM,
            test: notInFuture,
          })
          .test({
            message: ERROR_UNIQUE_ZAEHLER_ERSETZT_AM,
            test: uniqueDate(ZAEHLER_ERSETZT_AM.fieldName, zaehler),
          })
          .test({
            message: ERROR_INCREASING_ZAEHLER_ERSETZT_AM,
            test: increasingDate(ZAEHLER_ERSETZT_AM.fieldName, zaehler),
          })
          .test({
            message: ERROR_VALUES_AFTER_ZAEHLER_ERSETZT_AM,
            test: noValuesAfterDate(zaehlerstaende, zaehlerNummer),
          })
      )
    ),
};

const ZaehlerErsetztAm = ({ index, fieldArrayName, ...props }: any) => {
  const [, { error }] = useField(
    ZAEHLER_ERSETZT_AM.name(fieldArrayName, index)
  );
  const [{ value: zaehlerNummer }] = useField(
    ZAEHLERNUMMER.name(fieldArrayName, index)
  );
  const [{ value: zaehler }] = useField(fieldArrayName);
  const { zaehlerstaende } = useZaehlerstaendeByFormId();
  const lastMatchingZaehlerstand = zaehlerstaende
    .reverse()
    .find((z) => z.zaehlernummer === zaehlerNummer);

  return (
    <LimboFormikDatePicker
      name={ZAEHLER_ERSETZT_AM.name(fieldArrayName, index)}
      label={ZAEHLER_ERSETZT_AM.label}
      tooltip={ZAEHLER_ERSETZT_AM.tooltip}
      validate={ZAEHLER_ERSETZT_AM.validationSchema(
        zaehlerstaende,
        zaehler,
        zaehlerNummer
      )}
      disabled={useDisabledIfZaehlerstaendeByZaehlernummer({
        fieldArrayName,
        index: index + 1,
      })}
      minDate={
        lastMatchingZaehlerstand?.zeitpunkt &&
        addDays(new Date(lastMatchingZaehlerstand.zeitpunkt), 1)
      }
      maxDate={new Date()}
      dateFormat="yyyy-MM-dd"
      errorText={error}
      required
      {...props}
    />
  );
};

export const ZAEHLER_FINALER_ZAEHLERSTAND = {
  fieldName: 'finalerZaehlerstand',
  name: (fieldArrayName: string, index: number) =>
    `${fieldArrayName}.${index}.${ZAEHLER_FINALER_ZAEHLERSTAND.fieldName}`,
  label: 'letzter Zählerstand',
  validationSchema: (
    zaehlerstaende: any,
    newRecord: any,
    attributeWertKey: string,
    attributeNulldurchgangKey: string
  ) =>
    validate(
      Yup.lazy((value: any) => {
        return value === ''
          ? Yup.string().required('Bitte Messwert eingeben.')
          : Yup.number()
              .required('Bitte Messwert eingeben.')
              .typeError('Bitte Messwert eingeben.')
              .min(0, 'Wert darf nicht negativ sein')
              .test({
                message: 'Bitte nur 3 Nachkommastellen eingeben',
                test: validateDecimalPlacesOfNumber(3),
              })
              .test({
                message: ERROR_INCREASING_NUMBER,
                test: increasingOrEqualNumber(
                  zaehlerstaende,
                  newRecord,
                  attributeWertKey,
                  attributeNulldurchgangKey
                ),
              });
      })
    ),
};

const ZaehlerFinalerMesswert = ({
  index,
  fieldArrayName,
  attributeWertKey,
  attributeNulldurchgangKey,
  ...textfieldProps
}: any) => {
  const { values, setFieldValue: _ } = useFormikContext();
  const [{ value }, , { setValue }] = useField(
    ZAEHLER_FINALER_ZAEHLERSTAND.name(fieldArrayName, index)
  );
  const zeitpunkt = getIn(
    values,
    ZAEHLER_ERSETZT_AM.name(fieldArrayName, index)
  );
  const { zaehlerstaende } = useZaehlerstaendeByFormId();

  const record = React.useMemo(
    () => zaehlerstaende.find((z) => z.zeitpunkt === zeitpunkt),
    [zaehlerstaende, zeitpunkt]
  );

  React.useEffect(() => {
    if (record && !value) {
      setValue(record[attributeWertKey]);
    }
  }, [attributeWertKey, record, setValue, value, zaehlerstaende, zeitpunkt]);

  return (
    <LimboFormikNumberField
      name={ZAEHLER_FINALER_ZAEHLERSTAND.name(fieldArrayName, index)}
      label={ZAEHLER_FINALER_ZAEHLERSTAND.label}
      validate={ZAEHLER_FINALER_ZAEHLERSTAND.validationSchema(
        zaehlerstaende,
        {
          zeitpunkt,
        },
        attributeWertKey,
        attributeNulldurchgangKey
      )}
      required
      value={value || ''}
      decimalScale={3}
      {...textfieldProps}
    />
  );
};

export const DeleteZaehlerLine = ({
  index,
  fieldArrayName,
  disabled,
  onClick,
}) => (
  <IconButton
    onClick={onClick}
    disabled={
      useDisabledIfZaehlerstaendeByZaehlernummer({ fieldArrayName, index }) ||
      disabled
    }
  >
    <DeleteIcon />
  </IconButton>
);

export const ZAEHLER_WANDLERFAKTOR = {
  fieldName: 'wandlerfaktor',
  name: (fieldArrayName: string, index: number) =>
    `${fieldArrayName}.${index}.${ZAEHLER_WANDLERFAKTOR.fieldName}`,
  label: 'Wandlerfaktor',
  tooltip: (
    <>
      Bei tendenziell hohem Stromverbrauch wird in einigen Fällen der
      tatsächliche Zählerstand mit Hilfe eines Wandlerfaktors bzw.
      Multiplikators umgerechnet. Leider stehen die Wandlerfaktoren nicht an
      einem einheitlich definierten Ort, sondern sind je Fabrikat an beliebigen
      Stellen auf der Vorderseite des Zählers zu finden.
      <Grid container direction="row">
        <Grid item xs={6}>
          <Tooltip title="Beispielzähler 1">
            <img
              src={zaehlerBeispiel1}
              alt="beispielzaehler 1"
              style={{ width: '50%', cursor: 'pointer' }}
              onClick={() => window.open(zaehlerBeispiel1, '_blank')}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <Tooltip title="Beispielzähler 2">
            <img
              src={zaehlerBeispiel2}
              alt="beispielzaehler 2"
              style={{ width: '50%', cursor: 'pointer' }}
              onClick={() => window.open(zaehlerBeispiel2, '_blank')}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </>
  ),
  validationSchema: validate(
    Yup.number()
      .min(0, 'Der Wandlerfaktor darf nicht negativ sein.')
      .typeError('')
      .test({
        message: 'Bitte keine Nachkommastellen eingeben',
        test: validateDecimalPlacesOfNumber(),
      })
  ),
};

const ZaehlerWandlderfaktor = ({
  disabled,
  index,
  fieldArrayName,
  ...props
}: any) => (
  <LimboFormikNumberField
    name={ZAEHLER_WANDLERFAKTOR.name(fieldArrayName, index)}
    label={ZAEHLER_WANDLERFAKTOR.label}
    tooltip={ZAEHLER_WANDLERFAKTOR.tooltip}
    validate={ZAEHLER_WANDLERFAKTOR.validationSchema}
    disabled={disabled}
    decimalScale={0}
    {...props}
  />
);

const useZaehlerstaendeByFormId = () => {
  const { values } = useFormikContext();
  const zaehlerId = getIn(values, 'id');
  const { zaehlerstaende } = useZaehlerstaende({ zaehlerId });
  return { zaehlerstaende };
};

const useDisabledIfZaehlerstaendeByZaehlernummer = ({
  fieldArrayName,
  index,
}) => {
  const [{ value }, { error }] = useField(
    ZAEHLERNUMMER.name(fieldArrayName, index)
  );
  const { zaehlerstaende } = useZaehlerstaendeByFormId();

  const disabled = React.useMemo(() => {
    const hasZaehlerstaende =
      zaehlerstaende.filter((z) => z[ZAEHLERNUMMER.fieldName] === value)
        .length > 0;
    const isExistingNumber = error === ERROR_UNIQUE_ZAEHLER_NUMMER;
    return isExistingNumber ? false : hasZaehlerstaende;
  }, [error, value, zaehlerstaende]);
  return disabled;
};
