import * as React from 'react';
import {
  Box,
  Collapse,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useLocation } from 'react-router-dom';
import { PATH_HILFEBEREICH } from 'src/routes';

const useInfoStyles = makeStyles((theme) => ({
  expandButton: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
    color: '#F25050',
  },
  infoOpen: {
    transform: 'rotate(-180deg)',
  },
  infoClosed: {
    transform: 'rotate(0)',
  },
}));

export const LiteVersionHinweis = () => {
  const { pathname } = useLocation();
  const classes = useInfoStyles();
  const [isOpen, setIsOpen] = React.useState<boolean | undefined>(undefined);

  const isHilfePage = React.useMemo(
    () => pathname.includes(PATH_HILFEBEREICH),
    [pathname]
  );

  React.useEffect(() => {
    const liteVersionInfo = localStorage.getItem('liteVersionInfo');
    setIsOpen(liteVersionInfo ? JSON.parse(liteVersionInfo) : true);
  }, []);

  const handleClick = React.useCallback(() => {
    setIsOpen((prevState) => !prevState);
    localStorage.setItem('liteVersionInfo', JSON.stringify(!isOpen));
  }, [isOpen]);

  return (
    <Paper
      style={{
        marginTop: 40,
        backgroundColor: '#ffffff',
        borderColor: '#f25050',
        borderStyle: 'solid',
        borderWidth: 2,
        boxShadow: 'none',
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <IconButton onClick={handleClick}>
            <ExpandMoreIcon
              className={`${classes.expandButton} ${
                isOpen ? classes.infoOpen : classes.infoClosed
              }`}
            />
          </IconButton>
          <Typography
            variant="h2"
            style={{ fontSize: '0.9rem', fontWeight: 'bold', color: '#F25050' }}
          >
            Hinweis zur Nutzung
          </Typography>
        </Box>
      </Box>
      <Collapse in={isOpen} timeout="auto">
        {isHilfePage ? (
          <Typography style={{ paddingLeft: 48, color: '#253048' }}>
            Sie nutzen LiMBO in einer Light-Version, dem Hilfebereich können Sie
            allerdings den vollen Funktionsumfang der Vollversion entnehmen.
          </Typography>
        ) : (
          <Typography style={{ paddingLeft: 48, color: '#253048' }}>
            Sie nutzen LiMBO in einer Light-Version, deshalb werden einige
            Ansichten unscharf oder basierend auf Beispieldaten dargestellt.
          </Typography>
        )}

        <Typography
          style={{ paddingLeft: 48, paddingBottom: 24, color: '#253048' }}
        >
          Sollten Sie Interesse an einer Vollversion haben oder weitere
          Informationen benötigen, schreiben Sie uns gerne eine{' '}
          <a
            href="mailto:info@limbo.energy"
            style={{
              color: '#F25050',
              fontWeight: 'bold',
              textDecoration: 'none',
            }}
          >
            E-Mail
          </a>
          .
        </Typography>
      </Collapse>
    </Paper>
  );
};
