import { Button, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 700,
    fontSize: '1rem',
    alignItems: 'center',
    padding: '8px 24px 8px 15px',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    boxShadow: 'none',
    '&:disabled': {
      color: '#D2D2D2',
      boxShadow: 'none',
      backgroundColor: '#E8E8E8',
      border: '1px solid #E8E8E8',
    },
    '&:hover': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
  },
}));

export const AddOutlinedButton = ({ onClick, children, ...buttonProps }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.root}
      onClick={onClick}
      startIcon={<Add style={{ fontSize: '22px' }} />}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
