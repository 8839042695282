import styled from '@emotion/styled';
import { Box } from '@material-ui/core';

import { ExportUserTrackingButton } from 'src/components/atoms/buttons/export-user-tracking-button';
import { LiteButton } from 'src/components/atoms/buttons/lite-button';
import { Brand } from 'src/components/atoms/logo';
import { Stack } from 'src/components/atoms/stack';
import { KUNDEN_TYPEN } from 'src/config';
import { useVerwaltung } from 'src/modules/Einstellungen/hooks';
import { useCurrentUser } from 'src/modules/User/hooks';
import { PRINT_QUERY } from 'src/utils/hooks/use-printing';
import { useSmallDevice } from 'src/utils/hooks/use-small-device';
import { KommuneNavItems } from './items-kommune';
import { VerbandNavItems } from './items-verband';
import { MobileNav } from './mobile';
import { SignOut } from './sign-out';

const Root = styled.div(
  ({
    color = 'white',
    backgroundColor = '#253048',
  }: {
    color: string;
    backgroundColor: string;
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '240px',
    position: 'fixed',
    backgroundColor,
    height: '100vh',
    color,
    top: 0,
    left: 0,
    overflowX: 'hidden',
    paddingTop: '20px',
    paddingBottom: '40px',
    [PRINT_QUERY]: {
      display: 'none',
    },
  })
);

const AppNavigation = () => {
  const { small } = useSmallDevice();
  const { verwaltung } = useVerwaltung();
  const { user } = useCurrentUser();
  const { customThemeConfig } = verwaltung;

  return small ? (
    <MobileNav />
  ) : (
    <Root
      color={customThemeConfig?.primary}
      backgroundColor={customThemeConfig?.backgroundColor}
    >
      <Stack spacing="1rem" flexDirection="column" flex="1">
        <Brand />
        <Box flex="1">
          {verwaltung?.kundenTyp === KUNDEN_TYPEN.verband ? (
            <VerbandNavItems />
          ) : (
            <KommuneNavItems />
          )}
          {verwaltung?.isLiteVersion && (
            <div style={{ marginTop: '50px' }}>
              <LiteButton />
            </div>
          )}
          {user?.berechtigungen?.globalSuperUser && (
            <div style={{ marginTop: '50px' }}>
              <ExportUserTrackingButton />
            </div>
          )}
        </Box>
        <SignOut />
      </Stack>
    </Root>
  );
};
export default AppNavigation;
