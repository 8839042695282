import styled from '@emotion/styled-base';
import { Grid } from '@material-ui/core';
import { SignOutButton } from 'src/components/atoms/buttons/sign-out';
import { SwitchAdministrationButton } from 'src/components/atoms/buttons/switch-administration';

const SignOutContainer = styled(Grid)`
  padding: 1rem;
`;

export const SignOut = () => (
  <SignOutContainer>
    <SwitchAdministrationButton />
    <SignOutButton />
  </SignOutContainer>
);
