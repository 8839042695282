import * as React from 'react';
import SupervisorAccounts from '@material-ui/icons/SupervisorAccount';
import { Button } from '@material-ui/core';
import { useSmallDevice } from 'src/utils/hooks/use-small-device';
import styled from '@emotion/styled-base';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';
import PubSub from 'pubsub-js';
import {
  useVerwaltung,
  useVerwaltungen,
} from 'src/modules/Einstellungen/hooks';
import { useHotkeys } from 'react-hotkeys-hook';
import { cache } from 'swr';
import { useHistory } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes';

const StyledButton = styled(Button)`
  .MuiButton-label {
    font-weight: 700;
    font-size: 0.775rem;
  }

  .MuiButton-startIcon {
    margin-bottom: -7px;
    margin-right: 12px;
    display: inline-block;
  }

  .MuiButton-iconSizeLarge > *:first-child {
    font-size: 24px;
    width: 24px;
    height: inherit;
  }
`;

export const SwitchAdministrationButton = ({ ...buttonProps }) => {
  const { small } = useSmallDevice();
  const { setValue } = useLocalStorage();
  const {
    verwaltung: { customThemeConfig },
  } = useVerwaltung();
  const { verwaltungen } = useVerwaltungen();
  const history = useHistory();

  useHotkeys(
    'ctrl + shift + w',
    () => {
      handleSwitch();
    },
    []
  );

  const handleSwitch = React.useCallback(async () => {
    await setValue('verwaltungs_id', null);
    history.push(PATH_DASHBOARD);
    cache.clear();
    PubSub.publish('changeAdministration');
  }, [history, setValue]);

  return (
    <>
      {verwaltungen && verwaltungen.length > 1 && (
        <StyledButton
          variant="text"
          onClick={handleSwitch}
          startIcon={<SupervisorAccounts />}
          style={{
            color:
              customThemeConfig?.primary || (small ? '#666e7f' : '#F2F2F2'),
          }}
          {...buttonProps}
        >
          Mandantenwechsel
        </StyledButton>
      )}
    </>
  );
};
