import { KUNDEN_TYPEN } from 'src/config';
import { ZaehlpunktLiegenschaft } from '../Zaehlpunkt/types';
import { Liegenschaft } from './types';

export const GEBAEUDE_KATEGORIEN_STRASSENBELEUCHTUNG_BY_KUNDEN_TYP = (
  kundenTyp
) => (kundenTyp === KUNDEN_TYPEN.kommune ? 9 : null);
export const UNTERKATEGORIE_STRASSENBELEUCHTUNG = 49;
export const UNTERKATEGORIE_KLAERWERK = 50;
export const UNTERKATEGORIE_SONSTIGES_BY_KUNDEN_TYP = (kundenTyp) =>
  kundenTyp === KUNDEN_TYPEN.kommune ? 51 : 9;

export enum DataStatus {
  HasNoZaehlpunkte = 0,
  HasNoData = 1,
  HasData = 2,
  HasMockData = 3,
}

export const getLabelOfDropdownListItemByUniqueAttribute = (
  dropdownList: any[],
  value: number,
  attribute = 'value'
) => dropdownList?.find((entry) => entry[attribute] === value)?.label;

interface Location {
  address: { lat: string | number; lng: string | number };
}

export const getLocationObj = (location: Location) => ({
  lat: location?.address?.lat,
  lng: location?.address?.lng,
});

export const hasLocation = (location: Location) =>
  location?.address?.lat && location?.address?.lng;

export const getLiegenschaftenNames = (
  liegenschaftenIds: ZaehlpunktLiegenschaft[],
  liegenschaften: Liegenschaft[] = []
) =>
  liegenschaftenIds
    .reduce<any[]>(
      (acc, { id }) => [
        ...acc,
        liegenschaften.find((l) => l.id === id)?.liegenschaftsbezeichnung,
      ],
      []
    )
    .filter(Boolean);

export const getLiegenschaftenName = (
  liegenschaftId: string,
  liegenschaften: Liegenschaft[] = []
) =>
  liegenschaften.find((l) => l.id === liegenschaftId)?.liegenschaftsbezeichnung;

export const getCurrentZaehlernummer = (zaehlernummern: any[]) =>
  zaehlernummern[zaehlernummern.length - 1]?.zaehlernummer;

export interface Flaeche {
  flaeche: number;
  flachenTyp: number;
  gueltigBis: string;
}

export const getCurrentFlaeche = (flaechen: Flaeche[]) =>
  flaechen?.find((flaeche: any) => !flaeche.gueltigBis)?.flaeche;

interface Lichtpunkt {
  anzahlLichtpunkte: number;
  strassenkilometer: number;
  gueltigBis: string;
}

export const getCurrentLichtpunkt = (lichtpunkte: Lichtpunkt[]) =>
  lichtpunkte?.find((lichtpunkt: Lichtpunkt) => !lichtpunkt.gueltigBis);

interface Einwohnerwert {
  einwohnerwert: number;
  gueltigBis: string;
}

export const getCurrentEinwohnerwert = (einwohnerwerte: Einwohnerwert[]) =>
  einwohnerwerte?.find(
    (einwohnerwert: Einwohnerwert) => !einwohnerwert.gueltigBis
  );
