/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { DatePicker } from '@material-ui/pickers';
import format from 'date-fns/format';

import LimboToolTip from '../../molecules/limbo-tool-tip';
import { withFormikField } from 'src/utils/formik';

interface LimboYearPickerProps {
  tooltip?: string;
  minDate: ParsableDate;
  maxDate: ParsableDate;
  label: string;
  errorText: string;
  value: string;
  onChange: ({ target: { value: date } }) => void;
}

export const LimboYearPicker: React.FC<LimboYearPickerProps> = ({
  tooltip,
  minDate,
  maxDate,
  errorText,
  value,
  onChange,
  ...props
}) => (
  <Box style={{ position: 'relative' }}>
    {tooltip && <LimboToolTip tooltip={<Typography>{tooltip}</Typography>} />}
    <DatePicker
      value={value?.toString() || null}
      minDate={minDate}
      maxDate={maxDate}
      okLabel="Speichern"
      cancelLabel="Abbrechen"
      error={!!errorText}
      helperText={errorText}
      views={['year']}
      onChange={(date) =>
        onChange({ target: { value: date ? format(date, 'yyyy') : '' } })
      }
      {...props}
    />
  </Box>
);

const LimboFormikYearPicker = withFormikField(LimboYearPicker);

export default LimboFormikYearPicker;
