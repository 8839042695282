import { Berechtigung, Berechtigungen } from 'src/modules/User/hooks';

export const hasPermission = (
  permission: Berechtigung,
  permissions?: Berechtigungen,
  striktCheck?: boolean
) => {
  const hasPermission = striktCheck
    ? permissions?.[permission]
    : permissions?.[Berechtigung.SuperUser] || permissions?.[permission];
  return !!hasPermission;
};
