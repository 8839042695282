import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  addToSearchObject,
  buildQueryString,
  parseQueryString,
  removeFromSearchObject,
  replaceInSearchObject,
} from '../parse-query-string';

interface UseSearchQueryReturn {
  filterData: any;
  addToSearchQuery: (key: string, value: string | string[]) => void;
  removeFromSearchQuery: (key: string, value: string) => void;
  replaceInSearchQuery: (key: string, value: string | string[]) => void;
  replaceSearchQuery: (key: string, value: string) => void;
}

export const useSearchQuery = (): UseSearchQueryReturn => {
  const history = useHistory();
  const location = useLocation();

  const searchObject = React.useMemo(
    () => parseQueryString(location.search),
    [location.search]
  );

  const addToSearchQuery = React.useCallback(
    (key: string, value: string | string[]) => {
      history.push({
        search: buildQueryString(addToSearchObject(searchObject, key, value)),
      });
    },
    [history, searchObject]
  );

  const removeFromSearchQuery = React.useCallback(
    (key: string, value: string) => {
      history.push({
        search: buildQueryString(
          removeFromSearchObject(searchObject, key, value)
        ),
      });
    },
    [history, searchObject]
  );

  const replaceInSearchQuery = React.useCallback(
    (key: string, value: string | string[]) => {
      history.push({
        search: buildQueryString(
          replaceInSearchObject(searchObject, key, value)
        ),
      });
    },
    [history, searchObject]
  );

  const replaceSearchQuery = React.useCallback(
    (key: string, value: string) => {
      history.push({
        search: buildQueryString({ [key]: [value] }),
      });
    },
    [history]
  );

  return {
    filterData: searchObject,
    addToSearchQuery,
    removeFromSearchQuery,
    replaceInSearchQuery,
    replaceSearchQuery,
  };
};
