import { Redirect, Route, Switch } from 'react-router';
import * as Routes from 'src/routes';
import * as Page from '../pages';

export const GlobalUserNavigation = () => (
  <Switch>
    <Route exact path={Routes.PATH_NEUER_KUNDE} component={Page.NeuerKunde} />
    <Route
      exact
      path={Routes.PATH_NEUEN_KUNDEN_KOMMUNE_ANLEGEN}
      component={Page.NeuenKundenKommuneAnlegen}
    />
    <Route
      exact
      path={Routes.PATH_NEUEN_KUNDEN_VERBAND_ANLEGEN}
      component={Page.NeuenKundenVerbandAnlegen}
    />
    <Route
      exact
      path={Routes.PATH_NEUER_KUNDE_DATENIMPORT}
      component={Page.Datenimport}
    />
    <Route
      exact
      path={Routes.PATH_ENERGY_REPORT}
      component={Page.ListViewEnergieberichteAdmin}
    />

    <Route
      exact
      path={Routes.PATH_ENERGY_REPORT_KOMMUNE}
      component={Page.ListViewEnergieberichteAdminKommune}
    />

    <Route
      exact
      path={Routes.PATH_ENERGY_REPORT_VERBAND}
      component={Page.ListViewEnergieberichteAdminVerband}
    />
    <Route
      exact
      path={Routes.PATH_HILFEBEREICH}
      component={Page.DetailHelpAdmin}
    />
    <Route
      exact
      path={Routes.PATH_HILFEBEREICH_KOMMUNE}
      component={Page.DetailHelpAdminKommune}
    />
    <Route
      path={Routes.PATH_HILFEBEREICH_KOMMUNE_SECTION}
      component={Page.DetailHelpAdminKommune}
    />
    <Route
      exact
      path={Routes.PATH_HILFEBEREICH_VERBAND}
      component={Page.DetailHelpAdminVerband}
    />
    <Route
      path={Routes.PATH_HILFEBEREICH_VERBAND_SECTION}
      component={Page.DetailHelpAdminVerband}
    />
    <Redirect from="/" to={Routes.PATH_HILFEBEREICH} />
  </Switch>
);

export const LiteVersionNavigation = () => (
  <Switch>
    <Redirect from="/" exact to={Routes.PATH_DASHBOARD} />
    <Route
      path={Routes.PATH_DASHBOARD}
      component={Page.DetailViewKommuneLite}
    />
    <Route
      exact
      path={Routes.PATH_LIEGENSCHAFTEN}
      component={Page.ListViewLiegenschaften}
    />
    <Route
      exact
      path={Routes.PATH_LIEGENSCHAFT_DETAIL}
      component={Page.LiegenschaftDetailView}
    />

    <Route
      exact
      path={Routes.PATH_ZAEHLPUNKTE_VON_LIEGENSCHAFT}
      component={Page.ListViewZaehlpunkte}
    />

    <Route
      exact
      path={Routes.NOT_FOUND_PATH}
      component={Page.DetailViewNotFound}
    />
    <Route exact path={Routes.PATH_HILFEBEREICH} component={Page.DetailHelp} />
    <Route
      path={Routes.PATH_HILFEBEREICH_SECTION}
      component={Page.DetailHelp}
    />
    <Route path={Routes.PATH_AUTH_CHECK} component={Page.KipAuthCheck} />
    <Route component={Page.DetailViewNotFound} />
  </Switch>
);

export const VerbandUserNavigation = () => {
  return (
    <Switch>
      <Redirect from="/" exact to={Routes.PATH_DASHBOARD} />
      <Route path={Routes.PATH_DASHBOARD} component={Page.DetailViewVerband} />
      <Route
        exact
        path={Routes.PATH_LIEGENSCHAFTEN}
        component={Page.ListViewLiegenschaften}
      />
      <Route
        exact
        path={Routes.PATH_LIEGENSCHAFT_DETAIL}
        component={Page.LiegenschaftDetailView}
      />
      <Route
        exact
        path={Routes.PATH_ZAEHLPUNKTE}
        component={Page.ListViewZaehlpunkte}
      />
      <Route
        exact
        path={Routes.PATH_ZAEHLPUNKTE_VON_LIEGENSCHAFT}
        component={Page.ListViewZaehlpunkte}
      />
      <Route
        exact
        path={Routes.PATH_ZAEHLPUNKTE_QRCODES}
        component={Page.ListViewQRCodes}
      />
      <Route
        exact
        path={Routes.PATH_QRCODE_RESOLVER}
        component={Page.DetailViewQRCodeResolver}
      />
      <Route
        exact
        path={Routes.PATH_NEUER_ZAEHLPUNKT}
        component={Page.NeuerZaehler}
      />
      <Route
        path={Routes.PATH_NEUER_ZAEHLPUNKT_LIEGENSCHAFT}
        component={Page.NeuerZaehler}
      />
      <Route
        path={Routes.PATH_SETTINGS}
        component={Page.DetailViewEinstellungen}
      />
      <Route
        exact
        path={Routes.PATH_DATA_INPUT}
        component={Page.ListViewDateneingabe}
      />
      <Route
        exact
        path={Routes.PATH_DATA_EXPORT}
        component={Page.ListViewDatenexport}
      />
      <Route
        path={Routes.PATH_NEUE_LIEGENSCHAFT}
        component={Page.NeueLiegenschaft}
      />
      <Route
        exact
        path={Routes.PATH_NEUER_ZAEHLERSTAND}
        component={Page.ZaehlerstandErfassen}
      />
      <Route
        exact
        path={Routes.PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT_ZAEHLPUNKT}
        component={Page.ZaehlerstandErfassen}
      />
      <Route
        exact
        path={Routes.PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT}
        component={Page.ZaehlerstandErfassen}
      />
      <Route
        exact
        path={Routes.PATH_NEUE_MASSNAHME}
        component={Page.DetailViewNeueMassnahme}
      />
      <Route
        exact
        path={Routes.PATH_ENERGY_REPORT}
        component={Page.ListViewEnergieberichte}
      />
      <Route
        path={Routes.PATH_ENERGY_REPORT_FEHLENDE_DATEN}
        component={Page.ListViewEnergieberichteMissingData}
      />
      <Route
        path={Routes.PATH_ENERGY_REPORT_FEHLENDE_BEWEGUNGSDATEN_ERFASSEN}
        component={Page.FehlendeZaehlerstaendeErfassen}
      />
      <Route
        path={Routes.PATH_ENERGY_REPORT_FEHLENDE_ZAEHLPUNKTE_ERFASSEN}
        component={Page.FehlendeZaehlpunkteErfassen}
      />
      <Route
        exact
        path={Routes.NOT_FOUND_PATH}
        component={Page.DetailViewNotFound}
      />
      <Route
        exact
        path={Routes.PATH_HILFEBEREICH}
        component={Page.DetailHelp}
      />
      <Route
        path={Routes.PATH_HILFEBEREICH_SECTION}
        component={Page.DetailHelp}
      />
      <Route path={Routes.PATH_AUTH_CHECK} component={Page.KipAuthCheck} />
      <Route component={Page.DetailViewNotFound} />
    </Switch>
  );
};

export const SignedOutNavigation = () => {
  return (
    <Switch>
      <Route path={Routes.PATH_SIGN_UP} component={Page.KipRegistrierung} />
      <Route path={Routes.PATH_AUTH_CHECK} component={Page.KipAuthCheck} />
      <Route component={Page.Login} />
    </Switch>
  );
};

export const StandardUserNavigation = () => {
  return (
    <Switch>
      <Redirect from="/" exact to={Routes.PATH_DASHBOARD} />
      <Route path={Routes.PATH_DASHBOARD} component={Page.DetailViewKommune} />
      <Route
        exact
        path={Routes.PATH_LIEGENSCHAFTEN}
        component={Page.ListViewLiegenschaften}
      />
      <Route
        exact
        path={Routes.PATH_LIEGENSCHAFT_DETAIL}
        component={Page.LiegenschaftDetailView}
      />
      <Route
        exact
        path={Routes.PATH_ZAEHLPUNKTE}
        component={Page.ListViewZaehlpunkte}
      />
      <Route
        exact
        path={Routes.PATH_ZAEHLPUNKTE_VON_LIEGENSCHAFT}
        component={Page.ListViewZaehlpunkte}
      />
      <Route
        exact
        path={Routes.PATH_ZAEHLPUNKTE_QRCODES}
        component={Page.ListViewQRCodes}
      />
      <Route
        exact
        path={Routes.PATH_QRCODE_RESOLVER}
        component={Page.DetailViewQRCodeResolver}
      />
      <Route
        exact
        path={Routes.PATH_RECHNUNGEN}
        component={Page.ListViewRechnungen}
      />
      <Route
        exact
        path={Routes.PATH_RECHNUNGEN_VON_LIEGENSCHAFT}
        component={Page.ListViewRechnungen}
      />
      <Route
        exact
        path={Routes.PATH_NEUER_ZAEHLPUNKT}
        component={Page.NeuerZaehler}
      />
      <Route
        path={Routes.PATH_NEUER_ZAEHLPUNKT_LIEGENSCHAFT}
        component={Page.NeuerZaehler}
      />
      <Route
        path={Routes.PATH_SETTINGS}
        component={Page.DetailViewEinstellungen}
      />
      <Route
        exact
        path={Routes.PATH_DATA_INPUT}
        component={Page.ListViewDateneingabe}
      />
      <Route
        exact
        path={Routes.PATH_DATA_EXPORT}
        component={Page.ListViewDatenexport}
      />
      <Route
        path={Routes.PATH_NEUE_LIEGENSCHAFT}
        component={Page.NeueLiegenschaft}
      />
      <Route
        exact
        path={Routes.PATH_NEUER_ZAEHLERSTAND}
        component={Page.ZaehlerstandErfassen}
      />
      <Route
        exact
        path={Routes.PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT_ZAEHLPUNKT}
        component={Page.ZaehlerstandErfassen}
      />
      <Route
        exact
        path={Routes.PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT}
        component={Page.ZaehlerstandErfassen}
      />
      <Route
        exact
        path={Routes.PATH_NEUE_MASSNAHME}
        component={Page.DetailViewNeueMassnahme}
      />
      <Route
        exact
        path={Routes.PATH_NEUE_RECHNUNG}
        component={Page.DetailViewNeueRechnung}
      />

      <Route
        exact
        path={Routes.PATH_ENERGY_REPORT}
        component={Page.ListViewEnergieberichte}
      />
      <Route
        path={Routes.PATH_ENERGY_REPORT_FEHLENDE_DATEN}
        component={Page.ListViewEnergieberichteMissingData}
      />
      <Route
        path={Routes.PATH_ENERGY_REPORT_FEHLENDE_BEWEGUNGSDATEN_ERFASSEN}
        component={Page.FehlendeZaehlerstaendeErfassen}
      />
      <Route
        path={Routes.PATH_ENERGY_REPORT_FEHLENDE_RECHNUNGSDATEN_ERFASSEN}
        component={Page.FehlendeRechnungenErfassen}
      />
      <Route
        path={Routes.PATH_ENERGY_REPORT_FEHLENDE_ZAEHLPUNKTE_ERFASSEN}
        component={Page.FehlendeZaehlpunkteErfassen}
      />

      <Route
        exact
        path={Routes.NOT_FOUND_PATH}
        component={Page.DetailViewNotFound}
      />
      <Route
        exact
        path={Routes.PATH_HILFEBEREICH}
        component={Page.DetailHelp}
      />
      <Route
        path={Routes.PATH_HILFEBEREICH_SECTION}
        component={Page.DetailHelp}
      />
      <Route path={Routes.PATH_AUTH_CHECK} component={Page.KipAuthCheck} />
      <Route component={Page.DetailViewNotFound} />
    </Switch>
  );
};

export const StandardUserMobileNavigation = () => (
  <Switch>
    <Route
      exact
      path={Routes.PATH_QRCODE_RESOLVER}
      component={Page.DetailViewQRCodeResolver}
    />
    <Route
      exact
      path={Routes.PATH_DATA_INPUT}
      component={Page.ListViewDateneingabe}
    />
    <Route
      exact
      path={Routes.PATH_NEUER_ZAEHLERSTAND}
      component={Page.ZaehlerstandErfassen}
    />
    <Route
      exact
      path={Routes.PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT_ZAEHLPUNKT}
      component={Page.ZaehlerstandErfassen}
    />
    <Route
      exact
      path={Routes.PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT}
      component={Page.ZaehlerstandErfassen}
    />

    <Redirect from="/" to={Routes.PATH_DATA_INPUT} />
  </Switch>
);
