const API_PREFIX = `${process.env.REACT_APP_DOMAIN}/${process.env.REACT_APP_STAGE}/api/${process.env.REACT_APP_VERSION}`;
const PUBLIC_API_PREFIX = `${process.env.REACT_APP_DOMAIN}/${process.env.REACT_APP_STAGE}/public-api/${process.env.REACT_APP_VERSION}`;

export const ENDPOINTS = {
  API_CONFIG: `${API_PREFIX}/config`,
  API_FEATURE_CONFIG: `${API_PREFIX}/feature-config`,
  API_GLOBAL_FEATURE_CONFIG: `${API_PREFIX}/feature-config-global`,
  API_LIEGENSCHAFTEN: `${API_PREFIX}/liegenschaften`,
  API_LIEGENSCHAFT_BY_ID: (id) => `${API_PREFIX}/liegenschaften/${id}`,
  API_LIEGENSCHAFTEN_ID_MASSNAHMEN: (id) =>
    `${API_PREFIX}/liegenschaften/${id}/massnahmen`,
  API_LIEGENSCHAFTEN_ID_MASSNAHMEN_ID: (liegenschaftId, massnahmeId) =>
    `${API_PREFIX}/liegenschaften/${liegenschaftId}/massnahmen/${massnahmeId}`,
  API_LIEGENSCHAFTEN_ID_DOKUMENTE: (liegenschaftId) =>
    `${API_PREFIX}/liegenschaften/${liegenschaftId}/dokumente`,
  API_LIEGENSCHAFTEN_ID_DOKUMENTE_ID_DOWNLOAD: (liegenschaftId, documentId) =>
    `${API_PREFIX}/liegenschaften/${liegenschaftId}/dokumente/${documentId}/download-url`,
  API_LIEGENSCHAFTEN_ID_DOKUMENTE_ID_UPLOAD: (liegenschaftId, documentId) =>
    `${API_PREFIX}/liegenschaften/${liegenschaftId}/dokumente/${documentId}/upload-url`,
  API_LIEGENSCHAFTEN_ID_GRENZWERTE: (id) =>
    `${API_PREFIX}/liegenschaften/${id}/grenzwerte`,
  API_LIEGENSCHAFTEN_ID_GRENZWERTE_ID: (liegenschaftId, grenzwertId) =>
    `${API_PREFIX}/liegenschaften/${liegenschaftId}/grenzwerte/${grenzwertId}`,
  API_LIEGENSCHAFTEN_GRUPPEN: `${API_PREFIX}/liegenschaften/gruppen`,
  DEV_API_MEINE_KOMMUNE_DATA_GET: `${API_PREFIX}/verwaltung`,
  DEV_API_MEINE_KOMMUNEN_DATA_GET: `${API_PREFIX}/users/tenant-permissions`,
  DEV_API_EINSTELLUNGEN_UPLOAD: `${API_PREFIX}/verwaltung/wappen-upload-url`,
  DEV_API_NUTZER_BERECHTIGUNGEN: `${API_PREFIX}/users`,
  API_CURRENT_USER: `${API_PREFIX}/users/authenticated-user`,
  API_ZAEHLPUNKTE: `${API_PREFIX}/zaehlpunkte`,
  API_RECHNUNGEN: `${API_PREFIX}/rechnungen`,
  API_KIP_REGISTRIERUNG: `${PUBLIC_API_PREFIX}/registration`,
  API_KIP_REGISTRIERUNG_CHECK: `${PUBLIC_API_PREFIX}/registration-check`,
  API_HEALTH: `${PUBLIC_API_PREFIX}/health`,
  API_ZAEHLPUNKTE_ZAEHLERSTAENDE: (id) =>
    `${API_PREFIX}/zaehlpunkte/${id}/zaehlerstaende`,
  API_ZAEHLPUNKTE_ZAEHLERSTAENDE_ID_ZEITPUNKT: (id, zeitpunkt) =>
    `${API_PREFIX}/zaehlpunkte/${id}/zaehlerstaende/${zeitpunkt}`,
  API_ZAEHLPUNKTE_MENGEN: (id) =>
    `${API_PREFIX}/zaehlpunkte/${id}/energiemengen`,
  API_ZAEHLPUNKTE_MENGEN_DELETE: (id, zeitpunkt) =>
    `${API_PREFIX}/zaehlpunkte/${id}/energiemengen/${zeitpunkt}`,
  API_ZAEHLPUNKTE_ID_RECHNUNGEN: (zaehlpunktId) =>
    `${API_PREFIX}/zaehlpunkte/${zaehlpunktId}/rechnungen`,
  API_ZAEHLPUNKTE_ID_RECHNUNGEN_ID: (zaehlpunktId, rechnungId) =>
    `${API_PREFIX}/zaehlpunkte/${zaehlpunktId}/rechnungen/${rechnungId}`,
  API_ZAEHLPUNKTE_ID_RECHNUNGEN_ID_DOWNLOAD: (zaehlpunktId, rechnungId) =>
    `${API_PREFIX}/zaehlpunkte/${zaehlpunktId}/rechnungen/${rechnungId}/download-url`,
  API_ZAEHLPUNKTE_ID_RECHNUNGEN_ID_UPLOAD: (zaehlpunktId, rechnungId) =>
    `${API_PREFIX}/zaehlpunkte/${zaehlpunktId}/rechnungen/${rechnungId}/upload-url`,
  API_HELP: (kundenTyp) => `${API_PREFIX}/help?kundenTyp=${kundenTyp}`,
  API_HELP_UPDATE_SECTION_BY_SLUG: (slug: string, kundenTyp: string) =>
    `${API_PREFIX}/help/${slug}?kundenTyp=${kundenTyp}`,
  API_HELP_DELETE_SECTION_BY_SLUG: (slug: string, kundenTyp: string) =>
    `${API_PREFIX}/help/${slug}?kundenTyp=${kundenTyp}`,
  API_ENERGIEBERICHT_CONTENT: `${API_PREFIX}/energy-report-content`,
  API_ENERGIEBERICHT_UPDATE_SECTION_BY_SLUG: (
    slug: string,
    kundenTyp: string,
    year?: string
  ) =>
    `${API_PREFIX}/energy-report-content/${slug}?kundenTyp=${kundenTyp}${
      year ? `&year=${year}` : ''
    }`,
  API_ENERGIEBERICHT_RENDERER: `${API_PREFIX}/energy-report-rendering`,
  API_ZAEHLPUNKTE_EXPORT: `${API_PREFIX}/exporte/zaehlpunkte`,
  API_ZAEHLERSTAENDE_EXPORT: `${API_PREFIX}/exporte/zaehlerstaende`,
  API_MENGEN_EXPORT: `${API_PREFIX}/exporte/energiemengen`,
  API_LASTGAENGE_EXPORT: `${API_PREFIX}/exporte/lastgaenge`,
  API_LIEGENSCHAFTEN_EXPORT: `${API_PREFIX}/exporte/liegenschaften`,
  API_RECHNUNGEN_EXPORT: `${API_PREFIX}/exporte/rechnungen`,
  API_ENERGIEBERICHT_REPORT: `${API_PREFIX}/report-data`,
  API_DATA_STATUS: `${API_PREFIX}/data-status`,
  API_USER_TRACKING: `${API_PREFIX}/user-tracking`,
  API_USER_TRACKING_EXPORT: `${API_PREFIX}/user-tracking-export`,
  API_STROM_WAERME_DIAGRAMM: `${API_PREFIX}/verwaltung/strom-waerme-diagramm`,
  API_KUNDE: `${API_PREFIX}/administration/tenants`,
  API_KUNDE_DATEN_IMPORT: (verwaltungsId = null) =>
    `${API_PREFIX}/administration/data-import/tenants${
      verwaltungsId ? `/${verwaltungsId}` : ''
    }`,
};

export const FOOTER_LINKS = {
  IMPRESSUM: 'https://limbo.energy/impressum/',
  DATENSCHUTZ: 'https://limbo.energy/datenschutz/',
  KONTAKT: 'https://limbo.energy/kontakt/',
  HILFE: 'https://limbo.energy/wp-content/uploads/2021/06/LiMBO_Handbuch.pdf',
};

// NOTE: move to config api
export const stackedGraphDetailPageDataKeys = {
  NONWITTERUNGSBEREINIGT: 'nonWitterungsbereinigt',
  WITTERUNGSBEREINIGT: 'witterungsbereinigt',
};

export const mobileDevices = [
  'Android',
  'webOS',
  'iPhone',
  'iPod',
  'BlackBerry',
  'Opera Mini',
];

export const KUNDEN_TYPEN = {
  kommune: 'kommune',
  verband: 'verband',
};
