import { Box, Button, Paper, Typography } from '@material-ui/core';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import styled from '@emotion/styled';
import { Add } from '@material-ui/icons';
import { PageWrapper } from 'src/components/molecules/page/wrapper';

const WarningIcon = styled(ReportProblemIcon)`
  color: #f25050;
  font-size: 4rem;
`;

const ErrorFallbackWrapper = styled(Paper)`
  margin: auto auto;
  text-align: center;
  width: 42rem;
  padding: 3rem 2rem;
  background: white;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
`;

const ErrorHeadline = styled(Typography)`
  color: #f25050;
  margin-bottom: 2rem;
`;

const ErrorText = styled(Typography)`
  margin-bottom: 3rem;
`;

export const ErrorFallback = () => (
  <PageWrapper>
    <ErrorFallbackWrapper>
      <WarningIcon />
      <ErrorHeadline variant="h1">
        <Box>OH NEIN!</Box>
        <Box>Da hat etwas nicht funktioniert</Box>
      </ErrorHeadline>
      <ErrorText>
        Bitte navigieren Sie über das seitliche Menü zu Ihrer gewünschen Seite.
      </ErrorText>
      <ErrorText>
        <Box>Tritt die Fehlermeldung häufiger auf?</Box>
        <Box>
          Nehmen Sie Kontakt mit uns auf, damit wir den Fehler beheben können.
        </Box>
      </ErrorText>
      <Button
        startIcon={<Add />}
        href="mailto:info@limbo.energy?subject=Fehler auf LiMBO Webportal"
        variant="outlined"
      >
        E-Mail schreiben
      </Button>
    </ErrorFallbackWrapper>
  </PageWrapper>
);
