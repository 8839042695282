/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  Box,
  StandardTextFieldProps,
  TextField,
  Typography,
} from '@material-ui/core';
import LimboToolTip from '../../molecules/limbo-tool-tip';
import { withFormikField } from '../../../utils/formik';

export interface LimboTextFieldProps extends StandardTextFieldProps {
  label: string;
  errorText: string;
  tooltip: string;
}

export const LimboTextField: React.FC<LimboTextFieldProps> = ({
  label,
  errorText = '',
  tooltip = '',
  ...props
}) => {
  window.addEventListener('mousewheel', (e: any) => {
    e.target.blur();
  });

  return (
    <Box style={{ position: 'relative' }}>
      {tooltip && <LimboToolTip tooltip={<Typography>{tooltip}</Typography>} />}
      <TextField
        label={label}
        error={!!errorText}
        helperText={errorText}
        {...props}
      />
    </Box>
  );
};

const LimboFormikTextField = withFormikField(LimboTextField);

export default LimboFormikTextField;
