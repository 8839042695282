import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';

// The Auth module doesn't refresh the tokon automatically.
// Reference:
// https://www.reddit.com/r/reactjs/comments/cvlsmk/best_practicemethod_to_refresh_token_with_aws/ey56ori/?utm_source=share&utm_medium=web2x&context=3
axios.interceptors.request.use(async (config) => {
  try {
    const jwt = await getJwtToken();
    const { getValue } = useLocalStorage();
    if (getValue('verwaltungs_id')) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: jwt,
          'Verwaltungs-ID': getValue('verwaltungs_id'),
        },
      };
    } else {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: jwt,
        },
      };
    }
  } catch (err) {
    const { setValue } = useLocalStorage();
    if (process.env.REACT_APP_STAGE !== 'test') {
      console.log('credentials expired', { err });
    }
    await Auth.signOut();
    setValue('verwaltungs_id', null);
    return config;
  }
});

export const getJwtToken = async () => {
  try {
    const session = await Auth.currentSession();
    // @ts-ignore5
    const { jwtToken } = session.idToken;
    return jwtToken;
  } catch (err) {
    console.log('couldn`t read credentials', { err });
  }
};

export function getParams(params: any) {
  return { params } || {};
}

export const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export const fetcherWithNormalizer =
  (normalizer: (data: any) => any) => async (url: string) => {
    return normalizer(await fetcher(url));
  };

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
};
