import { EnergieSparte, HeizungsTyp } from '../Config/hooks';

export const ERROR_INCREASING_NUMBER =
  'Der Zählerstand muss größer oder gleich dem vorhergehende sein';
export const ERROR_DECREASING_NUMBER = 'Der folgende Zählerstand ist kleiner';
export const ERROR_UNIQUE_ZAEHLER_NUMMER =
  'zählernummern müssen eindeutig sein';
export const ERROR_UNIQUE_ZAEHLER_ERSETZT_AM = 'Datum muss eindeutig sein';
export const ERROR_INCREASING_ZAEHLER_ERSETZT_AM =
  'Datum muss aufsteigend sein';
export const ERROR_FUTURE_ZAEHLER_ERSETZT_AM =
  'Datum darf nicht in der Zukunft liegen';
export const ERROR_VALUES_AFTER_ZAEHLER_ERSETZT_AM =
  'es existieren bereits Werte nach diesem Datum';

export const LABEL_STROM_BEZUG = 'Strom - Bezug';
export const LABEL_STROM_PRODUKTION = 'Strom - Produktion';
export const LABEL_GAS_BEZUG = 'Gas - Bezug';
export const LABEL_WASSER_BEZUG = 'Wasser - Bezug';
export const LABEL_WAERME_BEZUG = 'Wärme - Bezug';
export const LABEL_WAERME_PRODUKTION = 'Wärme - Produktion';
export const LABEL_EINSPEISER = 'Strom - Einspeisung';
export const LABEL_PRODUKTION = 'Strom - Produktion';
export const LABEL_BHKW = 'Strom & Wärme - Produktion (BHKW)';

export const ZAEHLER_STROM = {
  value: EnergieSparte.Strom,
  label: LABEL_STROM_BEZUG,
};
export const ZAEHLER_GAS = { value: EnergieSparte.Gas, label: LABEL_GAS_BEZUG };
export const ZAEHLER_WASSER = {
  value: EnergieSparte.Wasser,
  label: LABEL_WASSER_BEZUG,
};
export const ZAEHLER_EINSPEISUNG = {
  value: EnergieSparte.StromEinspeisung,
  label: LABEL_EINSPEISER,
};
export const ZAEHLER_SONSTIGE_WAERME_BEZUGSQUELLE = {
  value: EnergieSparte.Waerme,
  label: LABEL_WAERME_BEZUG,
};
export const ZAEHLER_PRODUKTION = {
  value: EnergieSparte.StromProduktion,
  label: LABEL_PRODUKTION,
};
export const ZAEHLER_BHKW = {
  value: EnergieSparte.BHKW,
  label: LABEL_BHKW,
};

export const TYPES_ERFASSUNG = {
  Zählerstand: 'zaehlerstand',
  Menge: 'menge',
};

export const ZAEHLPUNKT_TYPE_UNIT_MAP = {
  [EnergieSparte.Strom]: 'kWh',
  [EnergieSparte.Gas]: 'm³',
  [EnergieSparte.Wasser]: 'm³',
  [EnergieSparte.Waerme]: 'kWh',
  [EnergieSparte.StromEinspeisung]: 'kWh',
  [EnergieSparte.StromProduktion]: 'kWh',
  [EnergieSparte.BHKW]: 'kWh',
};

export const LABEL_HEIZUNGS_TYP_WAERMEPUMPE = 'Wärmepumpe';

export const HEIZUNGS_TYP_WAERMEPUMPE = {
  value: HeizungsTyp.Waermepumpe,
  label: LABEL_HEIZUNGS_TYP_WAERMEPUMPE,
};
