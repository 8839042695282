import { format, isAfter, isBefore } from 'date-fns';

export const isUnique =
  (timeSeries: any[], path: string) =>
  (dateString?: string, _record?: string) => {
    const context = format(new Date(dateString || '1975-01-01'), 'yyyy-MM-dd');
    const dateStrings = timeSeries.reduce(
      (acc, item) => [
        ...acc,
        format(new Date(item[path] || '1975-01-01'), 'yyyy-MM-dd'),
      ],
      []
    );
    return !dateStrings.includes(context);
  };

export const noOverlapWithPrevious =
  (timeSeries: any, fieldFrom: any, fieldTo: any) => (zeitraumVon: any) => {
    const currentDate = new Date(zeitraumVon);
    const previousItem = timeSeries
      .slice()
      .reverse()
      .find((i: any) => isAfter(currentDate, new Date(i[fieldFrom])));
    const previousDateTo = previousItem && new Date(previousItem[fieldTo]);
    return previousDateTo ? isAfter(currentDate, previousDateTo) : true;
  };

export const noOverlapWithFollowing =
  (timeSeries: any) => (zeitraumBis: any) => {
    const currentDate = new Date(zeitraumBis);
    const followingItem = timeSeries
      .slice()
      .reverse()
      .find((i: any) => isAfter(new Date(i.zeitraumBis), currentDate));

    return (
      !followingItem ||
      isAfter(new Date(followingItem.zeitraumVon), currentDate)
    );
  };

export const noOverlappingDateRange =
  ({ timeSeries, fieldFrom, fieldTo }: any) =>
  (data: any) => {
    const currentFrom = new Date(data[fieldFrom]);
    const currentTo = new Date(data[fieldTo]);
    const dates = timeSeries.reduce(
      (acc: any, row: any) => [
        ...acc,
        new Date(row[fieldFrom]),
        new Date(row[fieldTo]),
      ],
      []
    );

    return !dates.filter(
      (d: any) => isAfter(d, currentFrom) && isBefore(d, currentTo)
    ).length;
  };

export const increasingOrEqualNumber =
  (
    additionalValues: any,
    partialRecord: any,
    attributeWertKey: string,
    attributeNulldurchgangKey: string
  ) =>
  (wert: any) => {
    const newRecord = { ...partialRecord, wert };
    const sortedList = [newRecord, ...additionalValues].sort((a, b) =>
      new Date(b.zeitpunkt) > new Date(a.zeitpunkt) ? -1 : 1
    );
    const newRecordIndex = sortedList.findIndex(
      (entry) => entry.zeitpunkt === newRecord.zeitpunkt
    );
    const previousItems = sortedList.slice(0, newRecordIndex);
    const previousItem =
      previousItems.reverse().find((item) => !!item[attributeWertKey]) || {};

    const current = newRecord.wert;
    const previous = previousItem[attributeWertKey];

    if (current !== undefined && previous !== undefined && previous > current) {
      if (newRecord[attributeNulldurchgangKey]) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

export const decreasingNumber =
  (
    additionalValues: any,
    partialRecord: any,
    attributeWertKey: string,
    attributeNulldurchgangKey: string
  ) =>
  (wert: any) => {
    const newRecord = { ...partialRecord, wert };
    const sortedList = [newRecord, ...additionalValues].sort((a, b) =>
      new Date(b.zeitpunkt) > new Date(a.zeitpunkt) ? -1 : 1
    );
    const newEntryIndex = sortedList.findIndex(
      (entry) => entry.zeitpunkt === newRecord.zeitpunkt
    );

    if (newEntryIndex + 1 < sortedList.length) {
      const followingItems = sortedList.slice(newEntryIndex + 1);
      const followingItem =
        followingItems.find((item) => !!item[attributeWertKey]) || {};

      const current = newRecord.wert;
      const following = followingItem[attributeWertKey];

      if (
        current !== undefined &&
        following !== undefined &&
        current >= following
      ) {
        if (followingItem[attributeNulldurchgangKey]) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    }
    return true;
  };
