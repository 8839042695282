import * as React from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Button } from '@material-ui/core';
import { signOut } from 'src/aws-amplify';
import { useSmallDevice } from 'src/utils/hooks/use-small-device';
import styled from '@emotion/styled-base';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';
import { useHotkeys } from 'react-hotkeys-hook';
import { cache } from 'swr';
import { useHistory } from 'react-router-dom';
import { useVerwaltung } from 'src/modules/Einstellungen/hooks';
import { PATH_DASHBOARD } from 'src/routes';

const StyledButton = styled(Button)`
  .MuiButton-label {
    font-weight: 700;
    font-size: 0.775rem;
  }

  .MuiButton-startIcon {
    margin-bottom: -7px;
    margin-right: 12px;
    display: inline-block;
  }

  .MuiButton-iconSizeLarge > *:first-child {
    font-size: 24px;
    width: 24px;
    height: inherit;
  }
`;

export const SignOutButton = ({ ...buttonProps }) => {
  const { small } = useSmallDevice();
  const { setValue } = useLocalStorage();
  const {
    verwaltung: { customThemeConfig },
  } = useVerwaltung();
  const history = useHistory();

  useHotkeys(
    'ctrl + shift + a',
    () => {
      handleSignout();
    },
    []
  );

  const handleSignout = React.useCallback(async () => {
    await signOut();
    await cache.clear();
    await setValue('verwaltungs_id', null);
    history.push(PATH_DASHBOARD);
    window.location.reload();
  }, [history, setValue]);
  return (
    <StyledButton
      variant="text"
      onClick={handleSignout}
      startIcon={<ExitToAppIcon />}
      style={{
        color: customThemeConfig?.primary || (small ? '#666e7f' : '#F2F2F2'),
      }}
      {...buttonProps}
    >
      Abmelden
    </StyledButton>
  );
};
