import * as React from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { StandardTextFieldProps, TextField } from '@material-ui/core';
import { withFormikField } from 'src/utils/formik';

interface Address {
  addressString: string;
  lat: string | number;
  lng: string | number;
}

export interface SearchLocationFieldProps extends StandardTextFieldProps {
  returnedAdress: ({ addressString, lat, lng }: Address) => void;
  label: string;
  errorText: string;
  value: { addressString: string; lat: number; lng: number };
}

const SearchLocationField: React.FC<SearchLocationFieldProps> = ({
  returnedAdress,
  label,
  errorText,
  value,
  ...props
}) => {
  const autocompleteRef = React.useRef<{
    getPlace: () => { formatted_address: string; geometry: any };
  } | null>(null);

  const onLoad = React.useCallback((autocomplete) => {
    autocomplete.setFields([
      'address_components',
      'formatted_address',
      'geometry',
    ]);
    autocompleteRef.current = autocomplete;
  }, []);

  const onPlaceChanged = React.useCallback(() => {
    const { current: autocomplete } = autocompleteRef;
    if (autocomplete) {
      const addressObject = autocomplete?.getPlace();
      const query = addressObject.formatted_address;
      const lat = addressObject?.geometry?.location.lat();
      const lng = addressObject?.geometry?.location.lng();

      returnedAdress({
        addressString: query,
        lat,
        lng,
      });
    }
  }, [returnedAdress]);

  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      <TextField
        label={label}
        helperText={errorText}
        error={!!errorText}
        value={value?.addressString}
        {...props}
      />
    </Autocomplete>
  );
};

const FormikSearchLocaltionField = withFormikField(SearchLocationField);

export default FormikSearchLocaltionField;
