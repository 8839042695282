import * as React from 'react';
import { css } from '@emotion/css';

export const PRINT_QUERY = '@media print';

export const hiddenOnPrint = css({
  [PRINT_QUERY]: {
    display: 'none !important',
  },
});

enum PRINTING {
  beforePrint = 'beforeprint',
  onBeforeUnload = 'onbeforeunload',
  afterPrint = 'afterprint',
}

interface PrintProps {
  autoOpenPrint?: boolean;
}

export const usePrinting = (
  { autoOpenPrint }: PrintProps = { autoOpenPrint: true }
) => {
  const [isPrintDialogOpen, setIsPrintDialogOpen] = React.useState(false);

  const handleBeforePrint = React.useCallback(() => {
    setIsPrintDialogOpen(true);
  }, []);

  const handleAfterPrint = React.useCallback(() => {
    setIsPrintDialogOpen(false);
  }, []);

  const peparePrintView = React.useCallback(() => {
    setIsPrintDialogOpen(true);
  }, []);

  const openPrintDialog = React.useCallback(() => {
    if (!isPrintDialogOpen) {
      console.error('Please call preparePrintView first.');
      return;
    }
    window.print();
  }, [isPrintDialogOpen]);

  React.useEffect(() => {
    if (autoOpenPrint && isPrintDialogOpen) {
      window.print();
    }
  }, [autoOpenPrint, isPrintDialogOpen]);

  React.useEffect(() => {
    window.addEventListener(PRINTING.beforePrint, handleBeforePrint);
    return () =>
      window.removeEventListener(PRINTING.beforePrint, handleBeforePrint);
  }, [handleBeforePrint]);

  React.useEffect(() => {
    window.addEventListener(PRINTING.afterPrint, handleAfterPrint);
    return () =>
      window.removeEventListener(PRINTING.afterPrint, handleAfterPrint);
  }, [handleAfterPrint]);

  return { isPrintDialogOpen, peparePrintView, openPrintDialog };
};
