import * as React from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import styled from '@emotion/styled';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { useSmallDevice } from 'src/utils/hooks/use-small-device';
import { TitleWithTooltip } from 'src/modules/Einstellungen/components/tooltips';
import { TooltipPopper } from '../atoms/tooltip';

const TooltipWrapper = styled.div<{ tooltip: string; small: boolean }>(
  ({ tooltip, small }) => ({
    display: tooltip === undefined ? 'none' : 'inherit',
    position: 'absolute',
    top: small ? -25 : -20,
    right: 0,
    zIndex: 1,
  })
);

interface LimboToolTipProps {
  title?: string;
  tooltip: any;
  position?: Array<{
    direction: 'top' | 'right' | 'bottom' | 'left';
    distance: number;
  }>;
}

const LimboToolTip: React.FC<LimboToolTipProps> = ({
  title = ' ',
  position = null,
  tooltip,
  ...props
}) => {
  const { small } = useSmallDevice();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const tooltipWrapperStyle = React.useMemo(
    () =>
      position
        ? {
            ...position.reduce(
              (acc, item) => ({ ...acc, [item.direction]: item.distance }),
              {}
            ),
          }
        : {},
    [position]
  );

  return tooltip ? (
    <TooltipWrapper
      style={tooltipWrapperStyle}
      tooltip={tooltip}
      small={small}
      {...props}
    >
      {small ? (
        <ClickAwayListener onClickAway={handleClose}>
          <Tooltip
            onClose={handleClose}
            open={isOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={title}
            PopperComponent={TooltipPopper(tooltip)}
          >
            <IconButton size="small" onClick={handleOpen}>
              <HelpIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      ) : (
        <Tooltip
          interactive
          title={title}
          PopperComponent={TooltipPopper(tooltip)}
        >
          <Box>
            <TitleWithTooltip>{title}</TitleWithTooltip>
          </Box>
        </Tooltip>
      )}
    </TooltipWrapper>
  ) : null;
};

export default LimboToolTip;
