import { isAfter, isEqual, isBefore, format } from 'date-fns';

export const uniqueDate = (path: string, zaehler: Array<any>) => () => {
  const zaehlerWithDate = zaehler?.filter((l) => !!l[path]);

  const uniqueDates =
    zaehlerWithDate?.length ===
    new Set(zaehlerWithDate?.map((l) => l[path])).size;

  return uniqueDates;
};

export const increasingDate = (path: string, zaehler: Array<any>) => () => {
  const zaehlerWithDate = zaehler?.filter((l) => !!l[path]);

  if (!zaehlerWithDate || zaehlerWithDate.length === 0) return true;

  const decreasingDate = zaehlerWithDate?.find((l, i) => {
    if (i === zaehlerWithDate.length - 1) return false;

    const current = new Date(l[path]);
    const following = new Date(zaehlerWithDate[i + 1][path]);

    return isAfter(current, following);
  });

  return !decreasingDate;
};

export const uniqueZaehlernummer =
  (zaehler: Array<{ zaehlernummer }>, index: number) =>
  (zaehlernummer?: string) => {
    const existing = zaehler.findIndex(
      (z) => z.zaehlernummer === zaehlernummer
    );
    return existing > -1 ? index === existing || index < existing : true;
  };

export const notInFuture = (date) => {
  const today = new Date();
  const newDate = new Date(date);

  const isNewDateBeforeToday = isBefore(newDate, today);
  const isNewDateEqualToday = isEqual(newDate, today);

  return isNewDateBeforeToday || isNewDateEqualToday;
};
export const noValuesAfterDate =
  (zaehlerstaende, zaehlerNummer: string) => (date) => {
    const newDate = new Date(format(new Date(date), 'yyyy-MM-dd'));

    const zaehlerstand = !zaehlerstaende
      .filter((zs) => zs.zaehlerNummer === zaehlerNummer)
      .find((zs) => {
        const current = new Date(`${zs.zeitpunkt}T00:00:00.000+0100`);

        const hasValuesAfter = isAfter(current, newDate);
        const isSameDate = isEqual(current, newDate);

        return hasValuesAfter || isSameDate;
      });

    return zaehlerstand;
  };
