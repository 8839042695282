import { Helmet } from 'react-helmet-async';
import { HYNES_HOSTNAME } from './modules/Energieberichte/components/printview/footer';

export const AppHelmet = () => {
  const hostname = window.location.hostname;

  switch (hostname) {
    case HYNES_HOSTNAME:
      return <HynesAppHelmet />;
    default:
      return <LimboAppHelmet />;
  }
};

const LimboAppHelmet = () => (
  <Helmet>
    <link
      rel="apple-touch-icon"
      sizes="57x57"
      href="/icons/limbo/apple/apple-icon-57x57.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="60x60"
      href="/icons/limbo/apple/apple-icon-60x60.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="72x72"
      href="/icons/limbo/apple/apple-icon-72x72.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="76x76"
      href="/icons/limbo/apple/apple-icon-76x76.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="114x114"
      href="/icons/limbo/apple/apple-icon-114x114.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href="/icons/limbo/apple/apple-icon-120x120.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="144x144"
      href="/icons/limbo/apple/apple-icon-144x144.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="152x152"
      href="/icons/limbo/apple/apple-icon-152x152.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/icons/limbo/apple/apple-icon-180x180.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="36x36"
      href="/icons/limbo/android/android-icon-36x36.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="48x48"
      href="/icons/limbo/android/android-icon-48x48.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="72x72"
      href="/icons/limbo/android/android-icon-72x72.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="96x96"
      href="/icons/limbo/android/android-icon-96x96.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="144x144"
      href="/icons/limbo/android/android-icon-144x144.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href="/icons/limbo/android/android-icon-192x192.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/icons/limbo/favicon/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/icons/limbo/favicon/favicon-16x16.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="96x96"
      href="/icons/limbo/favicon/favicon-96x96.png"
    />
    <link rel="manifest" href="/manifest/limbo/manifest.json" />
    <link rel="shortcut icon" href="/icons/limbo/Limbo-favicon.svg" />

    <meta
      name="description"
      content="Das LiMBO Webportal – Energiemanagement für Kommunen"
    />
    <meta name="msapplication-TileColor" content="#253048" />
    <meta
      name="msapplication-TileImage"
      content="/icons/limbo/ms-icon/ms-icon-144x144.png"
    />

    <title>LiMBO</title>
  </Helmet>
);

const HynesAppHelmet = () => (
  <Helmet>
    <link
      rel="apple-touch-icon"
      sizes="57x57"
      href="/icons/hynes/apple/apple-icon-57x57.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="60x60"
      href="/icons/hynes/apple/apple-icon-60x60.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="72x72"
      href="/icons/hynes/apple/apple-icon-72x72.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="76x76"
      href="/icons/hynes/apple/apple-icon-76x76.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="114x114"
      href="/icons/hynes/apple/apple-icon-114x114.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href="/icons/hynes/apple/apple-icon-120x120.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="144x144"
      href="/icons/hynes/apple/apple-icon-144x144.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="152x152"
      href="/icons/hynes/apple/apple-icon-152x152.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/icons/hynes/apple/apple-icon-180x180.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="36x36"
      href="/icons/hynes/android/android-icon-36x36.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="48x48"
      href="/icons/hynes/android/android-icon-48x48.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="72x72"
      href="/icons/hynes/android/android-icon-72x72.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="96x96"
      href="/icons/hynes/android/android-icon-96x96.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="144x144"
      href="/icons/hynes/android/android-icon-144x144.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href="/icons/hynes/android/android-icon-192x192.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/icons/hynes/favicon/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/icons/hynes/favicon/favicon-16x16.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="96x96"
      href="/icons/hynes/favicon/favicon-96x96.png"
    />
    <link rel="manifest" href="/manifest/hynes/manifest.json" />
    <link rel="shortcut icon" href="/icons/hynes/hynes-favicon.png" />

    <meta
      name="description"
      content="Das hynemo Webportal – Energiemanagement für Kommunen"
    />
    <meta name="msapplication-TileColor" content="#253048" />
    <meta
      name="msapplication-TileImage"
      content="/icons/hynes/hynes-favicon.png"
    />

    <title>hynemo</title>
  </Helmet>
);
