import { isAfter, isEqual } from 'date-fns';
import { getCurrentFlaeche } from 'src/modules/Liegenschaften/helper';

const DEFAULT_FOR_ADDING_ZEROS = 5;
const DEFAULT_FOR_NO_ADDRESS_VALUE = 'z';
const DEFAULT_FOR_NO_NUMBER_VALUE = 0;

const addLeadingZerosToNumber = (
  initialNumber,
  amountOfZeros = DEFAULT_FOR_ADDING_ZEROS
) => {
  let result = initialNumber;

  for (let i = result; result.length <= amountOfZeros; i++) {
    result = '0' + result;
  }

  return result;
};

export const sortFlaechen =
  (sortBy = 'flaechen') =>
  (a: object, b: object) => {
    const flaecheA =
      getCurrentFlaeche(a[sortBy]) || DEFAULT_FOR_NO_NUMBER_VALUE;
    const flaecheB =
      getCurrentFlaeche(b[sortBy]) || DEFAULT_FOR_NO_NUMBER_VALUE;

    return ascendingComparator(flaecheA, flaecheB);
  };

export const sortNumber = (sortBy: string) => (a: number, b: number) => {
  const numberA = a[sortBy] || DEFAULT_FOR_NO_NUMBER_VALUE;
  const numberB = b[sortBy] || DEFAULT_FOR_NO_NUMBER_VALUE;

  return ascendingComparator(numberA, numberB);
};

const ascendingComparator = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const sortAddress =
  (sortBy = 'address') =>
  (a: object, b: object) => {
    const addressAParts = a[sortBy]?.addressString
      ?.toLowerCase()
      .split(/(\d+)/) || [DEFAULT_FOR_NO_ADDRESS_VALUE];
    const addressBParts = b[sortBy]?.addressString
      ?.toLowerCase()
      .split(/(\d+)/) || [DEFAULT_FOR_NO_ADDRESS_VALUE];

    const addressA = getPreparedSortingString(addressAParts);
    const addressB = getPreparedSortingString(addressBParts);

    return ascendingComparator(addressA, addressB);
  };

export const alphabeticalSort =
  (sortBy = 'name') =>
  (a: object, b: object) => {
    const nameAParts = a[sortBy]?.toString().toLowerCase().split(/(\d+)/);
    const nameBParts = b[sortBy]?.toString().toLowerCase().split(/(\d+)/);

    const nameA = getPreparedSortingString(nameAParts);
    const nameB = getPreparedSortingString(nameBParts);

    return ascendingComparator(nameA, nameB);
  };

const getPreparedSortingString = (inputParts: Array<any>) =>
  inputParts
    ?.map((part) => {
      return isNaN(part) ? part : addLeadingZerosToNumber(part);
    })
    .join(' ');

const convertFormatedDateToJsDate = (date) =>
  date.split('.').reverse().join('-');

export const dateRangeEndSort =
  (sortBy = 'abrechnungszeitraum') =>
  (a: object, b: object) => {
    const endA = a[sortBy]?.split('-')[1].trim();
    const endB = b[sortBy]?.split('-')[1].trim();

    const endADate = new Date(convertFormatedDateToJsDate(endA));
    const endBDate = new Date(convertFormatedDateToJsDate(endB));

    //@ts-ignore
    return endADate - endBDate;
  };

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const sortDate = (a: string, b: string) => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  if (isEqual(dateA, dateB)) return 0;

  return isAfter(dateA, dateB) ? 1 : -1;
};

export const sortArrayByFieldASC = (array: Array<any>, field: string) => {
  const stabilizedRowArray = array.map((el, index) => [el, index]);
  stabilizedRowArray?.sort((a, b) => {
    const order = -descendingComparator(a[0], b[0], field);
    if (order !== 0) {
      return order;
    } else {
      return a[1] - b[1];
    }
  });
  return stabilizedRowArray.map((el) => el[0]);
};
