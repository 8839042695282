const pattern = new RegExp(
  'https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}([-a-zA-Z0-9()@:%_+.~#?&//=]*)',
  'i'
); // fragment locator

export default function isUrl(str: string) {
  return !!pattern.test(str);
}

export const phoneRegExp = /^[\+,0](?:[0-9] ?){6,14}[0-​9]$/;

export const marloRegExp = /^[0-​9]{11}$/;

export const meloRegExp = /^DE[0-​9]{31}$/;
