import styled from '@emotion/styled';
import { Box, Divider, Drawer as MuiDrawer } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import * as React from 'react';
import { Brand } from 'src/components/atoms/logo';
import { Stack } from 'src/components/atoms/stack';
import { KUNDEN_TYPEN } from 'src/config';
import { useVerwaltung } from 'src/modules/Einstellungen/hooks';
import { hiddenOnPrint } from 'src/utils/hooks/use-printing';
import { useTitleByPath } from 'src/utils/hooks/use-title-by-path';
import { KommuneNavItems } from './items-kommune';
import { VerbandNavItems } from './items-verband';
import { SignOut } from './sign-out';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })
);

const Drawer = styled(MuiDrawer)`
  .MuiDrawer-paper {
    width: 75vw;
  }
`;

export const MobileNav = () => {
  const classes = useStyles();
  const {
    verwaltung: { customThemeConfig, kundenTyp },
  } = useVerwaltung();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const title = useTitleByPath();

  const handleNavitemClick = React.useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <React.Fragment>
      <AppBar position="fixed" className={hiddenOnPrint}>
        <Toolbar
          style={{
            color: customThemeConfig?.primary,
            backgroundColor: customThemeConfig?.backgroundColor,
          }}
        >
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setIsMenuOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor={'left'} open={isMenuOpen} onClose={handleNavitemClick}>
        <Stack spacing="1rem" flexDirection="column" flex="1">
          <Brand />
          <Divider />
          <Box flex="1">
            {kundenTyp === KUNDEN_TYPEN.verband ? (
              <VerbandNavItems onNavItemClick={handleNavitemClick} />
            ) : (
              <KommuneNavItems onNavItemClick={handleNavitemClick} />
            )}
          </Box>
          <Divider />
          <SignOut />
        </Stack>
      </Drawer>
    </React.Fragment>
  );
};
