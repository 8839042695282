import * as React from 'react';
import {
  useEnergiemengen,
  useZaehlerstaende,
  useZaehlpunkte,
} from '../Zaehlpunkt/hooks';
import { ErfassungsTyp, useFeatureFlag } from '../Config/hooks';
import { useLiegenschaften } from '../Liegenschaften/hooks';
import { useLocalStorage } from '../../utils/hooks/useLocalStorage';

const LAST_SYNC_KEY = 'lastSync';

export const useDataCaching = () => {
  const [healthStatus, setHealthStatus] = React.useState<string>('');
  const [lastSync, setLastSync] = React.useState<string | null>(
    localStorage.getItem(LAST_SYNC_KEY)
  );

  //using none cached FeatureFlag request for connection health check.
  const { error: networkError } = useFeatureFlag({ feature: '' });

  const { zaehlpunkte } = useZaehlpunkte();
  const { isError: liegenschaftenError } = useLiegenschaften();
  const { handleGetZaehlerstaende } = useZaehlerstaende({ zaehlerId: false });
  const { handleGetEnergiemengen } = useEnergiemengen({ zaehlerId: false });
  const { getValue } = useLocalStorage();

  const verwaltungsId = React.useMemo(
    () => getValue('verwaltungs_id'),
    [getValue]
  );

  const fetchDataForCaching = React.useCallback(async () => {
    if (!networkError && !liegenschaftenError && verwaltungsId) {
      setHealthStatus('Online');
      try {
        await Promise.all(
          zaehlpunkte.map((zp) => {
            if (zp.erfassungsTyp === ErfassungsTyp.Zaehlerstand) {
              return handleGetZaehlerstaende({ zaehlerId: zp.id, anzahl: 1 });
            }
            return handleGetEnergiemengen({ zaehlerId: zp.id, anzahl: 1 });
          })
        );
        setLastSync(new Date().toISOString());
        localStorage.setItem(LAST_SYNC_KEY, new Date().toISOString());
      } catch (_) {
        setHealthStatus('Offline');
      }
    } else {
      setHealthStatus('Offline');
    }
  }, [
    verwaltungsId,
    networkError,
    liegenschaftenError,
    zaehlpunkte,
    handleGetEnergiemengen,
    handleGetZaehlerstaende,
  ]);

  return { fetchDataForCaching, healthStatus, lastSync };
};
