import * as React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { IconUp } from '../../icons';
import styled from '@emotion/styled';

const IconButtonStyled = styled(IconButton)`
  color: white;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
  position: fixed;
  bottom: 50px;
  right: 20px;
`;

const PX_BENCHMARK_FOR_SHOW_BUTTON = 200;

export const BackToTopButton = ({ ...buttonProps }) => {
  const [showButton, setShowButton] = React.useState(false);

  const handelScrolling = React.useCallback(() => {
    const scrollSafari = document.body.scrollTop; // for safari
    const scrollDefault = document.documentElement.scrollTop; // for chrome, firefox, etc.
    setShowButton(
      scrollDefault > PX_BENCHMARK_FOR_SHOW_BUTTON ||
        scrollSafari > PX_BENCHMARK_FOR_SHOW_BUTTON
    );
  }, [setShowButton]);

  React.useEffect(() => {
    window.addEventListener('scroll', handelScrolling);
    return () => window.removeEventListener('scroll', handelScrolling);
  }, [handelScrolling]);

  const handleClick = React.useCallback(() => {
    document.body.scrollTop = 0; // for safari
    document.documentElement.scrollTop = 0; // for chrome, firefox, etc.
  }, []);

  return showButton ? (
    <Tooltip title="Zum Seitenanfang">
      <IconButtonStyled onClick={handleClick} {...buttonProps}>
        <IconUp />
      </IconButtonStyled>
    </Tooltip>
  ) : null;
};
