import * as React from 'react';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withFormikField } from 'src/utils/formik';
import { alphabeticalSort } from 'src/utils/sorting';
import styled from '@emotion/styled';

const useStyles = makeStyles((theme) => ({
  popupIndicator: {
    color: theme.palette.secondary.main,
  },
}));

const StyledBox = styled(Box)<{ isChecked: boolean }>(({ isChecked }) => ({
  fontSize: '1rem',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: isChecked ? `#ebebeb` : `white`,
  '&:hover': {
    backgroundColor: '#f8f4f4',
  },
}));

interface LimboMultiChipSelectWithGroupsProps {
  label: string;
  data: Array<{ id: string; label: string; group: string }>;
  errorText: string;
  value: Array<string>;
  onChange: ({ target: { value } }: any) => void;
  required: boolean;
}

export const LimboMultiChipSelectWithGroups: React.FC<LimboMultiChipSelectWithGroupsProps> =
  ({ label, data, errorText = '', value, onChange, required, ...props }) => {
    const classes = useStyles();

    const sortedData = React.useMemo(
      () => data.sort(alphabeticalSort('group')),
      [data]
    );

    const mapFieldValuesForDropdown = React.useCallback(
      (values) => {
        return values.map((id: any) =>
          sortedData.find((value: any) => value.id === id)
        );
      },
      [sortedData]
    );

    const selectedValues = React.useMemo(
      () => (sortedData.length ? mapFieldValuesForDropdown(value || []) : []),
      [sortedData.length, mapFieldValuesForDropdown, value]
    );

    const getOptionSelected = React.useCallback(
      (option, value) => option?.id == value?.id,
      []
    );

    const checkGroup = React.useCallback(
      (group) => {
        const groupItems = sortedData.filter((item) => item.group === group);
        const selectedGroupItems = selectedValues.filter(
          (item) => item.group === group
        );
        return groupItems.length === selectedGroupItems.length;
      },
      [sortedData, selectedValues]
    );

    const selectGroup = React.useCallback(
      (group) => {
        const itemsForGroup = sortedData.filter((item) => item.group === group);
        const selectedItemsForGroup = selectedValues.filter(
          (item) => item.group === group
        );

        if (itemsForGroup.length === selectedItemsForGroup.length) {
          onChange({
            target: {
              value: selectedValues
                .filter((item) => item.group !== group)
                .map((item) => item.id),
            },
          });
        } else {
          const newValues =
            value?.length > 0
              ? [
                  ...value,
                  ...sortedData
                    .filter((item) => item.group === group)
                    .map((item) => item.id),
                ]
              : [
                  ...sortedData
                    .filter((item) => item.group === group)
                    .map((item) => item.id),
                ];
          onChange({
            target: {
              value: Array.from(new Set(newValues)),
            },
          });
        }
      },
      [sortedData, onChange, selectedValues, value]
    );

    const handleChange = React.useCallback(
      (_, values) => {
        onChange({
          target: { value: values.map((value: any) => value.id) },
        });
      },
      [onChange]
    );

    return (
      <FormControl variant="outlined" fullWidth required={required}>
        <Autocomplete
          {...props}
          id={`${label}-mutiple-chip-filter`}
          disableCloseOnSelect
          multiple
          openOnFocus
          classes={classes}
          onChange={handleChange}
          size="small"
          limitTags={1}
          options={sortedData}
          value={selectedValues}
          getOptionSelected={getOptionSelected}
          groupBy={(option) => option.group}
          renderGroup={(option) => (
            <>
              <StyledBox
                isChecked={checkGroup(option.group)}
                onClick={() => selectGroup(option.group)}
              >
                <Checkbox checked={checkGroup(option.group)} />
                {option.group}
              </StyledBox>
              <>{option?.children}</>
            </>
          )}
          getOptionLabel={(value: any) => value.label}
          renderOption={(option, { selected }) => (
            <Box
              style={{
                fontSize: '1rem',
                alignItems: 'center',
              }}
            >
              <Checkbox checked={selected} />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="medium"
              label={`${label}${required ? ' *' : ''}`}
              error={!!errorText}
              helperText={errorText}
            />
          )}
          renderTags={(value, getTagProps) => {
            const numTags = value.length;
            const limitTags = 1;

            return (
              <>
                {value.slice(0, limitTags).map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={index}
                    label={option?.label}
                    style={{
                      width: '84%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  />
                ))}

                {numTags > limitTags && ` +${numTags - limitTags}`}
              </>
            );
          }}
        />
      </FormControl>
    );
  };

const LimboFormikMultiChipSelectWithGroups = withFormikField(
  LimboMultiChipSelectWithGroups
);

export default LimboFormikMultiChipSelectWithGroups;
