import { useSnackbar } from 'notistack';
import PubSub from 'pubsub-js';
import * as React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  useVerwaltung,
  useVerwaltungen,
} from 'src/modules/Einstellungen/hooks';
import { useHealth } from 'src/utils/hooks/useHealth';
import './aws-amplify';
import { useDataCaching } from './modules/Dateneingabe/hooks';
import { useScrollToTop } from './utils/hooks/scroll-to-top';
import { useSmallDevice } from './utils/hooks/use-small-device';
import { useTitleByPath } from './utils/hooks/use-title-by-path';
import {
  GlobalUserNavigation,
  LiteVersionNavigation,
  SignedOutNavigation,
  StandardUserMobileNavigation,
  StandardUserNavigation,
  VerbandUserNavigation,
} from './utils/navigation-switch';

import { ThemeProvider } from '@emotion/react';
import { MuiThemeProvider } from '@material-ui/core';
import AdministrationSelectionPage from 'src/modules/Login/page.administration';
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage';

import { AppHelmet } from './app-helmet';
import AppNavigation from './components/organisms/navigation';
import { KUNDEN_TYPEN } from './config';
import defaultTheme from './theme';
import { getCustomTheme } from './theme-custom';
import { useUserTracking } from './utils/hooks/useUsertracking';

const GLOBAL_ADMIN_ADMINISTRATION_ID = '00000000-0000-0000-0000-000000000000';
const App = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { small } = useSmallDevice();
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [administrationSelectionRequired, setAdministrationSelectionRequired] =
    React.useState(false);
  const { verwaltung } = useVerwaltung();
  const { verwaltungen, isGlobalUser } = useVerwaltungen();

  const { fetchDataForCaching } = useDataCaching();
  const { setValue, getValue } = useLocalStorage();
  useScrollToTop();
  useTitleByPath();
  const { health } = useHealth();
  const { refetch } = useUserTracking();
  const theme = React.useMemo(
    () =>
      verwaltung?.customThemeConfig
        ? getCustomTheme({
            primary: verwaltung?.customThemeConfig?.primary,
            secondary: verwaltung?.customThemeConfig?.secondary,
            fontFamily: verwaltung?.customThemeConfig?.fontFamily,
          })
        : defaultTheme,
    [verwaltung?.customThemeConfig]
  );

  useHotkeys(
    'shift+v+e',
    () => {
      enqueueSnackbar(
        `frontend version: ${process.env.REACT_APP_FRONTEND_VERSION}, backend version: ${health.version}`,
        {
          variant: 'info',
          autoHideDuration: 6000,
          preventDuplicate: true,
        }
      );
    },
    [health]
  );

  const handleAdministrationSelection = async () => {
    setAdministrationSelectionRequired(false);
    setIsSignedIn(true);
  };

  const handleAdministrationChange = async () => {
    setAdministrationSelectionRequired(true);
    setIsSignedIn(false);
  };

  const handleFailedLogin = () => {
    setIsSignedIn(false);
  };

  PubSub.subscribe('loginFailed', handleFailedLogin);
  PubSub.subscribe('administrationSelected', handleAdministrationSelection);
  PubSub.subscribe('changeAdministration', handleAdministrationChange);

  navigator.serviceWorker.onmessage = async (event) => {
    if (event.data && event.data.type === 'INVALID_ZAEHLERSTAND') {
      enqueueSnackbar(`${event.data.name}: ${event.data.error}`, {
        variant: 'error',
        autoHideDuration: 6000,
      });
    }
    if (event.data && event.data.type === 'QUEUE_SYNC') {
      if (event.data.text === 'Synchronisation beendet.') {
        await fetchDataForCaching();
      }
      enqueueSnackbar(event.data.text, {
        variant: 'info',
        autoHideDuration: 6000,
      });
    }
  };

  const showNewVersion = React.useCallback(async () => {
    const readyServiceWorker = await navigator.serviceWorker.ready;
    if (readyServiceWorker.waiting) {
      enqueueSnackbar(
        'Es ist eine neue Version verfügbar. Bitte schließen Sie die App vollständig und starten Sie diese anschließend neu.',
        { variant: 'info', preventDuplicate: true }
      );
    }
  }, [enqueueSnackbar]);

  const checkForValidAdministrationId = React.useCallback(() => {
    let administrationId = getValue('verwaltungs_id');
    if (administrationId) {
      let found = false;
      for (const administration of verwaltungen) {
        if (administration.id === administrationId) {
          found = true;
          break;
        }
      }
      if (!found) {
        setValue('verwaltungs_id', null);
        administrationId = null;
      }
    }
    return administrationId;
  }, [getValue, setValue, verwaltungen]);

  React.useEffect(() => {
    if (verwaltungen) {
      if (verwaltungen && verwaltungen.length > 1) {
        if (checkForValidAdministrationId()) {
          setAdministrationSelectionRequired(false);
          setIsSignedIn(true);
        } else {
          setAdministrationSelectionRequired(true);
        }
      } else {
        setIsSignedIn(true);
        setValue('verwaltungs_id', verwaltungen[0].id);
      }
    } else if (isGlobalUser) {
      setIsSignedIn(true);
      setValue('verwaltungs_id', GLOBAL_ADMIN_ADMINISTRATION_ID);
    }
    showNewVersion();
  }, [
    checkForValidAdministrationId,
    getValue,
    isGlobalUser,
    isSignedIn,
    setValue,
    showNewVersion,
    verwaltungen,
  ]);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <AppHelmet />
        {isSignedIn ? (
          <>
            <AppNavigation />
            {verwaltung?.kundenTyp === KUNDEN_TYPEN.verband ? (
              <VerbandUserNavigation />
            ) : verwaltung?.isLiteVersion ? (
              <LiteVersionNavigation />
            ) : isGlobalUser ? (
              <GlobalUserNavigation />
            ) : small ? (
              <StandardUserMobileNavigation />
            ) : (
              <StandardUserNavigation />
            )}
          </>
        ) : administrationSelectionRequired ? (
          <AdministrationSelectionPage userTracking={refetch} />
        ) : (
          <SignedOutNavigation />
        )}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
