import * as React from 'react';
import { FieldValidator, useField, useFormikContext } from 'formik';

export const withFormikField = (WrappedFormComponent: any) => {
  return ({
    name,
    validate,
    onChange = (_: any) => {},
    onBlur = (_: any) => {},
    ...props
  }: {
    name: string;
    validate: FieldValidator;
  } & any) => {
    const [field, meta] = useField({
      name,
      type: props.type,
      value: props.value || '',
      validate,
    });
    const { setFieldValue, setFieldTouched } = useFormikContext();

    React.useEffect(() => {
      if (!field.value && props.defaultValue != undefined) {
        setFieldValue(name, props.defaultValue);
      }
      setFieldTouched(name, meta.touched);
    }, [
      field.value,
      meta.touched,
      name,
      props.defaultValue,
      setFieldTouched,
      setFieldValue,
    ]);

    const errorText = getErrorText(meta);

    const handleChange = React.useCallback(
      (e) => {
        const value =
          props.type === 'number' ? parseFloat(e.target.value) : e.target.value;
        setFieldValue(name, value);
        onChange && onChange(e);
      },
      [props.type, setFieldValue, name, onChange]
    );

    const handleBlur = React.useCallback(() => {
      setFieldTouched(name, true);
      onBlur && onBlur(field.value);
    }, [field.value, name, onBlur, setFieldTouched]);

    return (
      <WrappedFormComponent
        {...field}
        errorText={errorText}
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        data-testid={name}
      />
    );
  };
};

export const getErrorText = (meta: any) =>
  meta.error && meta.touched ? meta.error : '';
