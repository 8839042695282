import {
  Checkbox,
  Chip,
  FormControl,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import * as React from 'react';
import { withFormikField } from 'src/utils/formik';
import LimboToolTip from '../../molecules/limbo-tool-tip';

const useStyles = makeStyles((theme) => ({
  popupIndicator: {
    color: theme.palette.secondary.main,
  },
}));

interface LimboMultiChipSelectProps {
  label: string;
  data: Array<{ value: string; label: string }>;
  tooltip: string;
  errorText: string;
  value: Array<number>;
  onChange: ({ target: { value } }: any) => void;
  required: boolean;
}

export const LimboMultiChipSelect: React.FC<LimboMultiChipSelectProps> = ({
  label,
  data,
  tooltip = '',
  errorText = '',
  value,
  onChange,
  required,
  ...props
}) => {
  const classes = useStyles();
  const mapFieldValuesForDropdown = React.useCallback(
    (values) => {
      return values.map((id: any) =>
        data.find((value: any) => value.value === id)
      );
    },
    [data]
  );

  const values = React.useMemo(
    () => (data.length ? mapFieldValuesForDropdown(value || []) : []),
    [data.length, mapFieldValuesForDropdown, value]
  );

  const handleChange = React.useCallback(
    (_, values) => {
      onChange({
        target: { value: values.map((value: any) => value.value) },
      });
    },
    [onChange]
  );

  const getOptionSelected = React.useCallback(
    (option, value) => option?.value == value?.value,
    []
  );

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        {tooltip && (
          <LimboToolTip tooltip={<Typography>{tooltip}</Typography>} />
        )}
        <Autocomplete
          {...props}
          disableCloseOnSelect
          multiple
          openOnFocus
          classes={classes}
          size="small"
          limitTags={1}
          onChange={handleChange}
          options={data}
          value={values}
          getOptionSelected={getOptionSelected}
          getOptionLabel={(value: any) => value?.label}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox checked={selected} />
              {option?.label}
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="medium"
              //Note * for required is set manually, because the value of the field is overwritten by params ref to be always empty string
              label={`${label}${required ? ' *' : ''}`}
              error={!!errorText}
              helperText={errorText}
            />
          )}
          renderTags={(value, getTagProps) => {
            const numTags = value.length;
            const limitTags = 1;

            return (
              <>
                {value.slice(0, limitTags).map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={index}
                    label={option?.label}
                    style={{
                      width: '84%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  />
                ))}

                {numTags > limitTags && ` +${numTags - limitTags}`}
              </>
            );
          }}
        />
      </FormControl>
    </>
  );
};

const LimboFormikMultiChipSelect = withFormikField(LimboMultiChipSelect);

export default LimboFormikMultiChipSelect;
