import { ENDPOINTS } from 'src/config';
import useSWR from 'swr';
import { fetcher } from 'src/utils/httpService';

export const useHealth = () => {
  const { data, error } = useSWR(ENDPOINTS.API_HEALTH, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    health: data || {},
    isLoading: !error && !data,
    isError: error,
  };
};
