import { saveAs } from 'file-saver';
import { EnergieSparte } from '../Config/hooks';
import {
  ZAEHLERNUMMERN,
  ZAEHLERNUMMERN_STROM,
  ZAEHLERNUMMERN_WAERME,
} from './components/forms/fields';
import {
  ZAEHLERNUMMER,
  ZAEHLER_ERSETZT_AM,
  ZAEHLER_FINALER_ZAEHLERSTAND,
  ZAEHLER_WANDLERFAKTOR,
} from './components/forms/limboZaehlernummernFieldArray';
import {
  ZAEHLERSTAND_MESSWERT,
  ZAEHLERSTAND_ZEITPUNKT,
} from './components/zaehler-werte-erfassen/zaehlerstaende-erfassen-form/forms/form-fields';
import { getDateWithHoursAndMinutesSetToZero } from './components/zaehler-werte-erfassen/zaehlerstaende-erfassen-form/helper';
import { ZAEHLPUNKT_TYPE_UNIT_MAP } from './constants';
import { Status } from './types';

interface GetUnitByType {
  zaehlpunktTyp: EnergieSparte;
}

export const getUnitByType = ({ zaehlpunktTyp }: GetUnitByType) =>
  ZAEHLPUNKT_TYPE_UNIT_MAP[zaehlpunktTyp];

export const convertToExcelFile = (data: string, fileName: string) => {
  const decodedData = Buffer.from(data, 'base64');
  const blob = new Blob([decodedData], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  });
  saveAs(blob, `${fileName}.xlsx`);
};

export const getStatus = (status?: Status) =>
  status === undefined ? true : status === Status.aktiv;

export const getIsRLMZaehler = (liveMessung: number) => liveMessung === 1;

export const getIsIMSZaehler = (liveMessung: number) => liveMessung === 2;

export const getIsInactiveZaehler = (status: boolean) =>
  status === !!Status.inaktiv;

interface GetFinalerZaehlerstandFromFormValues {
  id: string;
  zaehlernummern: any[];
}

export const getFinalerZaehlerstandFromFormValues = (
  values: GetFinalerZaehlerstandFromFormValues
) => {
  // NOTE: only replaced zaehler need to be considered
  const zaehlernummern = values[ZAEHLERNUMMERN.name].filter(
    (zn) =>
      !!zn[ZAEHLER_ERSETZT_AM.fieldName] &&
      !!zn[ZAEHLER_FINALER_ZAEHLERSTAND.fieldName]
  );

  return zaehlernummern.reduce((acc, zaehler) => {
    const wert = zaehler[ZAEHLER_FINALER_ZAEHLERSTAND.fieldName];

    const zeitpunkt = getDateWithHoursAndMinutesSetToZero(
      new Date(zaehler[ZAEHLER_ERSETZT_AM.fieldName])
    );

    return [
      ...acc,
      {
        id: values.id,
        [ZAEHLERSTAND_MESSWERT.fieldName]: wert,
        [ZAEHLERSTAND_ZEITPUNKT.fieldName]: zeitpunkt,
      },
    ];
  }, []);
};

interface GetFinalerWaermepumpenZaehlerstandFromFormValues {
  id: string;
  zaehlernummernStrom: any[];
  zaehlernummernWaerme: any[];
}

export const getFinalerWaermepumpenZaehlerstandFromFormValues = (
  values: GetFinalerWaermepumpenZaehlerstandFromFormValues
) => {
  // NOTE: only replaced zaehler need to be considered
  const zaehlernummernStrom = values[ZAEHLERNUMMERN_STROM.name].filter(
    (zn) =>
      !!zn[ZAEHLER_ERSETZT_AM.fieldName] &&
      !!zn[ZAEHLER_FINALER_ZAEHLERSTAND.fieldName]
  );
  const zaehlernummernWaerme = values[ZAEHLERNUMMERN_WAERME.name].filter(
    (zn) =>
      !!!!zn[ZAEHLER_ERSETZT_AM.fieldName] &&
      !!zn[ZAEHLER_FINALER_ZAEHLERSTAND.fieldName]
  );

  const uniqueZeitpunkte = Array.from(
    new Set(
      [...zaehlernummernStrom, ...zaehlernummernWaerme].map((zn) =>
        getDateWithHoursAndMinutesSetToZero(
          new Date(zn[ZAEHLER_ERSETZT_AM.fieldName])
        ).toString()
      )
    )
  ) as Array<any>;

  //NOTE: strom and waerme werte need to be in one zaehlerstand object
  return uniqueZeitpunkte.reduce((acc, zeitpunkt) => {
    const stromZaehlerstand = zaehlernummernStrom.find(
      (zn) =>
        getDateWithHoursAndMinutesSetToZero(
          new Date(zn[ZAEHLER_ERSETZT_AM.fieldName])
        ).toString() == zeitpunkt.toString()
    );
    const waermeZaehlerstand = zaehlernummernWaerme.find(
      (zn) =>
        getDateWithHoursAndMinutesSetToZero(
          new Date(zn[ZAEHLER_ERSETZT_AM.fieldName])
        ).toString() == zeitpunkt.toString()
    );

    return [
      ...acc,
      {
        id: values.id,
        [ZAEHLERSTAND_ZEITPUNKT.fieldName]: getDateWithHoursAndMinutesSetToZero(
          new Date(zeitpunkt)
        ),
        wertStrom: !!stromZaehlerstand
          ? stromZaehlerstand[ZAEHLER_FINALER_ZAEHLERSTAND.fieldName]
          : null,
        wertWaerme: !!waermeZaehlerstand
          ? waermeZaehlerstand[ZAEHLER_FINALER_ZAEHLERSTAND.fieldName]
          : null,
        nulldurchgangStrom: false,
        nulldurchgangWaerme: false,
      },
    ];
  }, []);
};

export const filterAndNormalizeZaehlerAttributes = (zaehlernummern: any[]) => {
  const ATTRIBUTES = [
    ZAEHLERNUMMER.fieldName,
    ZAEHLER_ERSETZT_AM.fieldName,
    ZAEHLER_WANDLERFAKTOR.fieldName,
  ];
  return zaehlernummern.map((zn) => {
    return Object.keys(zn)
      .filter((key) => ATTRIBUTES.indexOf(key) >= 0)
      .reduce((acc, key) => {
        if (key === ZAEHLER_ERSETZT_AM.fieldName) {
          const ersetztAm = Date.parse(zn[key]);
          return {
            ...acc,
            [key]: isNaN(ersetztAm)
              ? undefined
              : getDateWithHoursAndMinutesSetToZero(new Date(zn[key])),
          };
        } else {
          return { ...acc, [key]: zn[key] };
        }
      }, {});
  });
};
