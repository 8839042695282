import {
  UNTERKATEGORIE_KLAERWERK,
  UNTERKATEGORIE_SONSTIGES_BY_KUNDEN_TYP,
  UNTERKATEGORIE_STRASSENBELEUCHTUNG,
} from '../helper';
import * as KlaerwerkLiegenschaft from './klaerwerk/liegenschaft.schema.json';
import * as SonstigesLiegenschaft from './sonstiges/liegenschaft.schema.json';
import * as StandardLiegenschaft from './standard/liegenschaft.schema.json';
import * as StrassenbeleuchtungLiegenschaft from './strassenbeleuchtung/liegenschaft.schema.json';

export const getLiegenschaftenPropsByUnterkategorie = (
  unterkategorie: number,
  kundenTyp: string
) => {
  switch (unterkategorie) {
    case UNTERKATEGORIE_STRASSENBELEUCHTUNG:
      return StrassenbeleuchtungLiegenschaft.properties;
    case UNTERKATEGORIE_KLAERWERK:
      return KlaerwerkLiegenschaft.properties;
    case UNTERKATEGORIE_SONSTIGES_BY_KUNDEN_TYP(kundenTyp):
      return SonstigesLiegenschaft.properties;
    default:
      return StandardLiegenschaft.properties;
  }
};
