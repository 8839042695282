import { Button } from '@material-ui/core';
import styled from '@emotion/styled-base';

const StyledButton = styled(Button)`
  .MuiButton-label {
    font-weight: 700;
    font-size: 1rem;
  }
`;

export const LiteButton = () => {
  return (
    <StyledButton
      onClick={() => window.open('https://www.limbo.energy', '_blank')}
      variant="text"
      style={{
        margin: 'auto',
        display: 'block',
        width: '90%',
        textTransform: 'none',
        color: 'white',
        backgroundColor: '#f1504f',
      }}
    >
      Zur LiMBO Vollversion
    </StyledButton>
  );
};
