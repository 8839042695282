import * as ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { LimboGoogleMapsLoadScript } from 'src/components/molecules/map/load-script';
import App from './App';
import { GlobalProvider } from './provider';
import * as serviceWorkerRegistration from './service-worker-registration';

ReactDOM.render(
  <BrowserRouter>
    <GlobalProvider>
      <LimboGoogleMapsLoadScript>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </LimboGoogleMapsLoadScript>
    </GlobalProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
