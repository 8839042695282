import { createTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

export const getCustomTheme = ({
  primary,
  secondary,
  fontFamily,
}: {
  primary: string;
  secondary: string;
  fontFamily: string;
}) =>
  createTheme({
    props: {
      MuiTextField: {
        variant: 'outlined',
        fullWidth: true,
      },
      MuiSelect: {
        variant: 'outlined',
      },
      MuiButton: {
        variant: 'contained',
        color: 'primary',
        size: 'large',
      },
    },
    overrides: {
      MuiButton: {
        containedSizeLarge: {
          fontSize: '1rem',
          fontWeight: 700,
          textTransform: 'uppercase',
        },
      },
      MuiPaper: {
        rounded: { borderRadius: '12px' },
      },
      MuiSelect: {
        icon: {
          color: secondary,
        },
      },
      MuiListItem: {
        root: {
          '&$selected': {
            backgroundColor: hexToRGB(secondary, 0.1),
            '&:hover': {
              backgroundColor: hexToRGB(secondary, 0.1),
            },
          },
        },
        button: {
          '&:hover': {
            backgroundColor: hexToRGB(secondary, 0.1),
          },
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '0.9rem',
          lineHeight: 1.5,
        },
      },
      MuiTableCell: {
        head: {
          fontWeight: 600,
        },
      },
      MuiFormControl: {
        root: {
          marginBottom: '1rem',
        },
      },
      MuiDialogActions: {
        root: {
          justifyContent: 'space-between',
        },
      },
      MuiStepLabel: {
        root: {
          '&$active': {
            fontWeight: 600,
            fontSize: '0.9rem',
          },
        },
        alternativeLabel: {
          fontWeight: 600,
          fontSize: '0.9rem',
        },
      },
      MuiFormLabel: {
        root: {
          color: primary,
        },
      },
    },
    palette: {
      text: {
        primary: primary,
      },
      background: {
        default: '#F2F2F2',
      },
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      grey,
      action: {
        disabledBackground: '#E8E8E8',
        disabled: '#D2D2D2',
      },
    },
    typography: {
      fontFamily: fontFamily,
      h1: {
        fontWeight: 700,
        fontSize: '1.5rem',
        textTransform: 'uppercase',
      },
      h2: {
        fontWeight: 700,
        fontSize: '1.2rem',
        textTransform: 'uppercase',
      },
      h3: {
        fontWeight: 700,
        fontSize: '1rem',
        textTransform: 'uppercase',
      },
      h4: {
        fontWeight: 700,
        fontSize: '0.9rem',
      },
    },
    spacing: (factor) => `${0.25 * factor}rem`, // (Bootstrap strategy)
  });

const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};
