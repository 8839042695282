import { Stack } from 'src/components/atoms/stack';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import { IconButton, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

export const TitleWithTooltip = ({ children }) => (
  <Stack spacing="0.25rem" alignItems="center">
    <span>{children}</span>
    <span>
      <HelpIcon style={{ fontSize: 15, opacity: 0.4 }} />
    </span>
  </Stack>
);

const SPACING = '0.75rem';

export const StammdatenTooltipContent = () => (
  <Stack flexDirection="column" spacing={SPACING}>
    <Stack alignItems="start" spacing={SPACING}>
      <IconButton size="small">
        <ViewIcon fontSize="small" />
      </IconButton>
      <Typography>
        Alle Stammdaten der zugeordneten Liegenschaften sind einsehbar.
      </Typography>
    </Stack>
    <Stack alignItems="flex-start" spacing={SPACING}>
      <IconButton size="small">
        <EditIcon fontSize="small" />
      </IconButton>
      <Typography>
        Die Stammdaten der zugeordneten Liegenschaften können bearbeitet werden.
      </Typography>
    </Stack>
  </Stack>
);

export const ZaehlerstaendeTooltipContent = () => (
  <Stack flexDirection="column" spacing={SPACING}>
    <Stack alignItems="flex-start" spacing={SPACING}>
      <IconButton size="small">
        <ViewIcon fontSize="small" />
      </IconButton>
      <Typography>
        Die Zählerstände und Mengen der zugeordneten Liegenschaften sind
        einsehbar.
      </Typography>
    </Stack>
    <Stack alignItems="flex-start" spacing={SPACING}>
      <IconButton size="small">
        <EditIcon fontSize="small" />
      </IconButton>
      <Typography>
        Die Zählerstände und Mengen können für zugeordnete Liegenschaften
        erfasst und bearbeitet werden.
      </Typography>
    </Stack>
  </Stack>
);

export const RechnungenTooltipContent = () => (
  <Stack flexDirection="column" spacing={SPACING}>
    <Stack alignItems="flex-start" spacing={SPACING}>
      <IconButton size="small">
        <ViewIcon fontSize="small" />
      </IconButton>
      <Typography>Die hinterlegten Rechnungen sind einsehbar.</Typography>
    </Stack>
    <Stack alignItems="flex-start" spacing={SPACING}>
      <IconButton size="small">
        <EditIcon fontSize="small" />
      </IconButton>
      <Typography>
        Rechnungen können hinzugefügt und editiert werden.
      </Typography>
    </Stack>
  </Stack>
);
