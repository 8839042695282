import { getHynesEnergyReportFooter } from './hynes';
import { getLimboEnergyReportFooter } from './limbo';

export const HYNES_HOSTNAME = 'hynemo.hynes.eu';

export const getEnergyReportFooter = () => {
  const hostname = window.location.hostname;

  switch (hostname) {
    case HYNES_HOSTNAME:
      return getHynesEnergyReportFooter();
    default:
      return getLimboEnergyReportFooter();
  }
};
