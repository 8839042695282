import styled from '@emotion/styled';
import { Grid, Typography } from '@material-ui/core';
import {
  useVerwaltung,
  useVerwaltungen,
} from 'src/modules/Einstellungen/hooks';
import { MOBILE_BREAKPOINT } from 'src/utils/hooks/use-small-device';
import Logo from '../../assets/limbo_logo.svg';
import TruncateTextWithTooltip from '../molecules/limbo-truncate-text-with-tooltip';

const StyledGrid = styled(Grid)<{ config: any }>(({ theme, config }) => ({
  ...(config
    ? {
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]:
          config[theme.breakpoints.down(MOBILE_BREAKPOINT)].margin,
      }
    : {}),
  margin: config?.margin || '3.75rem 2rem 32px',
}));

const AdministrationName = styled(Typography)`
  max-width: 100%;
  margin-top: 16px;
`;

export const Brand = () => {
  const { verwaltung } = useVerwaltung();
  const { verwaltungen } = useVerwaltungen();
  const { customThemeConfig } = verwaltung;

  return (
    <StyledGrid config={customThemeConfig}>
      <img
        src={customThemeConfig?.logoUrl || Logo}
        alt={'Logo'}
        style={{
          maxWidth: '130px',
          maxHeight: '120px',
          width: customThemeConfig?.width || '130px',
          height: customThemeConfig?.height || 'inherit',
        }}
      />

      {verwaltung && verwaltungen && verwaltungen.length > 1 && (
        <TruncateTextWithTooltip tooltip={verwaltung.name}>
          <AdministrationName variant="inherit">
            {verwaltung.name}
          </AdministrationName>
        </TruncateTextWithTooltip>
      )}
    </StyledGrid>
  );
};
