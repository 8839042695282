interface SearchObject {
  [key: string]: string[];
}

export const parseQueryString = (search: string) => {
  const regex = /([^?=&]+)=([^&]*)?/g;
  const match = search.match(regex) || [];

  return match.reduce<SearchObject>((acc, item) => {
    const [key, value] = item.split('=');
    return { ...acc, [key]: Array.from(new Set([...(acc[key] || []), value])) };
  }, {});
};

export const buildQueryString = (searchObject: SearchObject): string => {
  const accumulatedKeys = Object.keys(searchObject)
    .reduce<string[]>((acc, key) => {
      const flatWithKeys = searchObject[key].reduce<string[]>(
        (acc, value) => [...acc, `${key}=${value}`],
        []
      );

      return [...acc, ...flatWithKeys];
    }, [])
    .join('&');

  return `?${accumulatedKeys}`;
};

export const addToSearchObject = (
  searchObject: SearchObject,
  key: string,
  value: string | string[]
) => {
  return {
    ...Object.keys(searchObject)
      .filter((k) => k !== key)
      .reduce((acc, key) => ({ ...acc, [key]: searchObject[key] }), {}),
    ...(typeof value === 'string' || value.length
      ? {
          [key]: Array.from(
            new Set([
              ...(typeof value !== 'string' && value?.length > 0
                ? value
                : [...(searchObject[key] || []), value]),
            ])
          ),
        }
      : {}),
  };
};

export const removeFromSearchObject = (
  searchObject: SearchObject,
  key: string,
  value: string
) => {
  const newValues = searchObject[key]?.filter((i) => i !== value);

  if (!newValues) return searchObject;

  if (newValues.length === 0) {
    return Object.keys(searchObject)
      .filter((i) => i !== key)
      .reduce((acc, item) => ({ ...acc, [item]: searchObject[item] }), {});
  }

  return {
    ...searchObject,
    [key]: newValues,
  };
};

export const replaceInSearchObject = (
  searchObject: SearchObject,
  key: string,
  value: string | string[]
) => {
  return {
    ...Object.keys(searchObject)
      .filter((k) => k !== key)
      .reduce((acc, key) => ({ ...acc, [key]: searchObject[key] }), {}),
    ...(typeof value === 'string' || value.length
      ? {
          [key]: Array.from(
            new Set([
              ...(typeof value !== 'string' && value?.length > 0
                ? value
                : [value]),
            ])
          ),
        }
      : {}),
  };
};
