import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import { SnackbarProvider } from 'notistack';

export const GlobalProvider: React.FC = ({ children }) => (
  <StylesProvider injectFirst>
    <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <CssBaseline />
        {children}
      </SnackbarProvider>
    </MuiPickersUtilsProvider>
  </StylesProvider>
);
