import * as React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { LoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';

const libraries: Libraries = ['places'];

export const LimboGoogleMapsLoadScript: React.FC = ({ children }) => (
  <LoadScript
    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
    language="de"
    region="DE"
    libraries={libraries}
    loadingElement={
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    }
  >
    {children}
  </LoadScript>
);
