import * as React from 'react';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { useUserTrackingExport } from 'src/utils/hooks/useUsertracking';
import { useSnackbar } from 'notistack';
import { convertToExcelFile } from 'src/modules/Zaehlpunkt/helper';

const StyledButton = styled(Button)`
  .MuiButton-label {
    font-weight: 700;
    font-size: 1rem;
  }
`;

export const ExportUserTrackingButton = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { exportUserTrackingData } = useUserTrackingExport();

  const handleExportUserTrackingData = React.useCallback(async () => {
    try {
      const response = await exportUserTrackingData();
      convertToExcelFile(response.data, 'user-tracking-daten');
    } catch (_) {
      enqueueSnackbar(
        'Beim Herunterladen der Datei ist ein Fehler aufgetreten.',
        {
          variant: 'error',
        }
      );
    }
  }, [enqueueSnackbar, exportUserTrackingData]);

  return (
    <StyledButton
      onClick={handleExportUserTrackingData}
      variant="text"
      style={{
        margin: 'auto',
        display: 'block',
        width: '90%',
        textTransform: 'none',
        color: 'white',
        backgroundColor: '#f1504f',
      }}
    >
      Export User Tracking
    </StyledButton>
  );
};
