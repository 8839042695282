import * as React from 'react';
import { ENDPOINTS } from 'src/config';
import useSWR, { mutate } from 'swr';
import httpService, { fetcher } from '../httpService';

export const useUserTracking = () => {
  const { data, error } = useSWR(ENDPOINTS.API_USER_TRACKING, fetcher, {
    revalidateOnFocus: false,
  });

  const refetch = React.useCallback(() => {
    mutate(ENDPOINTS.API_USER_TRACKING);
  }, []);

  return {
    refetch,
    status: data || {},
    isLoading: !error || !data,
    isError: error,
  };
};

export const useUserTrackingExport = () => {
  const exportUserTrackingData = React.useCallback(async () => {
    return httpService.get(ENDPOINTS.API_USER_TRACKING_EXPORT);
  }, []);

  return {
    exportUserTrackingData,
  };
};
