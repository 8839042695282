import * as React from 'react';
import useSWR, { mutate } from 'swr';
import { ENDPOINTS } from 'src/config';
import httpService, { fetcher } from 'src/utils/httpService';
import { useSnackbar } from 'notistack';

export const useBenutzer = () => {
  const { data, error } = useSWR(
    ENDPOINTS.DEV_API_NUTZER_BERECHTIGUNGEN,
    fetcher
  );

  const refetch = React.useCallback(() => {
    mutate(ENDPOINTS.DEV_API_NUTZER_BERECHTIGUNGEN);
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const handleBenutzerHinzufuegen = React.useCallback(
    async (benutzer) => {
      const response = await httpService
        .post(ENDPOINTS.DEV_API_NUTZER_BERECHTIGUNGEN, benutzer)
        .catch((error) => {
          if (error.response.status === 422) {
            enqueueSnackbar(
              'Ein Benutzer mit dieser E-Mail Adresse existiert bereits',
              {
                variant: 'error',
              }
            );
          } else {
            enqueueSnackbar('Benutzer konnte nicht hinzugefügt werden', {
              variant: 'error',
            });
          }
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  const handleBenutzerLoeschen = React.useCallback(
    async ({ userId }) => {
      const response = await httpService
        .delete(`${ENDPOINTS.DEV_API_NUTZER_BERECHTIGUNGEN}/${userId}`)
        .catch(() => {
          enqueueSnackbar('Benutzer konnte nicht gelöscht werden', {
            variant: 'error',
          });
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  const handleBenutzerUpdate = React.useCallback(
    async (data) => {
      const response = await httpService
        .put(`${ENDPOINTS.DEV_API_NUTZER_BERECHTIGUNGEN}/${data.id}`, data)
        .catch(() => {
          enqueueSnackbar('Berechtigung konnte nicht aktualisiert werden', {
            variant: 'error',
          });
        });
      refetch();
      return response;
    },
    [enqueueSnackbar, refetch]
  );

  return {
    benutzerHinzufuegen: handleBenutzerHinzufuegen,
    benutzerLoeschen: handleBenutzerLoeschen,
    getBenutzer: refetch,
    benutzerUpdaten: handleBenutzerUpdate,
    benutzer: data || [],
    isLoading: !error && !data,
    isError: error,
  };
};

export enum Berechtigung {
  EditAdminData = 'editAdminData',
  ViewMeterReadings = 'viewMeterReadings',
  EditMeterReadings = 'editMeterReadings',
  ViewMetaData = 'viewMetaData',
  EditMetaData = 'editMetaData',
  ViewInvoices = 'viewInvoices',
  EditInvoices = 'editInvoices',
  SuperUser = 'superUser',
  GlobalSuperUser = 'globalSuperUser',
}

export interface Berechtigungen {
  [Berechtigung.ViewMeterReadings]: boolean;
  [Berechtigung.EditMeterReadings]: boolean;
  [Berechtigung.ViewMetaData]: boolean;
  [Berechtigung.EditMetaData]: boolean;
  [Berechtigung.ViewInvoices]: boolean;
  [Berechtigung.EditInvoices]: boolean;
  [Berechtigung.SuperUser]: boolean;
  [Berechtigung.GlobalSuperUser]: boolean;
}
interface User {
  id: string;
  berechtigungen: Berechtigungen;
  liegenschaften: string[];
  nachname: string;
  telefon: string;
  vorname: string;
  email: string;
}

interface UseCurrentUserReturn {
  user: User | null;
  refetch: () => void;
  isLoading: boolean;
  error: any;
}

export const useCurrentUser = (): UseCurrentUserReturn => {
  const { data, error } = useSWR(ENDPOINTS.API_CURRENT_USER, fetcher, {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  });

  const refetch = React.useCallback(() => {
    mutate(ENDPOINTS.API_CURRENT_USER);
  }, []);

  return {
    user: data || null,
    refetch,
    isLoading: !error && !data,
    error,
  };
};
