import { isAfter, isBefore, isEqual } from 'date-fns';
import { Zaehler, Zaehlerstand } from 'src/modules/Zaehlpunkt/types';
import { sortDate } from 'src/utils/sorting';

interface GetValidationDateRange {
  date: string;
  dates: Array<string>;
}

export const getDateRangeByDate = ({
  date,
  dates,
}: GetValidationDateRange): [string | undefined, string | undefined] => {
  const sortedDates = dates.sort(sortDate);
  const reversedDates = [...sortedDates].reverse();

  const from = reversedDates.find((d) => {
    const existingDate = new Date(d);
    const newDate = new Date(date);
    return isBefore(existingDate, newDate);
  });
  const to = dates.find((d) => {
    const existingDate = new Date(d);
    const newDate = new Date(date);
    return isAfter(existingDate, newDate) || isEqual(existingDate, newDate);
  });

  return [from, to];
};

interface GetZaehlerStaendeByNewDate {
  zaehlerstaende: Pick<Zaehlerstand, 'zeitpunkt'>[];
  newDate: string;
  zaehlernummern: Zaehler[];
}
export const getZaehlerStaendeByNewDate = ({
  zaehlerstaende,
  newDate,
  zaehlernummern,
}: GetZaehlerStaendeByNewDate) => {
  const [from, to] = getDateRangeByDate({
    date: newDate,
    dates: zaehlernummern
      .filter((zn) => zn.ersetztAm)
      .map((zn) => zn.ersetztAm!),
  });

  const dateFrom = from ? new Date(from) : false;
  const dateTo = to ? new Date(to) : false;

  const filteredZaehlerstaende = zaehlerstaende.filter((zs) => {
    const current = new Date(zs.zeitpunkt);
    return (
      (!dateFrom || isAfter(current, dateFrom)) &&
      (!dateTo || isEqual(current, dateTo) || isBefore(current, dateTo))
    );
  });

  return filteredZaehlerstaende;
};

export const getDateWithHoursAndMinutesSetToZero = (date: Date) =>
  new Date(date.setHours(0, 0, 0, 0));
