import * as React from 'react';
import { Box, FormControlLabel, Switch, Typography } from '@material-ui/core';
import LimboToolTip from 'src/components/molecules/limbo-tool-tip';
import { withFormikField } from 'src/utils/formik';

interface LimboSwitchProps {
  label: string;
  tooltip: string;
  dateFormat?: string;
  onChange: ({ target: { value } }: any) => void;
  value: boolean;
}

export const LimboSwitch: React.FC<LimboSwitchProps> = ({
  label,
  tooltip,
  value,
  onChange,
  ...props
}) => {
  const toggleChecked = React.useCallback(
    (e, checked) => {
      onChange({ ...e, target: { value: checked } });
    },
    [onChange]
  );
  return (
    <Box style={{ position: 'relative' }}>
      {tooltip && (
        <LimboToolTip tooltip={<Typography>{tooltip}</Typography>} {...props} />
      )}
      <FormControlLabel
        control={<Switch checked={value} onChange={toggleChecked} />}
        label={label}
        {...props}
      />
    </Box>
  );
};

const LimboFormikSwitch = withFormikField(LimboSwitch);

export default LimboFormikSwitch;
