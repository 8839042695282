import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { addDays } from 'date-fns';
import { FieldArray, getIn, useField, useFormikContext } from 'formik';
import * as React from 'react';
import { AddOutlinedButton } from 'src/components/atoms/buttons/add-button-outline';
import LimboFormikDatePicker from 'src/components/atoms/form-fields/limboDatePicker';
import LimboFormikMultiChipSelect from 'src/components/atoms/form-fields/limboMultiChipSelect';
import LimboFormikMultiChipSelectWithGroups from 'src/components/atoms/form-fields/limboMultiChipSelectWithGroups';
import LimboFormikNumberField from 'src/components/atoms/form-fields/limboNumberField';
import LimboFormikSelect, {
  LimboSelect,
} from 'src/components/atoms/form-fields/limboSelect';
import LimboFormikTextField from 'src/components/atoms/form-fields/limboTextField';
import LimboFormikYearPicker from 'src/components/atoms/form-fields/limboYearPicker';
import FormikSearchLocaltionField from 'src/components/atoms/form-fields/searchLocationField';
import { DeleteIcon } from 'src/components/atoms/icons';
import { TooltipPopper } from 'src/components/atoms/tooltip';
import { EnergieSparte, useConfig } from 'src/modules/Config/hooks';
import { ZAEHLPUNKT_TYPE } from 'src/modules/Zaehlpunkt/components/forms/fields';
import {
  increasingDate,
  uniqueDate,
} from 'src/modules/Zaehlpunkt/components/forms/validation';
import { HEIZUNGS_TYP_WAERMEPUMPE } from 'src/modules/Zaehlpunkt/constants';
import { useZaehlpunkte } from 'src/modules/Zaehlpunkt/hooks';
import { validateDecimalPlacesOfNumber } from 'src/utils/formatNumbers';
import { phoneRegExp } from 'src/utils/patterns';
import { alphabeticalSort } from 'src/utils/sorting';
import { validate } from 'src/utils/yup';
import * as Yup from 'yup';
import { UNTERKATEGORIE_STRASSENBELEUCHTUNG } from '../../helper';
import {
  useDropDownData,
  useLiegenschaften,
  useLiegenschaftenGruppen,
} from '../../hooks';
import { hasData, uniqueName } from './validations';

interface SelectLiegenschaftProps {
  onSelect: (liegenschaft: any) => void;
  liegenschaftId?: string;
  disabled?: boolean;
  required?: boolean;
}

export const SelectLiegenschaft: React.FC<SelectLiegenschaftProps> = ({
  onSelect,
  liegenschaftId,
  required,
  disabled,
}) => {
  const { liegenschaften } = useLiegenschaften();
  const { liegenschaftenDropDown } = useDropDownData(liegenschaften);

  const handleChange = React.useCallback(
    ({ target: { value } }) => {
      onSelect(value);
    },
    [onSelect]
  );

  return (
    <LimboSelect
      label={'Liegenschaft'}
      onChange={handleChange}
      value={liegenschaftId}
      data={liegenschaftenDropDown}
      required={required}
      disabled={disabled}
    />
  );
};

const SELECT_LIEGENSCHAFT_FORMIK = {
  name: 'liegenschaftId',
  label: 'Liegenschaft auswählen',
  validationSchema: validate(Yup.string().required('Bitte auswählen')),
};

export const SelectLiegenschaftFormik: React.FC<SelectLiegenschaftProps> = ({
  onSelect,
  liegenschaftId,
  disabled,
  required,
}) => {
  const { liegenschaften } = useLiegenschaften();
  const { liegenschaftenDropDown } = useDropDownData(liegenschaften);

  const handleChange = React.useCallback(
    ({ target: { value } }) => {
      onSelect(value);
    },
    [onSelect]
  );

  return (
    <LimboFormikSelect
      name={SELECT_LIEGENSCHAFT_FORMIK.name}
      label={SELECT_LIEGENSCHAFT_FORMIK.label}
      onChange={handleChange}
      defaultValue={liegenschaftId}
      data={liegenschaftenDropDown}
      required={required}
      disabled={disabled}
      validate={SELECT_LIEGENSCHAFT_FORMIK.validationSchema}
    />
  );
};

export const LIEGENSCHAFT_BEZEICHNUNG = {
  name: 'liegenschaftsbezeichnung',
  label: 'Name der Liegenschaft',
  validationSchema: validate(
    Yup.string()
      .required('Bitte Name vergeben')
      .min(6, 'Der Name muss mindestens 6 Zeichen lang sein.')
  ),
};

export const LiegenschaftBezeichnung = (props: any) => (
  <LimboFormikTextField
    name={LIEGENSCHAFT_BEZEICHNUNG.name}
    label={LIEGENSCHAFT_BEZEICHNUNG.label}
    validate={LIEGENSCHAFT_BEZEICHNUNG.validationSchema}
    required
    {...props}
  />
);

export const LIEGENSCHAFT_LOCATION = {
  name: 'address',
  label: 'Adresse',
  validationSchema: (required) =>
    validate(
      Yup.lazy((value: any) =>
        required || value
          ? Yup.object()
              .shape({
                adressString: Yup.string(),
                lat: Yup.number(),
                lng: Yup.number(),
              })
              .required('Bitte Straße eingeben')
              .typeError('Bitte Adresse eingeben')
          : Yup.string()
      )
    ),
};

export const LiegenschaftLocation = ({
  handleSearchBarChange,
  required = true,
  ...props
}: any) => (
  <FormikSearchLocaltionField
    name={LIEGENSCHAFT_LOCATION.name}
    label={LIEGENSCHAFT_LOCATION.label}
    returnedAdress={handleSearchBarChange}
    validate={LIEGENSCHAFT_LOCATION.validationSchema(required)}
    required={required}
    {...props}
  />
);

export const ANSPRECHPARTNER_VORNAME = {
  name: 'vorname',
  label: 'Vorname',
  validationSchema: validate(Yup.string()),
};

export const AnsprechpartnerVorname = (props: any) => (
  <LimboFormikTextField
    name={ANSPRECHPARTNER_VORNAME.name}
    label={ANSPRECHPARTNER_VORNAME.label}
    validate={ANSPRECHPARTNER_VORNAME.validationSchema}
    {...props}
  />
);

export const ANSPRECHPARTNER_NACHNAME = {
  name: 'nachname',
  label: 'Nachname',
  validationSchema: validate(Yup.string()),
};

export const AnsprechpartnerNachname = (props: any) => (
  <LimboFormikTextField
    name={ANSPRECHPARTNER_NACHNAME.name}
    label={ANSPRECHPARTNER_NACHNAME.label}
    validate={ANSPRECHPARTNER_NACHNAME.validationSchema}
    {...props}
  />
);

export const ANSPRECHPARTNER_EMAIL = {
  name: 'email',
  label: 'E-Mail',
  validationSchema: validate(
    Yup.string().email('Bitte eine valide E-Mail eintragen')
  ),
};

export const AnsprechpartnerEmail = (props: any) => (
  <LimboFormikTextField
    name={ANSPRECHPARTNER_EMAIL.name}
    label={ANSPRECHPARTNER_EMAIL.label}
    validate={ANSPRECHPARTNER_EMAIL.validationSchema}
    type="email"
    {...props}
  />
);

export const ANSPRECHPARTNER_TELEFON = {
  name: 'telefon',
  label: 'Telefonnummer',
  validationSchema: validate(
    Yup.string().matches(phoneRegExp, 'Gültige Telefonnummer eingeben')
  ),
};

export const AnsprechpartnerTelefon = (props: any) => (
  <LimboFormikTextField
    name={ANSPRECHPARTNER_TELEFON.name}
    label={ANSPRECHPARTNER_TELEFON.label}
    validate={ANSPRECHPARTNER_TELEFON.validationSchema}
    {...props}
  />
);

export const ANSPRECHPARTNER_FUNKTION = {
  name: 'funktion',
  label: 'Funktion',
  requiredErrorMsg: 'Bitte Funktion der Ansprechperson angeben',
};

export const AnsprechpartnerFunktion = (props: any) => (
  <LimboFormikTextField
    name={ANSPRECHPARTNER_FUNKTION.name}
    label={ANSPRECHPARTNER_FUNKTION.label}
    {...props}
  />
);

// note: adding one second to todays date to handle current year selecetion corretly.
// Otherwise yup.date().max(new Date()) throws an error if max date is euqal to selected date.
const today = new Date();
const maxDate = new Date(today.setSeconds(today.getSeconds() + 1));

export const LIEGENSCHAFT_BAUJAHR = {
  name: 'baujahr',
  label: 'Baujahr',
  validationSchema: (required) =>
    validate(
      required
        ? Yup.date()
            .min(new Date('0300-01-01'))
            .max(maxDate)
            .typeError('Bitte Baujahr eingeben')
            .required('Bitte Baujahr eingeben')
        : Yup.date().min(new Date('0300-01-01')).max(maxDate).nullable()
    ),
};

export const LiegenschaftBaujahr = ({ required = true, ...props }) => (
  <LimboFormikYearPicker
    label={LIEGENSCHAFT_BAUJAHR.label}
    name={LIEGENSCHAFT_BAUJAHR.name}
    minDate="0300-01-01"
    maxDate={new Date()}
    validate={LIEGENSCHAFT_BAUJAHR.validationSchema(required)}
    required={required}
    {...props}
  />
);

export const GEBAEUDE_NUTZFLAECHE = {
  fieldName: 'flaeche',
  name: (index: number) =>
    `${ZEITLICHE_GEBAEUDEFLAECHEN.name}.${index}.${GEBAEUDE_NUTZFLAECHE.fieldName}`,
  label: 'Gebäudefläche',
  tooltip:
    'Der Energieverbrauchskennwert wird auf Basis der Gebäudefläche ermittelt.',
  validationSchema: (required) =>
    validate(
      required
        ? Yup.number()
            .min(1, 'Die Nutzfläche muss positiv sein.')
            .required('Bitte Gebäudefläche eingeben')
            .typeError('Bitte Gebäudefläche eingeben')
            .test({
              message: 'Bitte nur 2 Nachkommastellen eingeben',
              test: validateDecimalPlacesOfNumber(2),
            })
        : Yup.lazy((value: any) =>
            isNaN(value) || value === ''
              ? Yup.string().transform((currentValue, originalValue) =>
                  originalValue === null ? '' : currentValue
                )
              : Yup.number()
                  .min(1, 'Die Nutzfläche muss positiv sein.')
                  .typeError('Bitte Gebäudefläche eingeben')
                  .test({
                    message: 'Bitte nur 2 Nachkommastellen eingeben',
                    test: validateDecimalPlacesOfNumber(2),
                  })
          )
    ),
};

export const GebaeudeNutzflaeche = ({
  index,
  required = true,
  ...props
}: any) => (
  <LimboFormikNumberField
    name={GEBAEUDE_NUTZFLAECHE.name(index)}
    label={GEBAEUDE_NUTZFLAECHE.label}
    tooltip={GEBAEUDE_NUTZFLAECHE.tooltip}
    validate={GEBAEUDE_NUTZFLAECHE.validationSchema(required)}
    InputProps={{
      endAdornment: <InputAdornment position="end">m²</InputAdornment>,
    }}
    required={required}
    {...props}
  />
);

export const GEBAEUDE_FLAECHENTYP = {
  fieldName: 'flaechenTyp',
  name: (index: number) =>
    `${ZEITLICHE_GEBAEUDEFLAECHEN.name}.${index}.${GEBAEUDE_FLAECHENTYP.fieldName}`,
  label: 'Flächentyp',
  tooltip:
    'Je nachdem welche Flächenangabe zu der Liegenschaft vorliegt, können Sie den Flächentyp frei wählen.',
  validationSchema: (required) =>
    validate(
      required
        ? Yup.number()
            .required('Bitte Flächentyp angeben.')
            .typeError('Bitte Flächentyp angeben')
        : Yup.lazy((value: any) =>
            isNaN(value) || value === ''
              ? Yup.string().transform((currentValue, originalValue) =>
                  originalValue === null ? '' : currentValue
                )
              : Yup.number().typeError('Bitte Flächentyp angeben')
          )
    ),
};

export const GebaeudeFlaechentyp = ({
  index,
  required = true,
  ...props
}: any) => {
  const { config } = useConfig();
  return (
    <LimboFormikSelect
      data={config.flaechenTyp}
      tooltip={GEBAEUDE_FLAECHENTYP.tooltip}
      name={GEBAEUDE_FLAECHENTYP.name(index)}
      label={GEBAEUDE_FLAECHENTYP.label}
      validate={GEBAEUDE_FLAECHENTYP.validationSchema(required)}
      required={required}
      {...props}
    />
  );
};

const GEBAEUDE_FLAECHE_GUELTIG_BIS = {
  fieldName: 'gueltigBis',
  name: (index: number) =>
    `${ZEITLICHE_GEBAEUDEFLAECHEN.name}.${index}.${GEBAEUDE_FLAECHE_GUELTIG_BIS.fieldName}`,
  label: 'Gültig bis',
  validationSchema: (flaechen: Array<{ gueltigBis?: string }>) =>
    validate(
      Yup.date()
        .typeError('Bitte korrektes Datum eingeben')
        .required('Bitte Datum eingeben')
        .max(new Date(), 'Datum darf nicht in der Zukunft liegen')
        .test({
          message: 'Datum muss eindeutig sein',
          test: uniqueDate(GEBAEUDE_FLAECHE_GUELTIG_BIS.fieldName, flaechen),
        })
        .test({
          message: 'Datum muss aufsteigend sein',
          test: increasingDate(
            GEBAEUDE_FLAECHE_GUELTIG_BIS.fieldName,
            flaechen
          ),
        })
    ),
};

const GebaeudeFlaecheGueltigBis = ({ index, ...props }: any) => {
  const [, { error }] = useField(GEBAEUDE_FLAECHE_GUELTIG_BIS.name(index));
  const [{ value: flaechen }] = useField(ZEITLICHE_GEBAEUDEFLAECHEN.name);
  const lastFlaeche = index > 0 && flaechen[index - 1];

  return (
    <LimboFormikDatePicker
      name={GEBAEUDE_FLAECHE_GUELTIG_BIS.name(index)}
      label={GEBAEUDE_FLAECHE_GUELTIG_BIS.label}
      validate={GEBAEUDE_FLAECHE_GUELTIG_BIS.validationSchema(flaechen)}
      minDate={
        lastFlaeche?.gueltigBis && addDays(new Date(lastFlaeche.gueltigBis), 1)
      }
      maxDate={new Date()}
      dateFormat="yyyy-MM-dd"
      errorText={error}
      required
      {...props}
    />
  );
};

const ZEITLICHE_GEBAEUDEFLAECHEN = {
  name: 'flaechen',
  validationSchema: (required: boolean) =>
    validate(
      required
        ? Yup.array().of(
            Yup.object().shape({
              flaeche: Yup.string().required('Bitte Fläche eintragen'),
              flaechenTyp: Yup.number().required('Bitte Flächentyp angeben.'),
              gueltigBis: Yup.lazy((value: string) =>
                value !== '' ? Yup.date() : Yup.string()
              ),
            })
          )
        : Yup.array().of(
            Yup.object().shape({
              flaeche: Yup.lazy((value: any) =>
                isNaN(value) || value === ''
                  ? Yup.string().transform((currentValue, originalValue) =>
                      originalValue === null ? '' : currentValue
                    )
                  : Yup.number()
                      .min(1, 'Die Nutzfläche muss positiv sein.')
                      .typeError('Bitte Gebäudefläche eingeben')
                      .test({
                        message: 'Bitte nur 2 Nachkommastellen eingeben',
                        test: validateDecimalPlacesOfNumber(2),
                      })
              ),
              flaechenTyp: Yup.lazy((value: any) =>
                isNaN(value) || value === ''
                  ? Yup.string().transform((currentValue, originalValue) =>
                      originalValue === null ? '' : currentValue
                    )
                  : Yup.number().typeError('Bitte Flächentyp angeben')
              ),
              gueltigBis: Yup.lazy((value: string) =>
                value !== '' ? Yup.date() : Yup.string()
              ),
            })
          )
    ),
  defaultValue: (required) => [
    {
      [GEBAEUDE_NUTZFLAECHE.fieldName]: '',
      [GEBAEUDE_FLAECHENTYP.fieldName]: required ? 1 : '',
    },
  ],
};

export const ZeitlicheGebaeudeflaechen = ({ disabled, required = true }) => {
  const [rowsToEdit, setRowsToEdit] = React.useState<Array<number>>([]);
  const [newAddedRows, setNewAddedRows] = React.useState<Array<number>>([]);
  const [isCreatedMode, setIsCreateMode] = React.useState(false);
  const [field, _, { setValue }] = useField<
    Array<{ [key: string]: string | number }>
  >({
    name: ZEITLICHE_GEBAEUDEFLAECHEN.name,
    validate: ZEITLICHE_GEBAEUDEFLAECHEN.validationSchema(required),
  });

  React.useEffect(() => {
    if (!field.value) {
      setValue(ZEITLICHE_GEBAEUDEFLAECHEN.defaultValue(required));
      setIsCreateMode(true);
    }
  }, [field.value, required, setValue]);

  const handleAddFlaeche = React.useCallback(() => {
    setValue([
      ...field.value,
      ZEITLICHE_GEBAEUDEFLAECHEN.defaultValue(required)[0],
    ]);
    const newRows = [...newAddedRows, field.value.length];
    setNewAddedRows(newRows);
  }, [field.value, newAddedRows, required, setValue]);

  const handleDeleteFlaeche = React.useCallback(
    (indexToDelete: number) => {
      const lastItem = field.value.length - 1;
      const newFieldArray = [
        ...field.value.slice(0, indexToDelete),
        ...field.value.slice(indexToDelete + 1),
      ];

      if (indexToDelete === lastItem) {
        const [last, ...items] = [...newFieldArray].reverse();

        return setValue([
          ...items.reverse(),
          {
            [GEBAEUDE_NUTZFLAECHE.fieldName]:
              last[GEBAEUDE_NUTZFLAECHE.fieldName],
            [GEBAEUDE_FLAECHENTYP.fieldName]:
              last[GEBAEUDE_FLAECHENTYP.fieldName],
          },
        ]);
      }
      setValue(newFieldArray);
      const newRows = newAddedRows
        .filter((rowIndex) => rowIndex !== indexToDelete)
        .map((rowIndex) =>
          rowIndex > indexToDelete ? rowIndex - 1 : rowIndex
        );
      setNewAddedRows(newRows);
    },
    [field.value, newAddedRows, setValue]
  );

  const handleEditRow = React.useCallback(
    (index: number) => {
      const newRowsToEdit = [...rowsToEdit, index];
      setRowsToEdit(newRowsToEdit);
    },
    [rowsToEdit]
  );

  return (
    <Grid container direction="row" spacing={4}>
      <Grid item xs={12}>
        <FieldArray
          name={ZEITLICHE_GEBAEUDEFLAECHEN.name}
          render={() => (
            <>
              {field.value?.map((record, index) => {
                const isLastEntry = index === field.value.length - 1;
                const showEditButton =
                  !isCreatedMode &&
                  !rowsToEdit.includes(index) &&
                  !newAddedRows.includes(index);
                const showDeleteButton =
                  (isCreatedMode && field?.value?.length > 1) ||
                  (!isCreatedMode && newAddedRows.includes(index));

                return (
                  <Grid key={index} container direction="row" spacing={4}>
                    <Grid item xs={!isLastEntry ? 4 : 6}>
                      <GebaeudeNutzflaeche
                        index={index}
                        value={record[GEBAEUDE_NUTZFLAECHE.fieldName]}
                        disabled={disabled || showEditButton}
                        required={required}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={
                        !isLastEntry
                          ? 4
                          : showDeleteButton || showEditButton
                          ? 5
                          : 6
                      }
                    >
                      <GebaeudeFlaechentyp
                        index={index}
                        value={record[GEBAEUDE_FLAECHENTYP.fieldName]}
                        disabled={disabled || showEditButton}
                        required={
                          required || !!record[GEBAEUDE_NUTZFLAECHE.fieldName]
                        }
                      />
                    </Grid>
                    {!isLastEntry && (
                      <Grid item xs={3}>
                        <GebaeudeFlaecheGueltigBis
                          index={index}
                          value={record[GEBAEUDE_FLAECHE_GUELTIG_BIS.fieldName]}
                          disabled={
                            disabled ||
                            (showEditButton &&
                              !newAddedRows.includes(index + 1))
                          }
                        />
                      </Grid>
                    )}
                    {showDeleteButton && (
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => handleDeleteFlaeche(index)}
                          disabled={disabled}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                    {showEditButton && (
                      <Grid item xs={1}>
                        <Tooltip
                          interactive
                          title="Bitte nutzen Sie die Bearbeitenfunktion nur um
                          historische Daten zu korrigieren."
                          PopperComponent={TooltipPopper(
                            <Typography>
                              Bitte nutzen Sie die Bearbeitenfunktion nur um
                              fehlerhafte historische Daten zu korrigieren. Für
                              Änderungen an der Fläche legen Sie bitte über die
                              Schaltfläche "Flächenänderung" einen neuen Eintrag
                              an.
                            </Typography>
                          )}
                        >
                          <IconButton
                            onClick={() => handleEditRow(index)}
                            disabled={disabled}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </>
          )}
        />
        <Grid item xs={3} style={{ marginTop: '0.5rem' }}>
          <AddOutlinedButton onClick={handleAddFlaeche} disabled={disabled}>
            Flächenänderung
          </AddOutlinedButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const GEBAEUDE_KATEGORIE = {
  name: 'gebaeudeKategorie',
  label: 'Gebäudekategorie',
  tooltip: (
    <>
      Die energetische Bewertung erfolgt auf Basis der Gebäudekategorie. Eine
      Übersicht sämtlicher Gebäudekategorien nach Bauwerkszuordnungskatalog
      (BWZK) finden Sie
      <Link
        style={{ color: '#253048' }}
        target="_blank"
        underline="always"
        href="https://limbo.energy/wp-content/uploads/2021/05/Gebaeudekategorien-FAQ.pdf"
      >
        {' hier.'}
      </Link>
    </>
  ),
  validationSchema: validate(
    Yup.string().required('Bitte Gebäudekategorie auswählen')
  ),
};

export const GebaeudeKategorie = (props: any) => {
  const { config } = useConfig();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const gebaeudeKategory = getIn(values, GEBAEUDE_KATEGORIE.name);

  const gebaeudeKategorieDropDown = React.useMemo(
    () =>
      config.gebaeudeKategorie?.map(
        ({ id, label }: { id: string; label: string }) => ({
          value: id,
          label,
        })
      ),
    [config.gebaeudeKategorie]
  );

  const gebaeudeUnterkategorien = React.useMemo(
    () =>
      config.gebaeudeUnterkategorie?.filter(
        (item: { gebaeudeKategorieId: any }) =>
          item.gebaeudeKategorieId === gebaeudeKategory
      ),
    [config.gebaeudeUnterkategorie, gebaeudeKategory]
  );

  React.useEffect(() => {
    if (gebaeudeUnterkategorien?.length === 1) {
      setFieldValue(
        GEBAEUDE_UNTERKATEGORIE.name,
        gebaeudeUnterkategorien[0]?.id
      );
      setFieldTouched(GEBAEUDE_UNTERKATEGORIE.name, true, true);
    }
  }, [gebaeudeUnterkategorien, setFieldTouched, setFieldValue]);

  return (
    <LimboFormikSelect
      data={gebaeudeKategorieDropDown}
      label={GEBAEUDE_KATEGORIE.label}
      name={GEBAEUDE_KATEGORIE.name}
      tooltip={GEBAEUDE_KATEGORIE.tooltip}
      required
      validate={GEBAEUDE_KATEGORIE.validationSchema}
      {...props}
    />
  );
};

export const GEBAEUDE_UNTERKATEGORIE = {
  name: 'gebaeudeUnterkategorie',
  label: 'Gebäudeunterkategorie',
  tooltip: (
    <>
      Die energetische Bewertung erfolgt auf Basis der Gebäudekategorie. Eine
      Übersicht sämtlicher Gebäudekategorien nach Bauwerkszuordnungskatalog
      (BWZK) finden Sie
      <Link
        style={{ color: '#253048' }}
        target="_blank"
        underline="always"
        href="https://limbo.energy/wp-content/uploads/2021/05/Gebaeudekategorien-FAQ.pdf"
      >
        {' hier.'}
      </Link>
    </>
  ),
  validationSchema: validate(
    Yup.string().required('Bitte Gebäudeunterkategorie auswählen')
  ),
};

export const GebaeudeUnterkategorie = (props: any) => {
  const { values } = useFormikContext();
  const { config } = useConfig();
  const gebaeudeKategory = getIn(values, GEBAEUDE_KATEGORIE.name);

  const gebaeudeUnterkategorien = React.useMemo(
    () =>
      config.gebaeudeUnterkategorie?.filter(
        (item: { gebaeudeKategorieId: any }) =>
          item.gebaeudeKategorieId === gebaeudeKategory
      ),
    [config.gebaeudeUnterkategorie, gebaeudeKategory]
  );

  const gebaeudeUnterkategorienDropDown = React.useMemo(
    () =>
      gebaeudeUnterkategorien?.map(
        ({ id, label }: { id: string; label: string }) => ({ value: id, label })
      ),
    [gebaeudeUnterkategorien]
  );

  return (
    <LimboFormikSelect
      data={gebaeudeUnterkategorienDropDown}
      label={GEBAEUDE_UNTERKATEGORIE.label}
      name={GEBAEUDE_UNTERKATEGORIE.name}
      tooltip={GEBAEUDE_UNTERKATEGORIE.tooltip}
      validate={GEBAEUDE_UNTERKATEGORIE.validationSchema}
      required
      {...props}
    />
  );
};

export const GEBAEUDE_BEMERKUNG = {
  name: 'bemerkungen',
  label: 'Bemerkungen',
  requiredErrorMsg: '',
  tooltip: 'Hier ist Platz für Ihre Notizen zur Liegenschaft.',
};

export const GebaeudeBemerkung = (props: any) => (
  <LimboFormikTextField
    name={GEBAEUDE_BEMERKUNG.name}
    label={GEBAEUDE_BEMERKUNG.label}
    tooltip={GEBAEUDE_BEMERKUNG.tooltip}
    rows="8"
    fullWidth
    multiline
    {...props}
  />
);

const LIEGENSCHAFT_SELECT = {
  name: (index: number) => `${ZAEHLPUNKT_LIEGENSCHAFTEN.name}.${index}.id`,
  label: 'Zugehörige Liegenschaft',
  validationSchema: validate(Yup.string().required('Bitte auswählen')),
  defaultValue: '',
};

const PROZENT_ANTEIL = {
  name: (index: number) =>
    `${ZAEHLPUNKT_LIEGENSCHAFTEN.name}.${index}.prozentAnteil`,
  label: 'Anteil',
  tooltip: (zaehlpunkt_typ: number) => {
    switch (zaehlpunkt_typ) {
      case 5:
        return 'Wie viel Prozent (%) der Einspeisung wird durch die Liegenschaft bedingt?';
      case 6:
        return 'Wie viel Prozent (%) der Produktion wird durch die Liegenschaft bedingt?';
      default:
        return 'Wie viel Prozent (%) des Verbrauchs wird durch die Liegenschaft bedingt?';
    }
  },
  validationSchema: validate(
    Yup.number()
      .required('Bitte Anteile eingeben')
      .typeError('Bitte Anteile eingeben')
      .min(1, 'Muss mindestens 1 sein.')
      .max(100, 'Darf nicht mehr als 100 sein.')
      .test({
        message: 'Bitte keine Nachkommastellen eingeben',
        test: validateDecimalPlacesOfNumber(),
      })
  ),
  defaultValue: 100,
};

export const ZAEHLPUNKT_LIEGENSCHAFTEN = {
  name: 'liegenschaften',
  validationSchema: validate(
    Yup.array().test({
      message: 'Die Anteile zusammen dürfen 100% nicht überschreiten',
      test: (list) => totalSharesEqualsOne(list),
    })
  ),
  defaultValue: [
    {
      id: LIEGENSCHAFT_SELECT.defaultValue,
      prozentAnteil: PROZENT_ANTEIL.defaultValue,
    },
  ],
};

const totalSharesEqualsOne = (list: { prozentAnteil: number }[] = []) => {
  const sum = list.reduce((acc, item) => (acc += item.prozentAnteil), 0);
  return !(sum > 100);
};

interface ZugehoerigeLiegenschaft {
  id: string;
  prozentAnteil: number;
}

interface ZaehlpunktLiegenschaftenProps {
  liegenschaften: any;
  disabled?: boolean;
  value?: string;
}

export const ZaehlpunktLiegenschaften: React.FC<ZaehlpunktLiegenschaftenProps> =
  ({ liegenschaften, ...props }) => {
    const [field, meta, { setValue }] = useField({
      name: ZAEHLPUNKT_LIEGENSCHAFTEN.name,
      validate: ZAEHLPUNKT_LIEGENSCHAFTEN.validationSchema,
    });
    const { values } = useFormikContext();
    const zaehlpunktType = getIn(values, ZAEHLPUNKT_TYPE.name);

    const handleAddLiegenschaft = React.useCallback(() => {
      const newAmount = field.value.length + 1;
      const newShare = Math.floor(100 / newAmount);
      const newFieldArray = [
        ...field.value.map((l: any) => ({
          ...l,
          prozentAnteil: newShare,
        })),
        { id: LIEGENSCHAFT_SELECT.defaultValue, prozentAnteil: newShare },
      ];
      setValue(newFieldArray);
    }, [field, setValue]);

    const handleDelete = React.useCallback(
      (rowIndex) => {
        const newAmount = field.value.length - 1;
        const newShare = Math.floor(100 / newAmount);
        const newFieldArray = [
          ...field.value.slice(0, rowIndex),
          ...field.value.slice(rowIndex + 1),
        ];
        setValue(
          newFieldArray.map((l: any) => ({
            ...l,
            prozentAnteil: newShare,
          }))
        );
      },
      [field, setValue]
    );

    const getGebeaudeUnterkategorie = React.useCallback(
      (liegenschaftId: any) => {
        for (const l of liegenschaften) {
          if (l.value == liegenschaftId) {
            return l.gebaeudeUnterkategorie;
          }
        }
        return null;
      },
      [liegenschaften]
    );

    const hasStrassenbeleuchtung = React.useMemo(() => {
      if (!field.value) {
        return false;
      } else {
        for (const i of field.value) {
          if (
            getGebeaudeUnterkategorie(i.id) ==
            UNTERKATEGORIE_STRASSENBELEUCHTUNG
          ) {
            return true;
          }
        }
      }
      return false;
    }, [field.value, getGebeaudeUnterkategorie]);

    React.useEffect(() => {
      if (
        field.value &&
        hasStrassenbeleuchtung &&
        zaehlpunktType &&
        zaehlpunktType !== EnergieSparte.Strom
      ) {
        const temp: any[] = [];
        for (const i of field.value) {
          if (
            getGebeaudeUnterkategorie(i.id) !==
            UNTERKATEGORIE_STRASSENBELEUCHTUNG
          ) {
            temp.push(i);
          }
        }
        if (temp.length > 0) {
          setValue(
            temp.map((l: any) => ({
              ...l,
              prozentAnteil: Math.floor(100 / temp.length),
            }))
          );
        } else {
          setValue(ZAEHLPUNKT_LIEGENSCHAFTEN.defaultValue);
        }
      }
    }, [
      zaehlpunktType,
      field.value,
      liegenschaften,
      setValue,
      hasStrassenbeleuchtung,
      getGebeaudeUnterkategorie,
    ]);

    const auswaehlbareLiegenschaften = React.useMemo(() => {
      if (zaehlpunktType === EnergieSparte.Strom || !zaehlpunktType) {
        return liegenschaften;
      } else {
        return liegenschaften.filter(
          (liegenschaft) =>
            liegenschaft.gebaeudeUnterkategorie !==
            UNTERKATEGORIE_STRASSENBELEUCHTUNG
        );
      }
    }, [zaehlpunktType, liegenschaften]);

    React.useEffect(() => {
      if (!field.value) {
        setValue(ZAEHLPUNKT_LIEGENSCHAFTEN.defaultValue);
      }
    });

    return (
      <FieldArray
        name={ZAEHLPUNKT_LIEGENSCHAFTEN.name}
        render={() => (
          <>
            {field.value?.map(
              (
                zugehoerigeLiegenschaft: ZugehoerigeLiegenschaft,
                index: number
              ) => (
                <Grid key={index} container direction="row" spacing={4}>
                  <Grid item xs={6}>
                    <LimboFormikSelect
                      name={LIEGENSCHAFT_SELECT.name(index)}
                      label={LIEGENSCHAFT_SELECT.label}
                      validate={LIEGENSCHAFT_SELECT.validationSchema}
                      data={auswaehlbareLiegenschaften}
                      value={zugehoerigeLiegenschaft.id}
                      required
                      disabled={props.disabled}
                    />
                  </Grid>
                  <Grid item xs={field.value.length > 1 ? 5 : 6}>
                    <LimboFormikNumberField
                      name={PROZENT_ANTEIL.name(index)}
                      label={PROZENT_ANTEIL.label}
                      tooltip={PROZENT_ANTEIL.tooltip(zaehlpunktType)}
                      validate={PROZENT_ANTEIL.validationSchema}
                      style={{ marginBottom: '0' }}
                      value={zugehoerigeLiegenschaft.prozentAnteil}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      decimalScale={0}
                      required
                      disabled={props.disabled}
                    />
                  </Grid>
                  {field.value.length > 1 && (
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => handleDelete(index)}
                        disabled={props.disabled}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )
            )}
            {typeof meta.error === 'string' && (
              <div style={{ color: 'red', marginBottom: '0.5rem' }}>
                {meta.error}
              </div>
            )}
            <AddOutlinedButton
              onClick={handleAddLiegenschaft}
              style={{ marginTop: '0.5rem' }}
              disabled={props.disabled}
            >
              weitere Liegenschaft
            </AddOutlinedButton>
          </>
        )}
      />
    );
  };

const normalizeZaehlpunkteToDropdown = (
  zaehlpunkte: any[],
  liegenschaften: any[]
) =>
  zaehlpunkte
    .map((item) => ({
      value: item.id,
      label: `${item.liegenschaften
        .map(
          (relatedLiegenschaft: { id: any }) =>
            liegenschaften.find((l) => l.id === relatedLiegenschaft.id)
              ?.liegenschaftsbezeichnung
        )
        .join(', ')} | ${item.name}`,
      type: item.zaehlpunktTyp,
      erfassungsTyp: item.erfassungsTyp,
    }))
    .sort(alphabeticalSort('label'));

export const useZaehlpunkteDropdown = (filterFn: {
  (zp: any): any;
  (zp: {
    zaehlpunktTyp: any;
    parent: any;
    id: string;
    liegenschaften: Array<{ prozent: number; id: string }>;
    liveMessung: number;
  }): boolean;
}) => {
  const { zaehlpunkte, isLoading } = useZaehlpunkte();
  const { liegenschaften } = useLiegenschaften();

  return {
    isLoading,
    zaehlpunkte: normalizeZaehlpunkteToDropdown(
      zaehlpunkte.filter(filterFn),
      liegenschaften
    ),
  };
};

export const UNTERZAEHLER_SELECT = {
  name: 'children',
  label: 'Zugehörige Unterzählpunkte',
  tooltip:
    'Der von Unterzählpunkten erfasste Verbrauch wird automatisch von den Bewegungsdaten subtrahiert, die an einem übergeordneten Zählpunkt erfasst werden. Falls oben zusätzlich eine prozentuale Verteilung angegeben wird, bezieht sich diese auf die Differenz (Hauptzählpunkt minus Unterzählpunkt).',
  validationSchema: validate(
    Yup.array()
      .of(Yup.string())
      .required('Bitte Unterzählpunkt auswählen')
      .typeError('Bitte Unterzählpunkt auswählen')
      .min(1, 'Bitte mindestens einen Unterzählpunkt auswählen')
  ),
};

export const UnterzaehlerSelect = (props: any) => {
  const { values } = useFormikContext();
  const selectedUnterzaehler = getIn(values, UNTERZAEHLER_SELECT.name);
  const zaehlpunktId = getIn(values, 'id');
  const zaehlpunktTyp = getIn(values, ZAEHLPUNKT_TYPE.name);

  const zaehlpunkteFilterFunction = React.useMemo(() => {
    if (zaehlpunktTyp === EnergieSparte.Strom) {
      return (zp) =>
        (zp.zaehlpunktTyp === zaehlpunktTyp ||
          zp.heizungsartRaumwaerme === HEIZUNGS_TYP_WAERMEPUMPE.value) &&
        !zp.children?.length &&
        (!zp.parent || selectedUnterzaehler.includes(zp.id)) &&
        zp.id !== zaehlpunktId &&
        !zp.liveMessung;
    } else if (zaehlpunktTyp === EnergieSparte.Gas) {
      return (zp) =>
        (zp.zaehlpunktTyp === zaehlpunktTyp &&
          !zp.children?.length &&
          (!zp.parent || selectedUnterzaehler.includes(zp.id)) &&
          zp.id !== zaehlpunktId &&
          !zp.liveMessung) ||
        (zp.zaehlpunktTyp === EnergieSparte.BHKW &&
          zp.id !== zaehlpunktId &&
          !zp.liveMessung);
    } else {
      return (zp) =>
        zp.zaehlpunktTyp === zaehlpunktTyp &&
        !zp.children?.length &&
        (!zp.parent || selectedUnterzaehler.includes(zp.id)) &&
        zp.id !== zaehlpunktId &&
        !zp.liveMessung;
    }
  }, [selectedUnterzaehler, zaehlpunktId, zaehlpunktTyp]);

  const { zaehlpunkte } = useZaehlpunkteDropdown(zaehlpunkteFilterFunction);
  const zaehlpunkteDropDown = React.useMemo(
    () =>
      zaehlpunkte.map(({ value, label }) => ({
        value,
        label,
      })),
    [zaehlpunkte]
  );

  return zaehlpunkte.length ? (
    <LimboFormikMultiChipSelect
      name={UNTERZAEHLER_SELECT.name}
      label={UNTERZAEHLER_SELECT.label}
      tooltip={UNTERZAEHLER_SELECT.tooltip}
      validate={UNTERZAEHLER_SELECT.validationSchema}
      data={zaehlpunkteDropDown}
      required
      {...props}
    />
  ) : (
    <Box marginTop="1rem">Keine Auswahlmöglichkeit</Box>
  );
};

export const PARENTZAEHLER_SELECT = {
  name: 'parent',
  label: 'Übergeordneten Zählpunkt auswählen',
  tooltip:
    'Der von Unterzählpunkten erfasste Verbrauch wird automatisch von den Bewegungsdaten subtrahiert, die an einem übergeordneten Zählpunkt erfasst werden. Falls oben zusätzlich eine prozentuale Verteilung angegeben wird, bezieht sich diese auf die Differenz (Hauptzählpunkt minus Unterzählpunkt).',
  validationSchema: validate(Yup.string().required('Bitte auswählen')),
};

export const ParentzaehlerSelect = (props: any) => {
  const { values } = useFormikContext();
  const zaehlpunktId = getIn(values, 'id');
  const zaehlpunktTyp = getIn(values, ZAEHLPUNKT_TYPE.name);

  const zaehlpunktFilterFn = React.useMemo(() => {
    if (
      zaehlpunktTyp === EnergieSparte.Strom ||
      zaehlpunktTyp === EnergieSparte.Waerme
    ) {
      return (zp: { zaehlpunktTyp: any; parent: any; id: string }) =>
        (zp.zaehlpunktTyp === zaehlpunktTyp &&
          !zp.parent &&
          zp.id !== zaehlpunktId) ||
        (zp.zaehlpunktTyp === EnergieSparte.BHKW && zp.id !== zaehlpunktId);
    } else {
      return (zp: { zaehlpunktTyp: any; parent: any; id: string }) =>
        zp.zaehlpunktTyp === zaehlpunktTyp &&
        !zp.parent &&
        zp.id !== zaehlpunktId;
    }
  }, [zaehlpunktId, zaehlpunktTyp]);

  const { zaehlpunkte } = useZaehlpunkteDropdown(zaehlpunktFilterFn);

  return zaehlpunkte.length ? (
    <LimboFormikSelect
      data={zaehlpunkte}
      name={PARENTZAEHLER_SELECT.name}
      label={PARENTZAEHLER_SELECT.label}
      tooltip={PARENTZAEHLER_SELECT.tooltip}
      validate={PARENTZAEHLER_SELECT.validationSchema}
      required
      {...props}
    />
  ) : (
    <Box marginTop="1rem">Keine Auswahlmöglichkeit</Box>
  );
};

export const PARENTZAEHLER_BY_LIEGENSCHAFTEN_SELECT = {
  name: 'parent',
  label: 'Übergeordneten Zählpunkt auswählen',
  tooltip:
    'Der von Unterzählpunkten erfasste Verbrauch wird automatisch von den Bewegungsdaten subtrahiert, die an einem übergeordneten Zählpunkt erfasst werden. Falls oben zusätzlich eine prozentuale Verteilung angegeben wird, bezieht sich diese auf die Differenz (Hauptzählpunkt minus Unterzählpunkt).',
  validationSchema: (zaehlpunkte, noDataInfoText) =>
    validate(
      Yup.string()
        .transform((currentValue, originalValue) =>
          originalValue === null ? '' : currentValue
        )
        .test({
          message: noDataInfoText,
          test: hasData(zaehlpunkte),
        })
        .required('Bitte auswählen')
    ),
};

export const ParentzaehlerByLiegenschaftenSelect = ({
  liegenschaftenIds,
  zaehlpunktTyp,
  noDataInfoText,
  ...props
}) => {
  const { values } = useFormikContext();
  const zaehlpunktId = getIn(values, 'id');

  const { zaehlpunkte } = useZaehlpunkteDropdown(
    (zp: {
      zaehlpunktTyp: any;
      parent: any;
      id: string;
      liegenschaften: Array<{ prozent: number; id: string }>;
    }) =>
      zp.zaehlpunktTyp === zaehlpunktTyp &&
      !zp.parent &&
      zp.id !== zaehlpunktId &&
      zp.liegenschaften.filter((lg) => liegenschaftenIds.includes(lg.id))
        ?.length > 0
  );

  return (
    <>
      <LimboFormikSelect
        data={zaehlpunkte}
        name={PARENTZAEHLER_BY_LIEGENSCHAFTEN_SELECT.name}
        label={PARENTZAEHLER_BY_LIEGENSCHAFTEN_SELECT.label}
        tooltip={PARENTZAEHLER_BY_LIEGENSCHAFTEN_SELECT.tooltip}
        validate={PARENTZAEHLER_BY_LIEGENSCHAFTEN_SELECT.validationSchema(
          zaehlpunkte,
          noDataInfoText
        )}
        required
        {...props}
      />
    </>
  );
};

export const UNTERZAEHLER_SELECT_BHKW = {
  name: 'children',
  label: 'Zugehörige Unterzählpunkte',
  tooltip:
    'Der von Unterzählpunkten erfasste Verbrauch wird automatisch von den Bewegungsdaten subtrahiert, die an einem übergeordneten Zählpunkt erfasst werden. Falls oben zusätzlich eine prozentuale Verteilung angegeben wird, bezieht sich diese auf die Differenz (Hauptzählpunkt minus Unterzählpunkt).',
  validationSchema: validate(Yup.array().of(Yup.string()).nullable()),
};

export const UnterzaehlerSelectBhkw = (props: any) => {
  const { values } = useFormikContext();
  const selectedUnterzaehler = getIn(values, UNTERZAEHLER_SELECT.name);
  const zaehlpunktId = getIn(values, 'id');

  const zaehlpunkteFilterFunction = React.useMemo(() => {
    return (zp) =>
      (zp.zaehlpunktTyp === EnergieSparte.Strom ||
        zp.zaehlpunktTyp === EnergieSparte.Waerme) &&
      !zp.children?.length &&
      (!zp.parent || selectedUnterzaehler.includes(zp.id)) &&
      zp.id !== zaehlpunktId &&
      !zp.liveMessung;
  }, [selectedUnterzaehler, zaehlpunktId]);

  const { zaehlpunkte } = useZaehlpunkteDropdown(zaehlpunkteFilterFunction);
  const zaehlpunkteDropDown = React.useMemo(
    () =>
      zaehlpunkte.map(({ value, label }) => ({
        value,
        label,
      })),
    [zaehlpunkte]
  );

  return (
    <LimboFormikMultiChipSelect
      name={UNTERZAEHLER_SELECT_BHKW.name}
      label={UNTERZAEHLER_SELECT_BHKW.label}
      tooltip={UNTERZAEHLER_SELECT_BHKW.tooltip}
      validate={UNTERZAEHLER_SELECT_BHKW.validationSchema}
      data={zaehlpunkteDropDown}
      {...props}
    />
  );
};

const LICHTPUNKTE = {
  label: 'Anzahl der Lichtpunkte',
  fieldName: 'anzahlLichtpunkte',
  name: (index: number) =>
    `${ZEITLICHE_LICHTPUNKTE.name}.${index}.${LICHTPUNKTE.fieldName}`,
  tooltip: 'Als Lichtpunkte sind einzelne Leuchten zu verstehen',
  validationSchema: () =>
    validate(
      Yup.number()
        .required('Bitte Anzahl der Lichtpunkte eingeben')
        .typeError('Bitte Anzahl der Lichtpunkte eingeben')
        .min(1, 'Negative Lichtpunkte sind nicht möglich')
        .test({
          message: 'Bitte keine Nachkommastellen eingeben',
          test: validateDecimalPlacesOfNumber(),
        })
    ),
};

const Lichtpunkte = (props: any) => {
  const { index, ...textFieldProps } = props;

  return (
    <LimboFormikNumberField
      name={LICHTPUNKTE.name(index)}
      label={LICHTPUNKTE.label}
      tooltip={LICHTPUNKTE.tooltip}
      validate={LICHTPUNKTE.validationSchema()}
      decimalScale={0}
      {...textFieldProps}
    />
  );
};

const STRASSENKILOMETER = {
  label: 'Straßenkilometer',
  fieldName: 'strassenkilometer',
  name: (index: number) =>
    `${ZEITLICHE_LICHTPUNKTE.name}.${index}.${STRASSENKILOMETER.fieldName}`,
  tooltip: '',
  validationSchema: () =>
    validate(
      Yup.lazy((value: any) =>
        isNaN(value)
          ? Yup.string().transform((currentValue, originalValue) =>
              originalValue === null ? '' : currentValue
            )
          : Yup.number()
              .min(0, 'Negative Straßenkilometer sind nicht möglich')
              .test({
                message: 'Bitte nur zwei Nachkommastellen angeben',
                test: validateDecimalPlacesOfNumber(2),
              })
      )
    ),
};

const Strassenkilometer = (props: any) => {
  const { index, ...textFieldProps } = props;

  return (
    <LimboFormikNumberField
      name={STRASSENKILOMETER.name(index)}
      label={STRASSENKILOMETER.label}
      tooltip={STRASSENKILOMETER.tooltip}
      validate={STRASSENKILOMETER.validationSchema()}
      {...textFieldProps}
    />
  );
};

const LICHTPUNKTE_GUELTIG_BIS = {
  label: 'Gültig bis',
  fieldName: 'gueltigBis',
  name: (index: number) =>
    `${ZEITLICHE_LICHTPUNKTE.name}.${index}.${LICHTPUNKTE_GUELTIG_BIS.fieldName}`,
  validationSchema: (lichtpunkte: Array<{ gueltigBis?: string }>) =>
    validate(
      Yup.date()
        .required('Bitte Datum auswählen')
        .typeError('Bitte Datum auswählen')
        .max(new Date(), 'Datum darf nicht in der Zukunft liegen')
        .test({
          message: 'Datum muss eindeutig sein',
          test: uniqueDate(LICHTPUNKTE_GUELTIG_BIS.fieldName, lichtpunkte),
        })
        .test({
          message: 'Datum muss aufsteigend sein',
          test: increasingDate(LICHTPUNKTE_GUELTIG_BIS.fieldName, lichtpunkte),
        })
    ),
};

const LichtpunkteGueltigBis = (props: any) => {
  const { index, ...datePickerProps } = props;
  const { values } = useFormikContext();
  const lichtpunkte = getIn(values, ZEITLICHE_LICHTPUNKTE.name);

  return (
    <LimboFormikDatePicker
      name={LICHTPUNKTE_GUELTIG_BIS.name(index)}
      label={LICHTPUNKTE_GUELTIG_BIS.label}
      validate={LICHTPUNKTE_GUELTIG_BIS.validationSchema(lichtpunkte)}
      maxDate={new Date()}
      dateFormat="yyyy-MM-dd"
      KeyboardButtonProps={{
        disabled: true,
        style: { display: 'none' },
      }}
      {...datePickerProps}
    />
  );
};

export const ZEITLICHE_LICHTPUNKTE = {
  name: 'lichtpunkte',
  validationSchema: validate(
    Yup.array().of(
      Yup.object().shape({
        [LICHTPUNKTE.fieldName]: Yup.number().required(),
        [STRASSENKILOMETER.fieldName]: Yup.lazy((value: any) =>
          isNaN(value)
            ? Yup.string().transform((currentValue, originalValue) =>
                originalValue === null ? '' : currentValue
              )
            : Yup.number()
                .min(0, 'Negative Straßenkilometer sind nicht möglich')
                .test({
                  message: 'Bitte nur zwei Nachkommastellen angeben',
                  test: validateDecimalPlacesOfNumber(2),
                })
        ),
        [LICHTPUNKTE_GUELTIG_BIS.fieldName]: Yup.date(),
      })
    )
  ),
  defaultValues: [
    {
      [LICHTPUNKTE.fieldName]: '',
    },
  ],
};

export const ZeitlicheLichtpunkte = (props: any) => {
  const [rowsToEdit, setRowsToEdit] = React.useState<Array<number>>([]);
  const [newAddedRows, setNewAddedRows] = React.useState<Array<number>>([]);
  const [isCreatedMode, setIsCreateMode] = React.useState(false);
  const { disabled } = props;
  const [field, , { setValue }] = useField<
    Array<{ [key: string]: string | null }>
  >({
    name: ZEITLICHE_LICHTPUNKTE.name,
    validate: ZEITLICHE_LICHTPUNKTE.validationSchema,
  });

  React.useEffect(() => {
    if (!field.value) {
      setValue(ZEITLICHE_LICHTPUNKTE.defaultValues);
      setIsCreateMode(true);
    }
  }, [field.value, setValue]);

  const handleAddWerte = React.useCallback(() => {
    setValue([...field.value, ZEITLICHE_LICHTPUNKTE.defaultValues[0]]);
    const newRows = [...newAddedRows, field.value.length];
    setNewAddedRows(newRows);
  }, [field.value, newAddedRows, setValue]);

  const handleRowDelete = React.useCallback(
    (indexToDelete) => {
      const lastItem = field.value.length - 1;
      const newFieldArray = [
        ...field.value.slice(0, indexToDelete),
        ...field.value.slice(indexToDelete + 1),
      ];
      if (indexToDelete === lastItem) {
        const [last, ...items] = [...newFieldArray].reverse();

        return setValue([
          ...items.reverse(),
          {
            [LICHTPUNKTE.fieldName]: last[LICHTPUNKTE.fieldName],
            [STRASSENKILOMETER.fieldName]: last[STRASSENKILOMETER.fieldName],
          },
        ]);
      }
      setValue(newFieldArray);
      const newRows = newAddedRows
        .filter((rowIndex) => rowIndex !== indexToDelete)
        .map((index) => (index > indexToDelete ? index - 1 : index));
      setNewAddedRows(newRows);
    },
    [field.value, newAddedRows, setValue]
  );

  const handleEditRow = React.useCallback(
    (index: number) => {
      const newRowsToEdit = [...rowsToEdit, index];
      setRowsToEdit(newRowsToEdit);
    },
    [rowsToEdit]
  );

  return (
    <>
      <FieldArray
        name={ZEITLICHE_LICHTPUNKTE.name}
        render={() => (
          <>
            {field.value?.map((record, index) => {
              const isLastEntry = index === field.value.length - 1;
              const showEditButton =
                !isCreatedMode &&
                !rowsToEdit.includes(index) &&
                !newAddedRows.includes(index);
              const showDeleteButton =
                (isCreatedMode && field?.value?.length > 1) ||
                (!isCreatedMode && newAddedRows.includes(index));

              return (
                <Grid key={index} container direction="row" spacing={4}>
                  <Grid item xs={6}>
                    <Lichtpunkte
                      index={index}
                      value={record[LICHTPUNKTE.fieldName]}
                      disabled={disabled || showEditButton}
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={
                      !isLastEntry
                        ? 3
                        : showDeleteButton || showEditButton
                        ? 5
                        : 6
                    }
                  >
                    <Strassenkilometer
                      index={index}
                      value={record[STRASSENKILOMETER.fieldName]}
                      disabled={disabled || showEditButton}
                    />
                  </Grid>
                  {!isLastEntry && (
                    <Grid item xs={2}>
                      <LichtpunkteGueltigBis
                        index={index}
                        value={record[LICHTPUNKTE_GUELTIG_BIS.fieldName]}
                        disabled={
                          disabled ||
                          (showEditButton && !newAddedRows.includes(index + 1))
                        }
                        required
                      />
                    </Grid>
                  )}
                  {showDeleteButton && (
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => handleRowDelete(index)}
                        disabled={disabled}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                  {showEditButton && (
                    <Grid item xs={1}>
                      <Tooltip
                        interactive
                        title="Bitte nutzen Sie die Bearbeitenfunktion nur um
                          historische Daten zu korrigieren."
                        PopperComponent={TooltipPopper(
                          <Typography>
                            Bitte nutzen Sie die Bearbeitenfunktion nur um
                            fehlerhafte historische Daten zu korrigieren. Für
                            Änderungen an der Anzahl der Lichtpunkte und
                            Straßenkilometer legen Sie bitte über die
                            Schaltfläche "Neueren Wert hinzufügen" einen neuen
                            Eintrag an.
                          </Typography>
                        )}
                      >
                        <IconButton
                          onClick={() => handleEditRow(index)}
                          disabled={disabled}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </>
        )}
      />
      <Grid item xs={6} style={{ marginTop: '0.5rem', marginBottom: '2rem' }}>
        <AddOutlinedButton onClick={handleAddWerte} disabled={disabled}>
          Neueren Wert hinzufügen
        </AddOutlinedButton>
      </Grid>
    </>
  );
};

const EINWOHNERWERT = {
  label: 'Einwohnerwert',
  fieldName: 'einwohnerwert',
  name: (index: number) =>
    `${ZEITLICHE_EINWOHNERWERTE.name}.${index}.${EINWOHNERWERT.fieldName}`,
  tooltip:
    'Der Einwohnerwert (EW) ist eine Kennzahl, der die Schmutzfracht\n' +
    '                im Zulauf einer Kläranlage angibt. Er beschreibt die Summe der\n' +
    '                Schmutzfracht aller angeschlossenen Einwohner. Diese ergibt sich\n' +
    '                aus den Schmutzfrachten der Abwässer aus Privathaushalten und\n' +
    '                Gewerbe. Der Einwohnerwert entspricht der Schmutzfracht zur\n' +
    '                Kläranlage, die für einen durchschnittlichen Einwohner erwartet\n' +
    '                werden kann und bildet dadurch das gesamte Einzugsgebiet der\n' +
    '                Kläranlage vereinfacht durch nur einen Kennwert ab.',
  validationSchema: () =>
    validate(
      Yup.number()
        .required('Bitte Einwohnerwert eingeben')
        .typeError('Bitte Einwohnerwert eingeben')
        .min(1, 'Ein negativer Einwohnerwert ist nicht möglich')
    ),
};

const Einwohnerwert = (props: any) => {
  const { index, ...textFieldProps } = props;

  return (
    <LimboFormikNumberField
      name={EINWOHNERWERT.name(index)}
      label={EINWOHNERWERT.label}
      tooltip={EINWOHNERWERT.tooltip}
      validate={EINWOHNERWERT.validationSchema()}
      decimalScale={0}
      {...textFieldProps}
    />
  );
};

const EINWOHNERWERT_GUELTIG_BIS = {
  label: 'Gültig bis',
  fieldName: 'gueltigBis',
  name: (index: number) =>
    `${ZEITLICHE_EINWOHNERWERTE.name}.${index}.${EINWOHNERWERT_GUELTIG_BIS.fieldName}`,
  validationSchema: (einwohnerwerte: Array<{ gueltigBis?: string }>) =>
    validate(
      Yup.date()
        .required('Bitte Datum auswählen')
        .typeError('Bitte Datum auswählen')
        .max(new Date(), 'Datum darf nicht in der Zukunft liegen')
        .test({
          message: 'Datum muss eindeutig sein',
          test: uniqueDate(EINWOHNERWERT_GUELTIG_BIS.fieldName, einwohnerwerte),
        })
        .test({
          message: 'Datum muss aufsteigend sein',
          test: increasingDate(
            EINWOHNERWERT_GUELTIG_BIS.fieldName,
            einwohnerwerte
          ),
        })
    ),
};

const EinwohnerwertGueltigBis = (props: any) => {
  const { index, ...datePickerProps } = props;
  const { values } = useFormikContext();
  const einwohnerwert = getIn(values, ZEITLICHE_EINWOHNERWERTE.name);

  return (
    <LimboFormikDatePicker
      name={EINWOHNERWERT_GUELTIG_BIS.name(index)}
      label={EINWOHNERWERT_GUELTIG_BIS.label}
      validate={EINWOHNERWERT_GUELTIG_BIS.validationSchema(einwohnerwert)}
      maxDate={new Date()}
      dateFormat="yyyy-MM-dd"
      KeyboardButtonProps={{
        disabled: true,
        style: { display: 'none' },
      }}
      {...datePickerProps}
    />
  );
};

export const ZEITLICHE_EINWOHNERWERTE = {
  name: 'einwohnerwerte',
  validationSchema: validate(
    Yup.array().of(
      Yup.object().shape({
        [EINWOHNERWERT.fieldName]: Yup.number().required(),
        [EINWOHNERWERT_GUELTIG_BIS.fieldName]: Yup.date(),
      })
    )
  ),
  defaultValues: [
    {
      [EINWOHNERWERT.fieldName]: '',
    },
  ],
};

export const ZeitlicheEinwohnerwerte = (props: any) => {
  const [rowsToEdit, setRowsToEdit] = React.useState<Array<number>>([]);
  const [newAddedRows, setNewAddedRows] = React.useState<Array<number>>([]);
  const [isCreatedMode, setIsCreateMode] = React.useState(false);
  const { disabled } = props;
  const [field, , { setValue }] = useField<
    Array<{ [key: string]: string | null }>
  >({
    name: ZEITLICHE_EINWOHNERWERTE.name,
    validate: ZEITLICHE_EINWOHNERWERTE.validationSchema,
  });

  React.useEffect(() => {
    if (!field.value) {
      setValue(ZEITLICHE_EINWOHNERWERTE.defaultValues);
      setIsCreateMode(true);
    }
  }, [field.value, setValue]);

  const handleAddWerte = React.useCallback(() => {
    setValue([...field.value, ZEITLICHE_EINWOHNERWERTE.defaultValues[0]]);
    const newRows = [...newAddedRows, field.value.length];
    setNewAddedRows(newRows);
  }, [field.value, newAddedRows, setValue]);

  const handleRowDelete = React.useCallback(
    (indexToDelete) => {
      const lastItem = field.value.length - 1;
      const newFieldArray = [
        ...field.value.slice(0, indexToDelete),
        ...field.value.slice(indexToDelete + 1),
      ];
      if (indexToDelete === lastItem) {
        const [last, ...items] = [...newFieldArray].reverse();

        return setValue([
          ...items.reverse(),
          {
            [EINWOHNERWERT.fieldName]: last[EINWOHNERWERT.fieldName],
          },
        ]);
      }
      setValue(newFieldArray);
      const newRows = newAddedRows
        .filter((rowIndex) => rowIndex != indexToDelete)
        .map((index) => (index > indexToDelete ? index - 1 : index));
      setNewAddedRows(newRows);
    },
    [field.value, newAddedRows, setValue]
  );

  const handleEditRow = React.useCallback(
    (index: number) => {
      const newRowsToEdit = [...rowsToEdit, index];
      setRowsToEdit(newRowsToEdit);
    },
    [rowsToEdit]
  );

  return (
    <>
      <FieldArray
        name={ZEITLICHE_EINWOHNERWERTE.name}
        render={() => (
          <>
            {field.value?.map((record, index) => {
              const isLastEntry = index === field.value.length - 1;
              const showEditButton =
                !isCreatedMode &&
                !rowsToEdit.includes(index) &&
                !newAddedRows.includes(index);
              const showDeleteButton =
                (isCreatedMode && field?.value?.length > 1) ||
                (!isCreatedMode && newAddedRows.includes(index));

              return (
                <Grid key={index} container direction="row" spacing={4}>
                  <Grid item xs={6}>
                    <Einwohnerwert
                      index={index}
                      value={record[EINWOHNERWERT.fieldName]}
                      disabled={disabled || showEditButton}
                      required
                    />
                  </Grid>
                  {!isLastEntry && (
                    <Grid item xs={5}>
                      <EinwohnerwertGueltigBis
                        index={index}
                        value={record[EINWOHNERWERT_GUELTIG_BIS.fieldName]}
                        disabled={
                          disabled ||
                          (showEditButton && !newAddedRows.includes(index + 1))
                        }
                        required
                      />
                    </Grid>
                  )}
                  {isLastEntry && <Grid item xs={5}></Grid>}
                  {showDeleteButton && (
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => handleRowDelete(index)}
                        disabled={disabled}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                  {showEditButton && (
                    <Grid item xs={1}>
                      <Tooltip
                        interactive
                        title="Bitte nutzen Sie die Bearbeitenfunktion nur um
                          historische Daten zu korrigieren."
                        PopperComponent={TooltipPopper(
                          <Typography>
                            Bitte nutzen Sie die Bearbeitenfunktion nur um
                            fehlerhafte historische Daten zu korrigieren. Für
                            Änderungen an dem Einwohnerwert legen Sie bitte über
                            die Schaltfläche "Einwohnerwert" einen neuen Eintrag
                            an.
                          </Typography>
                        )}
                      >
                        <IconButton
                          onClick={() => handleEditRow(index)}
                          disabled={disabled}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </>
        )}
      />
      <Grid item xs={6} style={{ marginTop: '0.5rem', marginBottom: '2rem' }}>
        <AddOutlinedButton onClick={handleAddWerte} disabled={disabled}>
          Einwohnerwert
        </AddOutlinedButton>
      </Grid>
    </>
  );
};

const LIEGENSCHAFT_GRUPPE = {
  name: 'gruppe',
  label: 'Name der Gruppe',
  validationSchema: (gruppen) =>
    validate(
      Yup.string()
        .required('Bitte eine Gruppe angeben')
        .test({
          message: 'Diese Gruppe existiert bereits',
          test: uniqueName(gruppen),
        })
    ),
};

export const LiegenschaftsGruppe = ({ initialValue, ...props }) => {
  const { gruppen } = useLiegenschaftenGruppen();

  const groupsForValidation = React.useMemo(
    () => gruppen.filter((gruppe) => gruppe !== initialValue),
    [gruppen, initialValue]
  );

  return (
    <LimboFormikTextField
      name={LIEGENSCHAFT_GRUPPE.name}
      label={LIEGENSCHAFT_GRUPPE.label}
      validate={LIEGENSCHAFT_GRUPPE.validationSchema(groupsForValidation)}
      tooltip="z.B. Name einer Mietgliedsgemeinde"
      {...props}
    />
  );
};

const LIEGENSCHAFTEN_FOR_GRUPPE = {
  name: 'gruppenLiegenschaften',
  label: 'Zugehörige Liegenschaften',
  validationSchema: validate(
    Yup.array(Yup.string())
      .required('Bitte auswählen')
      .min(1, 'Bitte mindestens eine Liegenschaft auswählen')
  ),
};

export const LiegenschaftenForGruppe = (props) => {
  const { liegenschaften } = useLiegenschaften();
  const { setFieldValue } = useFormikContext();

  const liegenschaftenDropDown = React.useMemo(
    () => [
      ...liegenschaften
        .map((ls) => ({
          id: ls.id,
          label: ls.liegenschaftsbezeichnung,
          group: ls.gruppe || 'Keiner Gruppe zugeordnet',
        }))
        .sort(alphabeticalSort('label')),
    ],
    [liegenschaften]
  );

  const handleChange = React.useCallback(
    ({ target: { value } }) => {
      setFieldValue(LIEGENSCHAFTEN_FOR_GRUPPE.name, value);
    },
    [setFieldValue]
  );

  return (
    <LimboFormikMultiChipSelectWithGroups
      name={LIEGENSCHAFTEN_FOR_GRUPPE.name}
      label={LIEGENSCHAFTEN_FOR_GRUPPE.label}
      data={liegenschaftenDropDown}
      onChange={handleChange}
      validate={LIEGENSCHAFTEN_FOR_GRUPPE.validationSchema}
      {...props}
    />
  );
};
